import ABI from "./abi";

const Addresses = {
    ZERO_ADDRESS: "0x0000000000000000000000000000000000000000",
    POYLGON_ZKEVM: {
		GENESIS_PROXY_ADDRESS: "0x589A3F7e3DC1291776FB1710102171f54c65512c"
    },
    LOCALHOST: {
		// GENESIS_PROXY_ADDRESS: "0xD2D9Cf6D222041569fEaCDdA8028534bc12CCEA4",
		// GENESIS_PROXY_ADDRESS: "0x8dcE80e2A3311Bd8e317C124958f3B54C7235Bd0",
		GENESIS_PROXY_ADDRESS: "0x86135Eb3B44bcE80a1057dBA74f0253fDbF077A7",
    },
    CRYPTAGO_PRE: {
		// GENESIS_PROXY_ADDRESS: "0xD2D9Cf6D222041569fEaCDdA8028534bc12CCEA4",
		// GENESIS_PROXY_ADDRESS: "0x8dcE80e2A3311Bd8e317C124958f3B54C7235Bd0",
		// GENESIS_PROXY_ADDRESS: "0x86135Eb3B44bcE80a1057dBA74f0253fDbF077A7",
		// GENESIS_PROXY_ADDRESS: "0x84e088AC8C603224A102c90eF25A428bA87E2dd5",
		GENESIS_PROXY_ADDRESS: "0x7453d94Ac51A92B31395B4C36d73abdc868A9D9A",
		GAS_STATION: "0xbD289D6BB3011EFD97736818Cc10f0154B27dC93"
    }
}

const Settings = {

    Contract: {

		Address: {
			MAIN: Addresses.POYLGON_ZKEVM.GENESIS_PROXY_ADDRESS,
			LOCALHOST: Addresses.LOCALHOST.GENESIS_PROXY_ADDRESS,

			WEB3LIB_PRE: Addresses.CRYPTAGO_PRE.GENESIS_PROXY_ADDRESS,

			GAS_STATION: Addresses.CRYPTAGO_PRE.GAS_STATION
		},
		Abi:{
			VERSION: ABI.genesis_version,
			V1: ABI.genesis_v1,
			V2: ABI.genesis_v2,
			// V3: ABI.genesis_v3
		}

    }

}

export default Settings;