import React from 'react';
import {useWeb3React} from "@web3-react/core";
import NetworkUtils from "../../../utils/NetworkUtils";
import PropTypes from "prop-types";
import {TileDiv} from "./Tiles.styles";
import {Web3App} from "../AppItem";

export const WalletInfo = ({switchable=true}) => {

    const { activate, deactivate, active, chainId, connector, account, library } = useWeb3React();

    const switch_wallet = () => {

    }

    const reduceAddressString = (address, preStep, postStep) => {
	let slicedKeyString = address.substring(0, preStep) + "..." + address.substring(address.length - postStep, address.length)
	return [slicedKeyString]
    }

    let network = NetworkUtils.getNetwork(chainId)



    return(
	<TileDiv>
	    <wallet-desc>
		<Web3App provider={"metamask"} showName={false} background={false} style={{
		    fontFamily: "Roboto",
		    padding: "4px",
		    width: "32px",
		    height: "32px",
		    imgStyle: {width: "24px", height: "24px", padding: "4px"}
		}}/>
		{reduceAddressString(account, 6, 4)}
	    </wallet-desc>
	</TileDiv>
    )

}
WalletInfo.propTypes = {
    switchable: PropTypes.bool
}