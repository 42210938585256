import './index.css';

import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';

import {Web3Provider} from "@ethersproject/providers";
import {Web3ReactProvider} from '@web3-react/core'
import Web3 from "web3";

import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import themeLight from "./theme/ThemeLight";
import {ThemeProvider} from "styled-components";
import themeDark from "./theme/ThemeDark";
import {LANG, LanguageProvider} from "./language/LanguageProvider";
import {BrowserRouter, Route, Routes} from "react-router-dom";

import Package from "../package.json";
import LandingPage from "./pages/landing/Landing";
import Legal from "./pages/legal/Legal";
import {UnderConstructionPage} from "./pages/_fallback/UnderConstruction";

import("./polyfill.js");

window.web3 = Web3; //Comment this out to disable global Web3

function getLibrary(provider) {
    // console.log("GetLibrary", provider)
    const library = new Web3Provider(provider);
    // console.log(library)
    library.pollingInterval = 1200;
    return library;
}

// Theme
function WrappedApp(){

    const [theme, setTheme] = useState(themeLight);
    const toggleTheme = () => { setTheme((curr) => (curr === themeLight ? themeDark : themeLight)); }

    useEffect(() => {
        if(window.location.hostname.includes("localhost") || window.location.hostname.includes(("127.0.0.1"))){
            document.title = document.title+" | Localhost"
        }
    }, []);

    return(
        <LanguageProvider enc={LANG.de_DE}>
            <Web3ReactProvider getLibrary={getLibrary}>
                <ThemeProvider theme={theme}>
                        <BrowserRouter basename={Package.homepage}>
                            <Routes>
                                <Route path={"/landing"} element={
                                    <LandingPage/>
                                }/>

                                <Route path={"/app/*"} element={
                                    <App toggleTheme={toggleTheme}/>
                                }/>

                                <Route path={"/legal"}>
                                    <Route index path={"*"} element={<Legal/>}/>
                                </Route>

                                <Route path={"*"} element={<UnderConstructionPage/>} />
                                {/*<Route path={"*"} element={<NotFoundPage/>} />*/}

                            {/* TODO: Insert mechanism to open document by Address   */}

                            </Routes>
                        </BrowserRouter>

                </ThemeProvider>
                <ToastContainer
                    position="top-right"
                />
            </Web3ReactProvider>
        </LanguageProvider>
    )

}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( <WrappedApp/> );


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
