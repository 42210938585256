import React, {useState} from 'react';
import {useLanguageService} from "../../../../language/LanguageProvider";

import {Worker} from '@react-pdf-viewer/core';
// Plugins
// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';


import BitcoinPDF from "../../../assets/bitcoin.pdf";
import "./testimonial.css";
import PDFViever from "./pdfslider/PDFSlider";
import {Backdrop} from "@mui/material";


function Testimonial(){

    const {landing, landingPR} = useLanguageService();

    const [showPDF, setShowPDF] = useState(false);

    // const imageSrc = "https://placehold.co/200x300"
    const imageSrc = "https://i.pinimg.com/originals/d6/9b/60/d69b60dc4ca5b87898c8e6d2e12b7345.png"


    const handleView = () => {
	setShowPDF(true)
    }
    const handleClose = () => {
	setShowPDF(false)
    }

    return(
	<div className={"work-section-container"}>
	    <div className={"work-section-top"}>
		<p className={"primary-subheading"}>
		    Testimonial
		</p>
		<h1 className={"primary-heading"}>
		    {landing.TESTIMONIAL_HEADER}
		</h1>
		<p className={"primary-text"}>
		    {landingPR.KNOWING_AUTHOR_DESCRIPTION_BITCOIN_2}
		</p>
	    </div>
	    <div className={"testimonial-section-bottom"}>
		<img src={imageSrc} alt={""} onClick={handleView}/>
		<p>
		    {landingPR.KNOWING_AUTHOR_DESCRIPTION_BITCOIN}
		</p>
		{/*<div className={"testimonial-stars-container"}>*/}
		{/*    <StarRateIcon/>*/}
		{/*    <StarRateIcon/>*/}
		{/*    <StarRateIcon/>*/}
		{/*    <StarRateIcon/>*/}
		{/*    <StarRateIcon/>*/}
		{/*</div>*/}
		<h2>Satoshi Nakamoto</h2>
		{
		    showPDF ?
			<Backdrop
			    sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
			    open={showPDF}
			>
			    <div className={"testimonial-section-pdfviewer"}>
				<Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.7.107/build/pdf.worker.min.js`}>
				    <PDFViever fileUrl={BitcoinPDF} close={handleClose}/>
				</Worker>
			    </div>
			</Backdrop>
			: <></>

		}
	    </div>
	</div>
    )
}




export default Testimonial;