import React, {useMemo, useState} from "react";

import {UnlockModal} from "../../styling/Diary.styles";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import {useDAO} from "../../../dao/DaoProvider";
import {Autocomplete, Button, Chip, Stack, TextField} from "@mui/material";
import Diary from "../../Diary.string";

import PublishIcon from '@mui/icons-material/Publish';
import {useLanguageService} from "../../../../language/LanguageProvider";

function PublishModalComponent(props) {

    const {storageContract} = useDAO();
    // const [title, setTitle] = useState("");
    const [tags, setTags] = useState([]);

    const [availableTags, setAvailableTags] = useState(["Tag1", "Tag2"]);

    const {documentTitle: title} = props;

    useMemo(() => {

        (async () => {
            let storedTags = await storageContract.getAllTags();
            console.log(storedTags)
            setAvailableTags(storedTags);
        })();
    }, [])

    const clear = () => {
        // props.setDocumentTitle("");
        setTags([])
    }
    const submit = (e) => {
        e.preventDefault();
        console.log(title, tags, props)
        if (title.length > 0 && tags.length > 0) {
            props.publishWithDetails({title: title, tags: tags});
        } else {
            //TODO: trigger validation
            alert("Insert atleast 1 Tag")
        }
    }

    return (
        <UnlockModal style={{display: (props.showModal) ? "grid" : "none"}}>

            <div id={"content"} style={{
                width: "65%",
                height: "unset",
                boxShadow: "1px 1px 16px 2px",
                paddingTop: 60,
                paddingBottom: 40
            }}>
                <header>
                    <PublishIcon sx={{fontSize: "2.2em"}}/>
                    <h2> Publish Document</h2>
                </header>
                <div id={"closeBtn"} onClick={() => {
                    clear();
                    props.setShowModal(false)
                }}><CloseIcon/></div>

                <section id={"document_description"} style={{textAlign: "center"}}>
                    <p>{Diary.strings.DOCUMENT_PUBLISH_DESCRIPTION}</p>
                </section>

                {/*<p></p>*/}

                <form style={{width: "100%"}} onSubmit={submit}>

                    <TextField
                        sx={{
                            mb: 4,
                            alignItems: "center",
                            'legend': {
                                fontSize: "1.2em"
                            }
                        }}
                        fullWidth
                        multiline
                        label={"Title"}
                        placeholder={Diary.strings.DOCUMENT_TITLE_PLACEHOLDER}
                        variant={"outlined"}
                        onChange={(e) => {
                            // setTitle(e.target.value)
                            props.setDocumentTitle(e.target.value)
                        }}
                        value={title}
                        InputProps={{
                            style: {height: "160px", fontSize: "1.2em", textAlign: "center"}
                        }}
                        InputLabelProps={{
                            style: {fontSize: "1.8em", width: 200}
                        }}
                        required
                    />
                    <TagViewer selectedTags={tags} setSelectedTags={setTags}/>

                    <TagSelector
                        availableTags={availableTags}
                        selectedTags={tags}
                        setSelectedTags={setTags}
                        maxTags={10}
                        required

                    />

                    <Button
                        variant={"contained"}
                        endIcon={<PublishIcon/>}
                        type={"submit"}
                        // onClick={() => {
                        //     props.publishWithDetails({title: title, tags: tags})
                        // }}
                        style={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            right: 0,
                            height: 40,
                            borderTopRightRadius: 0,
                            borderTopLeftRadius: 0
                        }}
                    >Publish</Button>

                </form>

                <section id={"document_description"} style={{textAlign: "center"}}>
                    <p className={"warning"} style={{fontSize: "1.2em"}}>Caution!</p>
                    <p className={"warning"}>{Diary.strings.DOCUMENT_PUBLISH_DESCRIPTION_WARNING}</p>
                </section>


            </div>
        </UnlockModal>
    )

}

PublishModalComponent.propTypes = {
    showModal: PropTypes.bool,
    setShowModal: PropTypes.func,
    publishWithDetails: PropTypes.func,

    documentTitle: PropTypes.string,
    setDocumentTitle: PropTypes.func
}
export default PublishModalComponent;

export function TagViewer(props) {

    const {strings} = useLanguageService();

    const viewOnly = props.viewOnly;

    const handleDelete = (tagToRemove) => {
        let newTags = props.selectedTags.filter(tag => tag !== tagToRemove);
        props.setSelectedTags(newTags);
    }

    if (props.selectedTags.length === 0) {
        return (
            <>
                <p style={{color: "grey", paddingLeft: 12}}>{strings.TAGS_PLACEHOLDER}</p>
            </>
        )
    } else {
        return (
            // <fieldset aria-required={props.selectedTags.length > 0} style={{margin: 0}}>
            //     <legend>Your selected tags</legend>
            <Stack direction="row" gap={0.6} style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: props.style?.justifyContent,
                fontSize: props.style?.fontSize
            }}>
                {
                    props.selectedTags.map((tag, key) => {
                        if (viewOnly)
                            return (
                                <Chip
                                    key={key}
                                    label={tag}
                                    style={{
                                        fontSize: props.style?.fontSize,
                                        borderRadius: props.style?.borderRadius,
                                        height: props.style?.height,
                                    }}
                                />
                            )
                        else
                            return (
                                <Chip
                                    key={key}
                                    label={tag}
                                    onDelete={() => {
                                        handleDelete(tag)
                                    }}
                                    style={{
                                        fontSize: props.style?.fontSize,
                                        borderRadius: props.style?.borderRadius,
                                        height: props.style?.height,
                                    }}
                                />
                            )
                    })
                }
            </Stack>
            // </fieldset>
        )
    }
}

TagViewer.propTypes = {
    selectedTags: PropTypes.array,
    setSelectedTags: PropTypes.func,
    viewOnly: PropTypes.bool,
    style: PropTypes.object
}

// --- MultiSelect for TAGS ---
function TagSelector(props) {

    const handleChange = (e, selectedTags) => {
        if (selectedTags.length < props.maxTags) {
            props.setSelectedTags(selectedTags);
        } else return;
    }

    const handleEnter = (value) => {
        console.log(value)
        let newTags = [...props.selectedTags];
        if (!newTags.includes(value)) {
            newTags.push(value);
            props.setSelectedTags(newTags);
        }
    }

    return (
        <Autocomplete
            sx={{paddingTop: 2}}
            // size={"medium"}
            multiple
            freeSolo
            fullWidth
            options={props.availableTags}
            getOptionLabel={(option) => option}
            disableCloseOnSelect
            value={props.selectedTags}
            renderTags={(value, getTagProps) => (
                // Render custom tags here, or return null to prevent rendering
                null
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label="Tags"
                    placeholder="Choose or insert new Tags"
                    // InputProps={{
                    //     style: {height: "100px"}
                    // }}
                    InputProps={{
                        ...params.InputProps,
                        required: props.selectedTags.length === 0
                    }}
                    InputLabelProps={{
                        style: {justifyContent: "center"},
                    }}
                    required={props.required}
                />
            )}
            onChange={handleChange}
            aria-required={true}
        />


        // <TextField
        //     sx={{mt: 4}}
        //     variant="outlined"
        //     label="Tags"
        //     placeholder="Choose or insert new Tags"
        //     // InputProps={{
        //     //     style: {height: "100px"}
        //     // }}
        //     // InputProps={{
        //     // required: props.selectedTags.length === 0
        //     // }}
        //     // InputLabelProps={{
        //     // style: {justifyContent: "center"},
        //     // }}
        //     style={{width: "100%"}}
        //     // required={props.required}
        //     onKeyDown={e => {
        //         if (e.key === "Enter") {
        //             e.preventDefault();
        //             handleEnter(e.target.value)
        //             e.target.value = ""
        //         }
        //     }}
        // />

    )
}

TagSelector.propTypes = {
    maxTags: PropTypes.number.isRequired,
    availableTags: PropTypes.array,
    selectedTags: PropTypes.array,
    setSelectedTags: PropTypes.func,
    required: PropTypes.bool
}