import React, {useEffect, useMemo, useState} from "react";
import {useDAO} from "../../dao/DaoProvider";
import Logo from "../logo/Logo";
import Image from "../imageFetcher/Image";
import NetworkUtils from "../../../utils/NetworkUtils";
import Web3 from "web3";
import styled from "styled-components";
import PropTypes from "prop-types";


export function NetworkViewer(){

    const {readOnly, library} = useDAO();
    let chainId = library.network.chainId;

    const [mainNetwork, setMainNetwork] = useState(null);

    useEffect(() => {
        if(readOnly)
            setMainNetwork(NetworkUtils.getNetwork(library.network.chainId))
        else{
            setMainNetwork(NetworkUtils.getNetworkFromChainIdHex(library.provider.chainId))
        }
    }, []);

    if(mainNetwork){
        return(
            <>
                <div style={{
                    position: "absolute",
                    display: 'flex',
                    alignItems: "center",
                    top: 0,
                    padding: "12px",
                    gap: "6px"
                }}>
                    <Logo src={Image.getImageSource(mainNetwork.chainId)} style={{width: "30px"}}/>
                    <div>
                        <p>Network: {mainNetwork.name}</p>
                    </div>
                </div>
            </>

        )
    }else{
        return(
            <></>
        )
    }



}

export function BlockViewer({style}){

    const {readOnly, library} = useDAO();
    // let mainNetwork;
    // if(readOnly)
    //     mainNetwork = NetworkUtils.getNetwork(library.network.chainId)
    // else
    //     mainNetwork = NetworkUtils.getNetwork(library.provider.chainId)

    const [block, setBlock] = useState(0);

    const [dotDiv, setDotDiv] = useState(<NetworkDotDiv/>);
    const [opacity, setOpacity] = useState(0.5);

    useMemo(() => {
        if(library){
            console.log(library)
            let web3 = new Web3(library.provider)
            web3.eth.getBlock('latest').then(block => {
                console.log("Set blocknumber", block.number)
                setBlock(block.number)
            })
        }
    }, [library]);

    useEffect(() => {
        let subscription;
        if (library) {
            console.log("Subscribe to Event - NewBlock")
            let web3 = new Web3(library.provider)
            console.log(web3)
            subscription = web3.eth.subscribe('newBlockHeaders');
            subscription.on('data', (blockHeader) => {
                // console.log("New Block", blockHeader.number)
                setBlock(blockHeader.number)
                setDotDiv(
                    <NetworkDotDiv>
                        <NetworkDotAnimation/>
                    </NetworkDotDiv>
                )
                setOpacity(1)
                setTimeout(()=> {
                    setDotDiv(
                        <NetworkDotDiv>
                        </NetworkDotDiv>
                    )
                    setOpacity(0.5)
                }, 2000)
            })

            // library.on('block', (block) => {
            //     console.log("New Block", block)
            // //     setBlock(block)
            // //     setDotDiv(
            // //         <NetworkDotDiv>
            // //             <NetworkDotAnimation/>
            // //         </NetworkDotDiv>
            // //     )
            // //     setOpacity(1)
            // //     setTimeout(()=> {
            // //         setDotDiv(<NetworkDotDiv/>)
            // //         setOpacity(0.5)
            // //     }, 2000)
            // })
        }
        return() => {
            subscription.unsubscribe((error, success) => {
                if(success)
                    console.log("Successfully unsubscribed receiving new Block-Header")
            })
        }

    }, [library])


    return(
        <div style={{
            position: (style?.position ? style.position : "relative"),
            bottom: 0,
            right: 0,
            display: "flex",
            alignItems: "center",
            padding: "0.5rem",
            transition: "color 250ms ease 0s",
            gap: "6px"
        }}>
            <BlockNumberDiv style={{
                opacity: opacity
            }}>{block}</BlockNumberDiv>
            {dotDiv}
        </div>
    )

}

BlockViewer.propTypes = {
    style: PropTypes.object
}

const BlockNumberDiv = styled.div`
  transition: opacity 0.25s ease 0s;
  color: ${(props) => props.theme.global.base};
  font-family: 'Inter custom', sans-serif;
  font-size: 11px;
  font-weight: 600;
`;

export const NetworkDotDiv = styled.div`
  width: 8px;
  height: 8px;
  //min-height: 8px;
  //min-width: 8px;
  border-radius: 50%;
  display: block;
  position: relative;
  background-color: ${(props) => props.theme.global.base};
  transition: background-color 250ms ease 0s;
`;

export const NetworkDotAnimation = styled.div`

  display: inline-block;
  position: absolute;
  top: 16px;
  left: -3px;
  
  &:after {
    content: " ";
    display: block;
    position: absolute;
    top: -19.5px;
    width: 10px;
    height: 10px;
    //margin: 8px;
    border-radius: 50%;
    border: 2px solid #000;
    border-color: ${(props) => props.theme.global.base} transparent ${(props) => props.theme.global.base} transparent;
    animation: lds-dual-ring 2s ease infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(720deg);
    }
    100% {
      transform: rotate(1440deg);
    }
  }
`;