import React from 'react';
import {useWeb3React} from "@web3-react/core";
import NetworkUtils from "../../../utils/NetworkUtils";
import Image from "../../../components/common/imageFetcher/Image";
import PropTypes from "prop-types";
import {TileDiv} from "./Tiles.styles";


export const NetworkInfo = ({switchable=true}) => {

    const { activate, deactivate, active, chainId, connector, account, library } = useWeb3React();

    const switch_network = () => {

    }

    let network = NetworkUtils.getNetwork(chainId)

    return(
	<TileDiv>
	    <network-desc>
		<img src={Image.getImageSource(network.chainId)}/>
		{network.name}
	    </network-desc>
	</TileDiv>
    )

}
NetworkInfo.propTypes = {
    switchable: PropTypes.bool
}
