import LogoImage from "../../../assets/logo/new/logo_only.svg";
import React, {useEffect, useState} from "react";
import {useWeb3React} from "@web3-react/core";
import {reduceKeyString} from "../../../../utils/StringUtils";

import {AccountSwitcher, BreadCrumbler, Menubar, MenuDocumentList, ResizeDragger} from "./Menu.styles";
import {useLanguageService} from "../../../../language/LanguageProvider";
import {useWorkspace} from "../../../../components/workspace/WorkspaceProvider";

import PropTypes from "prop-types";

import {DragIndicator, Public} from "@mui/icons-material";

import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import AddIcon from '@mui/icons-material/Add';

import {useDAO} from "../../../../components/dao/DaoProvider";
import {Button} from "@mui/material";
import LoadingHexagon from "../../../../components/common/loadingHexagon/LoadingHexagon";
import {BlockViewer} from "../../../../components/common/uiUtils/Utils";
import {useWorkspaceService} from "../../../../components/workspace/WorkspaceService";
import {Avatar} from "../../components/Avatar";
import LockIcon from "@mui/icons-material/LockOutlined";
import {useIPFS} from "../../../../components/ipfs/IpfsProvider";

export function WorkspaceMenu(props){

    const {account,library} = useWeb3React();
    const {strings} = useLanguageService();

    const {workspace, unlocked} = useWorkspace();
    const workspaceService = useWorkspaceService();
    // console.log(workspaceService)

    const [menuLoading, setMenuLoading] = useState(false);

    const web3docs = useDAO();
    const workspaceContract = web3docs.storageContract;

    const ipfs = useIPFS();

    useEffect(() => {
        console.log("Is Workspace unlocked?", workspace.isUnlocked())
        if(workspace.isUnlocked()){
            (async() => {

                let root_document = workspace.getWorkspaceRootDocument();

                if(props.workspaceExist){
                    console.log("RootDocument", root_document)
                    let space = await workspaceContract.getWorkspace(root_document.address);
                    // console.log("Workspace", space)

                    // let web3 = new Web3();

                    // console.log(space.document_ids.map(seeds => web3.utils.toNumber(seeds)).sort())

                    let docs = []
                    // console.log("Document_Ids", space.document_ids)
                    // for(let document_id of space.document_ids){
                    //     console.log(document_id)
                    //     console.log(document_id.toNumber())
                    // }

                    for(let document_id of space.document_ids){
                        let document = workspace.getWorkspaceDocument(document_id.toNumber());
                        // console.log(document)
                        docs.push(document);
                        // console.log("DOC", document.address)
                        // // docs.push(document)
                    }
                    props.addDocumentsToMenu(docs)
                    // props.addDocumentsToMenu(docs)
                }

                // setLoading(true);
                // handleWorkspaceUnlock();


                // console.log("ADD ROOT-DOCUMENT")
                // let root_document = workspace.getWorkspaceDocument(0);
                // console.log(root_document)
                // let document = {
                //     title: root_document.address,
                //     id: root_document.address,
                //     icon: <DescriptionIcon/>
                // }
                // setDocuments([...documents, document])
            })();
        }

    }, [unlocked, props.workspaceExist])

    // useEffect(() => {
    //     console.log(props.documents)
    // }, [props.documents]);

    const handleWorkspaceUnlock = () => {
        if(workspace.isLocked()){
            // props.handleUnlock();
            workspaceService.showUnlockModal();
        }else if(props.workspaceExist === false){
            props.initWorkspace();
        }
    }

    const handleAddDocument = () => {
        props.generateDocument();
    }

    console.log(props)

    return(
        <MenubarResizable dragger_size={4} isClosed={props.isClosed} setIsClosed={props.setIsClosed}>

            <AccountSwitcher>
                <Button onClick={() => {props.back()}} variant={"outlined"}>Back</Button>

                <div
                    style={{width: "80px", height: "80px", overflow: "hidden"}}
                >
                    <Avatar/>
                </div>

                <div>
                    {/*<label>Welcome</label>*/}
                    <label>{reduceKeyString(account, 6, 6)}</label>
                </div>
                <div>
                    <p>Workspace</p>
                </div>

                <BlockViewer/>
                <BreadCrumble/>

                {
                    workspace.isUnlocked() ?
                        <MenuDocumentList>
                            <ul>
                                <li style={{padding: "0px 16px 0px 28px"}} >
                                    <Button fullWidth startIcon={<LockIcon style={{fontSize: "1em"}}/>} onClick={() => {
                                        props.lockWorkspace();
                                    }} variant={"outlined"}>Lock</Button>
                                </li>
                            </ul>
                        </MenuDocumentList>
                        : <></>
                }

            </AccountSwitcher>
            <hr/>
            <MenuDocumentList hover>
                <ul>
                    {
                        workspace.isLocked()
                            ?
                            <li>
                                <div style={{flexDirection: "column", rowGap: "16px"}}>
                                    <label>Your local workspace is locked.</label>
                                    <button onClick={() => {handleWorkspaceUnlock()}}>Unlock my workspace</button>
                                </div>
                            </li>
                            :

                            props.loading ?
                                <li>
                                    <div style={{justifyContent: "center"}}>
                                        <LoadingHexagon style={{marginTop: "30px"}}>Loading Workspace</LoadingHexagon>
                                    </div>
                                </li>
                            :
                            props.workspaceExist === false ?
                                <li>
                                    <div style={{flexDirection: "column", rowGap: "16px"}}>
                                        <label>You need to create a Workspace.</label>
                                        <button onClick={() => {handleWorkspaceUnlock()}}>Create workspace</button>
                                    </div>
                                </li>
                            :
                            props.documents.size === 0
                                ?
                            <li>
                                <div>
                                    <label>No documents available</label>
                                </div>
                            </li>
                            :
                            <></>
                    }

                    {
                        [...props.documents.values()].map((document, key) => {
                            console.log(document)
                            return (
                                <li
                                    key={key}
                                    id={document.address}
                                    className={props.currentDocument?.address === document.address ? "selected" : ""}
                                    onClick={() => {props.setCurrentDocument(document)} }
                                >
                                    <div style={{
                                        display: "grid",
                                        gridTemplateColumns: "auto auto 60px",
                                        height: "100%",
                                        columnGap: "32px",
                                    }} className={"document_"+document.status}>
                                        {document.icon}
                                        {
                                            document.details?.title
                                                ?
                                                <label>{document.details.title}</label>
                                                :
                                                <label>{document.id} <p style={{color: "grey"}}>{document.index}</p> </label>
                                        }
                                        {
                                            document.details?.sealed
                                            ?
                                                <div style={{display: "flex", flexDirection: "column", fontSize: "0.8em"}}>
                                                    <Public/>
                                                    <label style={{fontSize: "1em"}}>Sealed</label>
                                                </div>
                                                :
                                                <></>
                                        }

                                    </div>
                                </li>
                            )
                        })
                    }

                </ul>
            </MenuDocumentList>

            <hr/>
            {
                workspace.isUnlocked() && props.workspaceExist
                    ?
                    <MenuDocumentList style={{overflowY: "unset"}}>
                        <ul>
                            <li>
                                <div>
                                    <Button
                                        fullWidth
                                        onClick={() => {
                                            handleAddDocument()
                                        }}
                                        startIcon={<AddIcon/>}
                                    >
                                        Add document
                                    </Button>
                                </div>
                            </li>
                        </ul>
                    </MenuDocumentList>
                    :
                    <></>
            }


        </MenubarResizable>
    )

}

WorkspaceMenu.propTypes = {
    back: PropTypes.func,
    // documents: PropTypes.array,
    isClosed: PropTypes.bool,
    setIsClosed: PropTypes.func,

    initWorkspace: PropTypes.func,
    loading: PropTypes.bool,
    workspaceExist: PropTypes.bool,
    lockWorkspace: PropTypes.func,

    documents: PropTypes.object,
    currentDocument: PropTypes.object,
    addDocumentsToMenu: PropTypes.func,
    generateDocument: PropTypes.func,

    setCurrentDocument: PropTypes.func
}

function MenubarResizable({children, dragger_size=10, isClosed, setIsClosed}){
    const [divWidth, setDivWidth] = useState(300); // Initial width
    const [tempDivWidth, setTempDivWidth] = useState(divWidth);
    const [isResizing, setIsResizing] = useState(false);
    // const [isClosed, setIsClosed] = useState(false);
    const handleMouseMove = (e) => {

        let x_pos = e.clientX - 5;
        if(x_pos >= 300){
            setDivWidth(x_pos); // Update width based on mouse position
            setTempDivWidth(x_pos);
            setIsClosed(false)
        }

    };

    useEffect(() => {
        if(isResizing){
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', () => {
                window.removeEventListener('mousemove', handleMouseMove);
                setIsResizing(false)
            })
        }else{
            console.log("Remove EventMouseMove")
            window.removeEventListener('mousemove', handleMouseMove);
        }
    }, [isResizing]);

    useEffect(() => {
        if(isClosed){
            setDivWidth(0)
        }else{
            setDivWidth(tempDivWidth)
        }
    }, [isClosed])

    const handleClose = (e) => {
        setIsClosed(!isClosed)
    }

    return (
        <div
            style={{
                display: "flex",
                zIndex: 1
            }}
        >
            <Menubar
                isResizing={isResizing}
                isClosed={isClosed}
                style={{ width: divWidth }}
            >
                <div
                    className={"close-icon-button"}
                    onClick={handleClose}
                >
                    <KeyboardDoubleArrowLeftIcon/>
                </div>

                {children}
            </Menubar>

            <ResizeDragger
                isClosed={isClosed}
                data-resizing={isResizing}
                dragger_size={isClosed ? 0 : dragger_size}
                // style={{width: dragger_size}}
                onMouseDown={() => setIsResizing(true)}
                onMouseUp={() => setIsResizing(false)}
            >
                <div className={"dragger-icon"}
                     style={{ width: (isClosed ? "0px" : "16px") }}
                >
                    <DragIndicator style={{color: "white"}}/>
                </div>
            </ResizeDragger>


        </div>
    );
}
MenubarResizable.propTypes = {
    children: PropTypes.node,
    dragger_size: PropTypes.number,
    isClosed: PropTypes.bool,
    setIsClosed: PropTypes.func
}

function BreadCrumble(){
    return(
        <BreadCrumbler>
            <div style={{width: "50px", padding: "6px 12px"}}>
                <img src={LogoImage} alt={"logo"}/>
            </div>
            <div style={{display: "flex", flexDirection: "column",justifyContent: "center"}}>
                <header>My Workspace</header>
                <ul>
                    <li>App</li>
                    <li>Workspace</li>
                </ul>
            </div>
            <div>

            </div>
        </BreadCrumbler>
    )
}


