import React from "react";
import PropTypes from "prop-types";

import Logo from "../../components/common/logo/Logo";

import {FollowButton, FollowButtonContainer} from "../common/followButtons/FollowButton";

import {UnderConstructionContainer} from "./UnderConstruction.styles";
import {useLanguageService} from "../../language/LanguageProvider";

export function UnderConstructionPage(){

    const {errorTexts, landingEarlyAccess} = useLanguageService();


    return(
        <UnderConstructionContainer>
            <div>
                <div style={{overflow: "hidden"}}>
                    <Logo logoType={1} style={{width: "40%", margin: "unset !important", scale: "1.8"}}/>
                    <br/>
                    <h2>Pre-Alpha</h2>
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        padding: 20,
                        textAlign: "center",
                    }}
                >
                    <h1>{errorTexts.UNDER_CONSTRUCTION}</h1>
                    <p style={{fontSize: "1.2em"}}>{errorTexts.UNDER_CONSTRUCTION_DESCRIPTION}</p>
                    {/*<hr style={{width: "100%"}}/>*/}
                    <br/>
                    <br/>
                    <p style={{fontSize: "0.9em"}}>{landingEarlyAccess.FINISHED_ONBOARDING_TEXT}</p>


                    <FollowButtonContainer>
                        <FollowButton twitter/>
                        {/*<FollowButton linkedIn/>*/}
                        {/*<FollowButton facebook/>*/}
                        <FollowButton instagram/>
                    </FollowButtonContainer>

                </div>
            </div>
        </UnderConstructionContainer>
    )

}
UnderConstructionPage.propTypes = {
    back: PropTypes.func
}

