import React, {useEffect, useState} from 'react';
import {useWorkspaceService} from "../../../components/workspace/WorkspaceService";
import {FooterMenu} from "./menu/Menu";
import PropTypes from "prop-types";
import {Route, Routes} from "react-router-dom";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {Avatar} from "../components/Avatar";
import {useWeb3React} from "@web3-react/core";
import * as StringUtils from "../../../utils/StringUtils";
import {FollowDot} from "./WorkspaceMobile.styles";
import Logo from "../../../components/common/logo/Logo";
import BackgroundImg from "../../assets/background_full.png";

import styled from "styled-components";
import {FollowButton, FollowButtonContainer} from "../../common/followButtons/FollowButton";
import {Button} from "@mui/material";
import {ArrowBack} from "@mui/icons-material";

export function WorkspaceMobile({back, visitLibrary}){

    const workspaceService = useWorkspaceService();

    const [underDevelopment, setUnderDevelopment] = useState(true);

    useEffect(() => {
        console.log(workspaceService)
    }, []);

    if(underDevelopment){
        return(
            <Fallback back={back}/>
        )
    }else{
        return(
            <>
                {/*<TopMenu*/}
                {/*    back={back}*/}
                {/*/>*/}
                <Routes>
                    <Route index element={<Home/>}/>
                    <Route path={"/home"} element={<Home/>} />
                    <Route path={"/account"} element={<Account/>} />
                </Routes>
                <FooterMenu
                    // back={back}
                    visitLibrary={visitLibrary}
                />
            </>
        )
    }



}
WorkspaceMobile.propTypes = {
    back: PropTypes.func,
    visitLibrary: PropTypes.func
}

function Account(){

    const {account} = useWeb3React();

    return(
        <div
            style={{
                height: "100vh",
                backgroundColor: "white",
                paddingTop: "20px",
                paddingInlineStart: "20px",
                paddingInlineEnd: "20px"
            }}
        >
            <div
                style={{
                    height: "40px",
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                    // float: "right"
                }}
            >
                <SettingsOutlinedIcon style={{fontSize: "2rem"}}/>
            </div>
            <div
                style={{
                    display: "grid",
                    gap: "20px"
                }}
            >
                <div style={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "60px auto",
                    gap: "20px"
                }}>
                    <div style={{width: "50px", borderRadius: "50%", overflow: "hidden"}}>
                        <Avatar/>
                    </div>
                    <div
                        style={{
                            display: "grid",
                            gridTemplateRows: "50% 50%"
                        }}
                    >
                        <div>
                            <h3>{StringUtils.reduceKeyString(account, 10, 10)}</h3>
                        </div>
                        <div
                            style={{
                                width: "100%",
                                display: "grid",
                                justifyItems: "center",
                                alignItems: "center",
                                gridTemplateColumns: "max-content 40px max-content"
                            }}
                        >
                            <label>0 followers</label>
                            <FollowDot>.</FollowDot>
                            <label>10 following</label>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        width: "100%",
                        display: "grid",
                        justifyContent: "space-evenly",
                        // gridTemplateColumns: "45% 45%",
                        gridTemplateColumns: "90%",
                        gap: "10%"
                    }}
                >
                    {/*<Button variant={"outlined"}>View status</Button>*/}
                    {/*<Button variant={"outlined"}>Edit profile</Button>*/}
                </div>
            </div>
        </div>
    )
}

function Home(){
    return(
        <>
            <h1>Home</h1>
        </>
    )
}

function Fallback({back}){
    return(
        <FallbackContainer>

            <div>

                <div>
                    <Button
                        startIcon={<ArrowBack/>}
                        variant={"outlined"}
                        onClick={() => {back()}}
                    >
                        <span style={{lineHeight: "unset"}}>Back</span>
                    </Button>
                </div>

                <div style={{overflow: "hidden"}}>
                    <Logo logoType={1} style={{width: "40%", margin: "unset !important", scale: "1.8"}}/>
                    <br/>
                    <h2>Mobile Version</h2>
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        padding: 20,
                        textAlign: "center",
                    }}
                >
                    <h1>Under construction</h1>
                    <p>We are sorry, the Mobile-Version is still under construction and will be available at the Alpha-Version.</p>
                    <p>Stay tuned by following us</p>

                    <FollowButtonContainer>
                        <FollowButton twitter/>
                        <FollowButton linkedIn/>
                        <FollowButton facebook/>
                    </FollowButtonContainer>

                </div>
            </div>
        </FallbackContainer>
    )
}

Fallback.propTypes = {
    back: PropTypes.func
}

const FallbackContainer = styled.div`
    
    height: 100vh;
    background-image: url(${BackgroundImg});
    background-size: 400vh;
    background-repeat: no-repeat;
    
    & > div{
        height: 100%;
        display: grid;
        grid-template-rows: auto auto 40%;
        align-content: center;
        justify-items: center;
        gap: 20px;
        color: white;
        
        backdrop-filter: blur(8px);
    
        h1 {
            text-transform: uppercase;
            word-spacing: 100vw;
            font-size: 2.6em;
            padding-bottom: 1em;
        }
        
        p{
            font-size: 18px; /* Adjust the font size as needed */
            line-height: 1.6; /* Set a comfortable line height */
            margin-bottom: 20px; /* Add spacing between paragraphs */
            //text-align: justify;
        }
    
    }
    
    
    h2 {
        overflow: hidden;
        text-align: center;
        color: white;
    }
    h2:before,
    h2:after {
        background-color: #fff;
        content: "";
        display: inline-block;
        height: 3px;
        position: relative;
        vertical-align: middle;
        width: 80px;
        border-radius: 5px;
    }
    h2:before {
        right: 0.5em;
        margin-left: -50%;
    }
    h2:after {
        left: 0.5em;
        margin-right: -50%;
    }
    
`;
