import React, {useEffect, useState} from 'react';
import {useWeb3React} from "@web3-react/core";

import {Web3DocDaoProvider} from "./components/dao/DaoProvider";

import {ethers} from "ethers";
import Web3 from "web3";

import NetworkUtils from "./utils/NetworkUtils";
import Settings from "./components/Settings";

import Logo from "./components/common/logo/Logo";
import Images from "./components/common/imageFetcher/Image";

import RestrictedPage from "./components/common/restrictedPage/RestrictedPage";
import ViewSelection from "./components/viewSelectionPage/ViewSelection";
import LoadingHexagon from "./components/common/loadingHexagon/LoadingHexagon";

import {Button} from "@mui/material";
import EditOffIcon from '@mui/icons-material/EditOff';
import {useLanguageService} from "./language/LanguageProvider";
import {WorkspaceProvider} from "./components/workspace/WorkspaceProvider";


function App(props) {

    const {strings} = useLanguageService();

    const __main_network = NetworkUtils.TestNetworkID.CRYPTAGO_PRE;	//Change this to initial change the main operating network

    const {activate, deactivate, active, chainId, connector, account, library} = useWeb3React();
    const [web3Provider, setWeb3Provider] = useState(null);

    const [mainNetwork, setMainNetwork] = useState(__main_network);
    const [mainContractAddress, setMainContractAddress] = useState(Settings.Contract.Address.WEB3LIB_PRE);
    const [gasStationAddress, setGasStationAddress] = useState(Settings.Contract.Address.GAS_STATION);

    const [networkError, setNetworkError] = useState(false);

    const [readOnly, setReadOnly] = useState(false);

    // useMemo(() => {
    //
    // // console.log(window.ethereum, Web3Connector)
    // if(window.ethereum){
    //     console.log("Browser supports window.ethereum")
    //     fallback_switchNetwork(mainNetwork)
    // }else{
    //     console.log("Browser does not support window.ethereum")
    // }
    //
    //
    // }, [])

    //Triggers if User wants to Read-Only
    useEffect(() => {
        if (readOnly || active) {
            console.log("ReadOnly", NetworkUtils.Web3Connector, __main_network)
            _fetchWeb3Provider();
        }
    }, [readOnly, active])

    useEffect(() => {

        console.log(mainNetwork)

        activate(NetworkUtils.Web3Connector).then(response => {
            console.log("Activate Web3Connector :", response, connector)
        }).catch(error => {
            console.error(error)
        })


        // if(window.ethereum){
        //     console.log("Init Network-Change detector")
        //     window.ethereum.on('networkChanged', (chainId) => {
        // 	console.log("Network Changed to", chainId)
        // 	console.log("Switch to ->", NetworkUtils.getNetwork(Number(chainId)))
        // 	console.log(mainNetwork)
        // 	// setMainNetwork(NetworkUtils.getNetwork(Number(chainId)))
        //     })
        // }


    }, [mainNetwork])

    useEffect(() => {

        console.log("Library Changed", library)
        if (library) {
            console.log("Library available...")
            if (chainId !== NetworkUtils.getChainId(mainNetwork)) {
                console.log("Switching network....")
                switchNetwork(mainNetwork).then(response => {
                    console.log("Switched Network: ", response)
                }).catch((error) => {
                    console.error("Error", error)
                    setNetworkError(true);
                })
            }

            //SETTING Web3Provider TODO: Maybe not necessary cause library.provider <-<
            // setWeb3Provider(_getWeb3Provider())

        } else {
            fallback_switchNetwork(mainNetwork)
        }

    }, [library])

    const _fetchWeb3Provider = () => {
        console.log("Get-Library...")
        // if(!active){
        let web3;
        if (window.location.hostname === "localhost") {
            web3 = new Web3(NetworkUtils.getNetworkConfig(NetworkUtils.NetworkID.LOCALHOST).rpc);
        } else {
            web3 = new Web3(NetworkUtils.getNetworkConfig(mainNetwork).rpc);
        }
        let web3Provider = new ethers.providers.Web3Provider(web3.currentProvider);
        setWeb3Provider(web3Provider);
    }


    const switchNetwork = async (network) => {
        try {
            await library.provider.request({
                method: "wallet_switchEthereumChain",
                params: [{chainId: network.id}]
            })
        } catch (switchError) {
            console.error("SwitchError: ", switchError)
            let errorObj = {
                /**
                 * 4001: User rejected request
                 * 4902:
                 * -32002: wallet_switchEthereumChain already pending
                 */
                code: switchError.code,
                message: switchError.message
            }
            console.log(errorObj);
            throw errorObj;
        }
    }

    const fallback_switchNetwork = async (network) => {
        console.log("Fallback NetworkSwitch", network)
        if (window.ethereum && window.ethereum.isMetaMask) {
            console.log(window.ethereum, network.chainId)
            if (window.ethereum.chainId !== network.id) {
                let availableChainIds = await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [NetworkUtils.getNetworkForAdd(network)]
                })
            }
            //
            //
            //
            // let chainId = await window.ethereum.request({
            // method: 'wallet_switchEthereumChain',
            // params: [{ chainId: network.id }], // chainId must be in hexadecimal numbers
            // });
            if (chainId === mainNetwork.chainId) {
                setNetworkError(false)
            }
        }
    }


    //READ-Only Mode!
    if (readOnly && web3Provider) {

        console.log("Enter Read-Only Mode!", mainNetwork.chainId, mainNetwork.name)
        let library = web3Provider;
        console.log(library)
        return (
            <Web3DocDaoProvider
                library={library}
                web3lib={{address: mainContractAddress}}
                gasStation={{address: gasStationAddress}}
                readOnly={readOnly}
            >
                    <WorkspaceProvider>
                        <ViewSelection mainNetwork={mainNetwork}/>
                    </WorkspaceProvider>
            </Web3DocDaoProvider>
        )

    }

    if (active) {
        //Main Entry
        if (chainId === mainNetwork.chainId) {
            return (
                <Web3DocDaoProvider
                    library={library}
                    web3lib={{address: mainContractAddress}}
                    gasStation={{address: gasStationAddress}}
                    readOnly={readOnly}
                >
                    <WorkspaceProvider>
                        <ViewSelection mainNetwork={mainNetwork}/>
                    </WorkspaceProvider>
                </Web3DocDaoProvider>

            )
        } else {
            if (networkError) {
                // return <ErrorPage/>
                return (
                    <RestrictedPage info={"Wrong Network"}>
                        <p>In order to use this app, switch to the correct Network.</p>
                        <br/>
                        <Button
                            startIcon={<Logo src={Images.getImageSource(mainNetwork.chainId)}
                                             style={{width: "1.6em"}}/>}
                            variant={"outlined"}
                            style={{width: "100%"}}
                            onClick={() => {
                                fallback_switchNetwork(mainNetwork)
                            }}
                        >
                            Switch to {mainNetwork.name}
                        </Button>

                        {/*<p>Thank you for your patient,</p>*/}
                        {/*<p>Your Cryptago-Team</p>*/}
                    </RestrictedPage>
                )
            } else {
                return <LoadingHexagon>{strings.LOADING}</LoadingHexagon>
            }
        }

    } else {
        return (
            <RestrictedPage info={"Please activate your Web3-Provider"}>
                <p>In order to use this app, you need to enable your web3 provider or use Read-Only possibility.</p>
                <br/>
                <p>Status: {(active) ? "Actived" : "Pending"}</p>
                <br/>
                <div style={{display: "flex", justifyContent: "space-around"}}>
                    {
                        (window.ethereum)
                            ?
                            <Button
                                startIcon={<Logo src={Images.getImageSource(mainNetwork.chainId)}
                                                 style={{width: "1.6em"}}/>}
                                variant={"outlined"}
                                onClick={() => {
                                    fallback_switchNetwork(mainNetwork)
                                }}
                            >
                                Switch to {mainNetwork.name}
                            </Button>
                            :
                            <></>
                    }
                    <Button
                        startIcon={<EditOffIcon/>}
                        variant={"outlined"}
                        onClick={() => {
                            setReadOnly(true)
                        }
                        }>
                        Read-Only Mode
                    </Button>
                </div>
                <br/>
                <p>Thank you for your patient,</p>
                <p>Your Cryptago-Team</p>
            </RestrictedPage>
        )
    }
}

export default App;