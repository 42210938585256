const ABI = {

    genesis_version: [
	{
	    "inputs": [],
	    "name": "version",
	    "outputs": [
		{
		    "internalType": "uint8",
		    "name": "",
		    "type": "uint8"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	}
    ],
	genesis_v1: [
		{
			"inputs": [],
			"stateMutability": "nonpayable",
			"type": "constructor"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "string",
					"name": "message",
					"type": "string"
				}
			],
			"name": "AddDocument",
			"type": "event"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "root_document",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "document_id",
					"type": "uint256"
				}
			],
			"name": "addDocumentToWorkspace",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "sender",
					"type": "address"
				},
				{
					"internalType": "address",
					"name": "root_document",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "document_id",
					"type": "uint256"
				},
				{
					"internalType": "bytes",
					"name": "_signature",
					"type": "bytes"
				}
			],
			"name": "addDocumentToWorkspaceGasless",
			"outputs": [
				{
					"components": [
						{
							"internalType": "address",
							"name": "owner",
							"type": "address"
						},
						{
							"internalType": "uint256",
							"name": "timestamp",
							"type": "uint256"
						},
						{
							"internalType": "uint256[]",
							"name": "document_ids",
							"type": "uint256[]"
						}
					],
					"internalType": "struct WorkspaceAuthenticatorManager.Workspace",
					"name": "_workspace",
					"type": "tuple"
				}
			],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "address",
					"name": "previousAdmin",
					"type": "address"
				},
				{
					"indexed": false,
					"internalType": "address",
					"name": "newAdmin",
					"type": "address"
				}
			],
			"name": "AdminChanged",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": true,
					"internalType": "address",
					"name": "beacon",
					"type": "address"
				}
			],
			"name": "BeaconUpgraded",
			"type": "event"
		},
		{
			"inputs": [
				{
					"internalType": "string",
					"name": "_name",
					"type": "string"
				},
				{
					"internalType": "address",
					"name": "_verification_manager",
					"type": "address"
				}
			],
			"name": "initialize",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "sender",
					"type": "address"
				},
				{
					"internalType": "address",
					"name": "root_document",
					"type": "address"
				},
				{
					"internalType": "bytes",
					"name": "_signature",
					"type": "bytes"
				}
			],
			"name": "initWorkspace",
			"outputs": [
				{
					"components": [
						{
							"internalType": "address",
							"name": "owner",
							"type": "address"
						},
						{
							"internalType": "uint256",
							"name": "timestamp",
							"type": "uint256"
						},
						{
							"internalType": "uint256[]",
							"name": "document_ids",
							"type": "uint256[]"
						}
					],
					"internalType": "struct WorkspaceAuthenticatorManager.Workspace",
					"name": "_workspace",
					"type": "tuple"
				}
			],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "address",
					"name": "_sender",
					"type": "address"
				},
				{
					"indexed": false,
					"internalType": "string",
					"name": "message",
					"type": "string"
				}
			],
			"name": "InitWorkspace",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "uint8",
					"name": "version",
					"type": "uint8"
				}
			],
			"name": "Initialized",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": true,
					"internalType": "address",
					"name": "previousOwner",
					"type": "address"
				},
				{
					"indexed": true,
					"internalType": "address",
					"name": "newOwner",
					"type": "address"
				}
			],
			"name": "OwnershipTransferred",
			"type": "event"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_docAddress",
					"type": "address"
				},
				{
					"internalType": "string",
					"name": "_key",
					"type": "string"
				}
			],
			"name": "publish",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "address",
					"name": "_docAddress",
					"type": "address"
				},
				{
					"indexed": false,
					"internalType": "string",
					"name": "message",
					"type": "string"
				}
			],
			"name": "Published",
			"type": "event"
		},
		{
			"inputs": [],
			"name": "renounceOwnership",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "newOwner",
					"type": "address"
				}
			],
			"name": "transferOwnership",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": true,
					"internalType": "address",
					"name": "implementation",
					"type": "address"
				}
			],
			"name": "Upgraded",
			"type": "event"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "newImplementation",
					"type": "address"
				}
			],
			"name": "upgradeTo",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "newImplementation",
					"type": "address"
				},
				{
					"internalType": "bytes",
					"name": "data",
					"type": "bytes"
				}
			],
			"name": "upgradeToAndCall",
			"outputs": [],
			"stateMutability": "payable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_docAddress",
					"type": "address"
				},
				{
					"internalType": "string",
					"name": "entry",
					"type": "string"
				},
				{
					"internalType": "uint256",
					"name": "chunkSize",
					"type": "uint256"
				}
			],
			"name": "writeToDoc",
			"outputs": [
				{
					"components": [
						{
							"internalType": "string[]",
							"name": "content",
							"type": "string[]"
						},
						{
							"internalType": "uint256",
							"name": "timestamp",
							"type": "uint256"
						}
					],
					"internalType": "struct Genesis.Web3DocEntry[]",
					"name": "",
					"type": "tuple[]"
				}
			],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "root_document",
					"type": "address"
				}
			],
			"name": "getWorkspace",
			"outputs": [
				{
					"components": [
						{
							"internalType": "address",
							"name": "owner",
							"type": "address"
						},
						{
							"internalType": "uint256",
							"name": "timestamp",
							"type": "uint256"
						},
						{
							"internalType": "uint256[]",
							"name": "document_ids",
							"type": "uint256[]"
						}
					],
					"internalType": "struct WorkspaceAuthenticatorManager.Workspace",
					"name": "_workspace",
					"type": "tuple"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "sender",
					"type": "address"
				},
				{
					"internalType": "address",
					"name": "root_document",
					"type": "address"
				}
			],
			"name": "hasWorkspace",
			"outputs": [
				{
					"internalType": "bool",
					"name": "_isWorkspace",
					"type": "bool"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"name": "myDocs",
			"outputs": [
				{
					"components": [
						{
							"internalType": "string[]",
							"name": "content",
							"type": "string[]"
						},
						{
							"internalType": "uint256",
							"name": "timestamp",
							"type": "uint256"
						}
					],
					"internalType": "struct Genesis.Web3DocEntry",
					"name": "documentEntry",
					"type": "tuple"
				},
				{
					"internalType": "string",
					"name": "key",
					"type": "string"
				},
				{
					"internalType": "bool",
					"name": "isSealed",
					"type": "bool"
				},
				{
					"internalType": "uint8",
					"name": "version",
					"type": "uint8"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "name",
			"outputs": [
				{
					"internalType": "string",
					"name": "",
					"type": "string"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "operator",
			"outputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "owner",
			"outputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "proxiableUUID",
			"outputs": [
				{
					"internalType": "bytes32",
					"name": "",
					"type": "bytes32"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_address",
					"type": "address"
				}
			],
			"name": "readDocument",
			"outputs": [
				{
					"components": [
						{
							"internalType": "string[]",
							"name": "content",
							"type": "string[]"
						},
						{
							"internalType": "uint256",
							"name": "timestamp",
							"type": "uint256"
						}
					],
					"internalType": "struct Genesis.Web3DocEntry",
					"name": "_entries",
					"type": "tuple"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "version",
			"outputs": [
				{
					"internalType": "uint8",
					"name": "",
					"type": "uint8"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "workspace_manager",
			"outputs": [
				{
					"internalType": "contract WorkspaceAuthenticatorManager",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		}
	],

    genesis_v2: [
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "string",
					"name": "message",
					"type": "string"
				}
			],
			"name": "AddDocument",
			"type": "event"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "root_document",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "document_id",
					"type": "uint256"
				}
			],
			"name": "addDocumentToWorkspace",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "sender",
					"type": "address"
				},
				{
					"internalType": "address",
					"name": "root_document",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "document_id",
					"type": "uint256"
				},
				{
					"internalType": "bytes",
					"name": "_signature",
					"type": "bytes"
				}
			],
			"name": "addDocumentToWorkspaceGasless",
			"outputs": [
				{
					"components": [
						{
							"internalType": "address",
							"name": "owner",
							"type": "address"
						},
						{
							"internalType": "uint256",
							"name": "timestamp",
							"type": "uint256"
						},
						{
							"internalType": "uint256[]",
							"name": "document_ids",
							"type": "uint256[]"
						}
					],
					"internalType": "struct WorkspaceAuthenticatorManager.Workspace",
					"name": "_workspace",
					"type": "tuple"
				}
			],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "address",
					"name": "previousAdmin",
					"type": "address"
				},
				{
					"indexed": false,
					"internalType": "address",
					"name": "newAdmin",
					"type": "address"
				}
			],
			"name": "AdminChanged",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": true,
					"internalType": "address",
					"name": "beacon",
					"type": "address"
				}
			],
			"name": "BeaconUpgraded",
			"type": "event"
		},
		{
			"inputs": [
				{
					"internalType": "string",
					"name": "_name",
					"type": "string"
				},
				{
					"internalType": "address",
					"name": "_verification_manager",
					"type": "address"
				}
			],
			"name": "initialize",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "address",
					"name": "_sender",
					"type": "address"
				},
				{
					"indexed": false,
					"internalType": "string",
					"name": "message",
					"type": "string"
				}
			],
			"name": "InitWorkspace",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "uint8",
					"name": "version",
					"type": "uint8"
				}
			],
			"name": "Initialized",
			"type": "event"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "sender",
					"type": "address"
				},
				{
					"internalType": "address",
					"name": "root_document",
					"type": "address"
				},
				{
					"internalType": "bytes",
					"name": "_signature",
					"type": "bytes"
				}
			],
			"name": "initWorkspace",
			"outputs": [
				{
					"components": [
						{
							"internalType": "address",
							"name": "owner",
							"type": "address"
						},
						{
							"internalType": "uint256",
							"name": "timestamp",
							"type": "uint256"
						},
						{
							"internalType": "uint256[]",
							"name": "document_ids",
							"type": "uint256[]"
						}
					],
					"internalType": "struct WorkspaceAuthenticatorManager.Workspace",
					"name": "_workspace",
					"type": "tuple"
				}
			],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": true,
					"internalType": "address",
					"name": "previousOwner",
					"type": "address"
				},
				{
					"indexed": true,
					"internalType": "address",
					"name": "newOwner",
					"type": "address"
				}
			],
			"name": "OwnershipTransferred",
			"type": "event"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_docAddress",
					"type": "address"
				},
				{
					"internalType": "string",
					"name": "_key",
					"type": "string"
				}
			],
			"name": "publish",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "address",
					"name": "_docAddress",
					"type": "address"
				},
				{
					"indexed": false,
					"internalType": "string",
					"name": "message",
					"type": "string"
				}
			],
			"name": "Published",
			"type": "event"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_docAddress",
					"type": "address"
				},
				{
					"internalType": "string",
					"name": "_key",
					"type": "string"
				},
				{
					"internalType": "string",
					"name": "_title",
					"type": "string"
				},
				{
					"internalType": "string[]",
					"name": "_tags",
					"type": "string[]"
				}
			],
			"name": "publishWithDetails",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "renounceOwnership",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "newOwner",
					"type": "address"
				}
			],
			"name": "transferOwnership",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": true,
					"internalType": "address",
					"name": "implementation",
					"type": "address"
				}
			],
			"name": "Upgraded",
			"type": "event"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "newImplementation",
					"type": "address"
				}
			],
			"name": "upgradeTo",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "newImplementation",
					"type": "address"
				},
				{
					"internalType": "bytes",
					"name": "data",
					"type": "bytes"
				}
			],
			"name": "upgradeToAndCall",
			"outputs": [],
			"stateMutability": "payable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_docAddress",
					"type": "address"
				},
				{
					"internalType": "string",
					"name": "entry",
					"type": "string"
				},
				{
					"internalType": "uint256",
					"name": "chunkSize",
					"type": "uint256"
				}
			],
			"name": "writeToDoc",
			"outputs": [
				{
					"components": [
						{
							"internalType": "string[]",
							"name": "content",
							"type": "string[]"
						},
						{
							"internalType": "uint256",
							"name": "timestamp",
							"type": "uint256"
						}
					],
					"internalType": "struct Genesis.Web3DocEntry[]",
					"name": "",
					"type": "tuple[]"
				}
			],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "countPublishedDocuments",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "_count",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "countTags",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "_count",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "getAllTags",
			"outputs": [
				{
					"internalType": "string[]",
					"name": "_allTags",
					"type": "string[]"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "index",
					"type": "uint256"
				}
			],
			"name": "getPublishedDocument",
			"outputs": [
				{
					"components": [
						{
							"internalType": "address",
							"name": "document",
							"type": "address"
						},
						{
							"internalType": "string",
							"name": "title",
							"type": "string"
						},
						{
							"internalType": "string[]",
							"name": "tags",
							"type": "string[]"
						},
						{
							"internalType": "uint256",
							"name": "timestamp",
							"type": "uint256"
						}
					],
					"internalType": "struct Genesis_V2.PublishedDocument",
					"name": "_doc",
					"type": "tuple"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_docAddress",
					"type": "address"
				}
			],
			"name": "getPublishedDocumentPerAddress",
			"outputs": [
				{
					"components": [
						{
							"internalType": "address",
							"name": "document",
							"type": "address"
						},
						{
							"internalType": "string",
							"name": "title",
							"type": "string"
						},
						{
							"internalType": "string[]",
							"name": "tags",
							"type": "string[]"
						},
						{
							"internalType": "uint256",
							"name": "timestamp",
							"type": "uint256"
						}
					],
					"internalType": "struct Genesis_V2.PublishedDocument",
					"name": "_doc",
					"type": "tuple"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "root_document",
					"type": "address"
				}
			],
			"name": "getWorkspace",
			"outputs": [
				{
					"components": [
						{
							"internalType": "address",
							"name": "owner",
							"type": "address"
						},
						{
							"internalType": "uint256",
							"name": "timestamp",
							"type": "uint256"
						},
						{
							"internalType": "uint256[]",
							"name": "document_ids",
							"type": "uint256[]"
						}
					],
					"internalType": "struct WorkspaceAuthenticatorManager.Workspace",
					"name": "_workspace",
					"type": "tuple"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "sender",
					"type": "address"
				},
				{
					"internalType": "address",
					"name": "root_document",
					"type": "address"
				}
			],
			"name": "hasWorkspace",
			"outputs": [
				{
					"internalType": "bool",
					"name": "_isWorkspace",
					"type": "bool"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"name": "myDocs",
			"outputs": [
				{
					"components": [
						{
							"internalType": "string[]",
							"name": "content",
							"type": "string[]"
						},
						{
							"internalType": "uint256",
							"name": "timestamp",
							"type": "uint256"
						}
					],
					"internalType": "struct Genesis.Web3DocEntry",
					"name": "documentEntry",
					"type": "tuple"
				},
				{
					"internalType": "string",
					"name": "key",
					"type": "string"
				},
				{
					"internalType": "bool",
					"name": "isSealed",
					"type": "bool"
				},
				{
					"internalType": "uint8",
					"name": "version",
					"type": "uint8"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "name",
			"outputs": [
				{
					"internalType": "string",
					"name": "",
					"type": "string"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "operator",
			"outputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "owner",
			"outputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "proxiableUUID",
			"outputs": [
				{
					"internalType": "bytes32",
					"name": "",
					"type": "bytes32"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_address",
					"type": "address"
				}
			],
			"name": "readDocument",
			"outputs": [
				{
					"components": [
						{
							"internalType": "string[]",
							"name": "content",
							"type": "string[]"
						},
						{
							"internalType": "uint256",
							"name": "timestamp",
							"type": "uint256"
						}
					],
					"internalType": "struct Genesis.Web3DocEntry",
					"name": "_entries",
					"type": "tuple"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "version",
			"outputs": [
				{
					"internalType": "uint8",
					"name": "",
					"type": "uint8"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "workspace_manager",
			"outputs": [
				{
					"internalType": "contract WorkspaceAuthenticatorManager",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		}
	],	//Has no functions like publishWithDetails -> Title and Tags

	genesis_v3: [
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "string",
					"name": "message",
					"type": "string"
				}
			],
			"name": "AddDocument",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "address",
					"name": "previousAdmin",
					"type": "address"
				},
				{
					"indexed": false,
					"internalType": "address",
					"name": "newAdmin",
					"type": "address"
				}
			],
			"name": "AdminChanged",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": true,
					"internalType": "address",
					"name": "beacon",
					"type": "address"
				}
			],
			"name": "BeaconUpgraded",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "address",
					"name": "_sender",
					"type": "address"
				},
				{
					"indexed": false,
					"internalType": "string",
					"name": "message",
					"type": "string"
				}
			],
			"name": "InitWorkspace",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "uint8",
					"name": "version",
					"type": "uint8"
				}
			],
			"name": "Initialized",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": true,
					"internalType": "address",
					"name": "previousOwner",
					"type": "address"
				},
				{
					"indexed": true,
					"internalType": "address",
					"name": "newOwner",
					"type": "address"
				}
			],
			"name": "OwnershipTransferred",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "address",
					"name": "_docAddress",
					"type": "address"
				},
				{
					"indexed": false,
					"internalType": "string",
					"name": "message",
					"type": "string"
				}
			],
			"name": "Published",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": true,
					"internalType": "address",
					"name": "implementation",
					"type": "address"
				}
			],
			"name": "Upgraded",
			"type": "event"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "root_document",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "document_id",
					"type": "uint256"
				}
			],
			"name": "addDocumentToWorkspace",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "sender",
					"type": "address"
				},
				{
					"internalType": "address",
					"name": "root_document",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "document_id",
					"type": "uint256"
				},
				{
					"internalType": "bytes",
					"name": "_signature",
					"type": "bytes"
				}
			],
			"name": "addDocumentToWorkspaceGasless",
			"outputs": [
				{
					"components": [
						{
							"internalType": "address",
							"name": "owner",
							"type": "address"
						},
						{
							"internalType": "uint256",
							"name": "timestamp",
							"type": "uint256"
						},
						{
							"internalType": "uint256[]",
							"name": "document_ids",
							"type": "uint256[]"
						}
					],
					"internalType": "struct WorkspaceAuthenticatorManager.Workspace",
					"name": "_workspace",
					"type": "tuple"
				}
			],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "countPublishedDocuments",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "_count",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "countTags",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "_count",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "getAllTags",
			"outputs": [
				{
					"internalType": "string[]",
					"name": "_allTags",
					"type": "string[]"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "index",
					"type": "uint256"
				}
			],
			"name": "getPublishedDocument",
			"outputs": [
				{
					"components": [
						{
							"internalType": "address",
							"name": "document",
							"type": "address"
						},
						{
							"internalType": "string",
							"name": "title",
							"type": "string"
						},
						{
							"internalType": "string[]",
							"name": "tags",
							"type": "string[]"
						},
						{
							"internalType": "uint256",
							"name": "timestamp",
							"type": "uint256"
						}
					],
					"internalType": "struct Genesis_V2.PublishedDocument",
					"name": "_doc",
					"type": "tuple"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_docAddress",
					"type": "address"
				}
			],
			"name": "getPublishedDocumentPerAddress",
			"outputs": [
				{
					"components": [
						{
							"internalType": "address",
							"name": "document",
							"type": "address"
						},
						{
							"internalType": "string",
							"name": "title",
							"type": "string"
						},
						{
							"internalType": "string[]",
							"name": "tags",
							"type": "string[]"
						},
						{
							"internalType": "uint256",
							"name": "timestamp",
							"type": "uint256"
						}
					],
					"internalType": "struct Genesis_V2.PublishedDocument",
					"name": "_doc",
					"type": "tuple"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "root_document",
					"type": "address"
				}
			],
			"name": "getWorkspace",
			"outputs": [
				{
					"components": [
						{
							"internalType": "address",
							"name": "owner",
							"type": "address"
						},
						{
							"internalType": "uint256",
							"name": "timestamp",
							"type": "uint256"
						},
						{
							"internalType": "uint256[]",
							"name": "document_ids",
							"type": "uint256[]"
						}
					],
					"internalType": "struct WorkspaceAuthenticatorManager.Workspace",
					"name": "_workspace",
					"type": "tuple"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "sender",
					"type": "address"
				},
				{
					"internalType": "address",
					"name": "root_document",
					"type": "address"
				}
			],
			"name": "hasWorkspace",
			"outputs": [
				{
					"internalType": "bool",
					"name": "_isWorkspace",
					"type": "bool"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "sender",
					"type": "address"
				},
				{
					"internalType": "address",
					"name": "root_document",
					"type": "address"
				},
				{
					"internalType": "bytes",
					"name": "_signature",
					"type": "bytes"
				}
			],
			"name": "initWorkspace",
			"outputs": [
				{
					"components": [
						{
							"internalType": "address",
							"name": "owner",
							"type": "address"
						},
						{
							"internalType": "uint256",
							"name": "timestamp",
							"type": "uint256"
						},
						{
							"internalType": "uint256[]",
							"name": "document_ids",
							"type": "uint256[]"
						}
					],
					"internalType": "struct WorkspaceAuthenticatorManager.Workspace",
					"name": "_workspace",
					"type": "tuple"
				}
			],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "string",
					"name": "_name",
					"type": "string"
				},
				{
					"internalType": "address",
					"name": "_verification_manager",
					"type": "address"
				}
			],
			"name": "initialize",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"name": "myDocs",
			"outputs": [
				{
					"components": [
						{
							"internalType": "string[]",
							"name": "content",
							"type": "string[]"
						},
						{
							"internalType": "uint256",
							"name": "timestamp",
							"type": "uint256"
						}
					],
					"internalType": "struct Genesis.Web3DocEntry",
					"name": "documentEntry",
					"type": "tuple"
				},
				{
					"internalType": "string",
					"name": "key",
					"type": "string"
				},
				{
					"internalType": "bool",
					"name": "isSealed",
					"type": "bool"
				},
				{
					"internalType": "uint8",
					"name": "version",
					"type": "uint8"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "name",
			"outputs": [
				{
					"internalType": "string",
					"name": "",
					"type": "string"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "operator",
			"outputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "owner",
			"outputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "proxiableUUID",
			"outputs": [
				{
					"internalType": "bytes32",
					"name": "",
					"type": "bytes32"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_docAddress",
					"type": "address"
				},
				{
					"internalType": "string",
					"name": "_key",
					"type": "string"
				}
			],
			"name": "publish",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_docAddress",
					"type": "address"
				},
				{
					"internalType": "string",
					"name": "_key",
					"type": "string"
				},
				{
					"internalType": "string",
					"name": "_title",
					"type": "string"
				},
				{
					"internalType": "string[]",
					"name": "_tags",
					"type": "string[]"
				}
			],
			"name": "publishWithDetails",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_address",
					"type": "address"
				}
			],
			"name": "readDocument",
			"outputs": [
				{
					"components": [
						{
							"internalType": "string[]",
							"name": "content",
							"type": "string[]"
						},
						{
							"internalType": "uint256",
							"name": "timestamp",
							"type": "uint256"
						}
					],
					"internalType": "struct Genesis.Web3DocEntry",
					"name": "_entries",
					"type": "tuple"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "renounceOwnership",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "temp",
			"outputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "newOwner",
					"type": "address"
				}
			],
			"name": "transferOwnership",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "newImplementation",
					"type": "address"
				}
			],
			"name": "upgradeTo",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "newImplementation",
					"type": "address"
				},
				{
					"internalType": "bytes",
					"name": "data",
					"type": "bytes"
				}
			],
			"name": "upgradeToAndCall",
			"outputs": [],
			"stateMutability": "payable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_docAddress",
					"type": "address"
				},
				{
					"internalType": "string",
					"name": "entry",
					"type": "string"
				},
				{
					"internalType": "address",
					"name": "sender",
					"type": "address"
				},
				{
					"internalType": "bytes",
					"name": "_signature",
					"type": "bytes"
				}
			],
			"name": "verify",
			"outputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				},
				{
					"internalType": "bool",
					"name": "",
					"type": "bool"
				}
			],
			"stateMutability": "pure",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "sender",
					"type": "address"
				},
				{
					"internalType": "address",
					"name": "doc_address",
					"type": "address"
				},
				{
					"internalType": "string",
					"name": "encrypted_content",
					"type": "string"
				},
				{
					"internalType": "bytes",
					"name": "_signature",
					"type": "bytes"
				}
			],
			"name": "verifyDocumentContentSignature",
			"outputs": [
				{
					"internalType": "address",
					"name": "_signer",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "version",
			"outputs": [
				{
					"internalType": "uint8",
					"name": "",
					"type": "uint8"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "workspace_manager",
			"outputs": [
				{
					"internalType": "contract WorkspaceAuthenticatorManager",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_docAddress",
					"type": "address"
				},
				{
					"internalType": "string",
					"name": "entry",
					"type": "string"
				},
				{
					"internalType": "uint256",
					"name": "chunkSize",
					"type": "uint256"
				}
			],
			"name": "writeToDoc",
			"outputs": [
				{
					"components": [
						{
							"internalType": "string[]",
							"name": "content",
							"type": "string[]"
						},
						{
							"internalType": "uint256",
							"name": "timestamp",
							"type": "uint256"
						}
					],
					"internalType": "struct Genesis.Web3DocEntry[]",
					"name": "",
					"type": "tuple[]"
				}
			],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "sender",
					"type": "address"
				},
				{
					"internalType": "address",
					"name": "doc_address",
					"type": "address"
				},
				{
					"internalType": "string",
					"name": "encrypted_content",
					"type": "string"
				},
				{
					"internalType": "bytes",
					"name": "_signature",
					"type": "bytes"
				},
				{
					"internalType": "uint256",
					"name": "chunkSize",
					"type": "uint256"
				}
			],
			"name": "writeToDocGasless",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		}
	],

	gas_station: [
		{
			"inputs": [],
			"stateMutability": "nonpayable",
			"type": "constructor"
		},
		{
			"inputs": [],
			"name": "AccessControlBadConfirmation",
			"type": "error"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "account",
					"type": "address"
				},
				{
					"internalType": "bytes32",
					"name": "neededRole",
					"type": "bytes32"
				}
			],
			"name": "AccessControlUnauthorizedAccount",
			"type": "error"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "string",
					"name": "topic",
					"type": "string"
				},
				{
					"indexed": false,
					"internalType": "uint256",
					"name": "from",
					"type": "uint256"
				},
				{
					"indexed": false,
					"internalType": "uint256",
					"name": "to",
					"type": "uint256"
				},
				{
					"indexed": false,
					"internalType": "address",
					"name": "sender",
					"type": "address"
				}
			],
			"name": "BalanceChanged",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": true,
					"internalType": "bytes32",
					"name": "role",
					"type": "bytes32"
				},
				{
					"indexed": true,
					"internalType": "bytes32",
					"name": "previousAdminRole",
					"type": "bytes32"
				},
				{
					"indexed": true,
					"internalType": "bytes32",
					"name": "newAdminRole",
					"type": "bytes32"
				}
			],
			"name": "RoleAdminChanged",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": true,
					"internalType": "bytes32",
					"name": "role",
					"type": "bytes32"
				},
				{
					"indexed": true,
					"internalType": "address",
					"name": "account",
					"type": "address"
				},
				{
					"indexed": true,
					"internalType": "address",
					"name": "sender",
					"type": "address"
				}
			],
			"name": "RoleGranted",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": true,
					"internalType": "bytes32",
					"name": "role",
					"type": "bytes32"
				},
				{
					"indexed": true,
					"internalType": "address",
					"name": "account",
					"type": "address"
				},
				{
					"indexed": true,
					"internalType": "address",
					"name": "sender",
					"type": "address"
				}
			],
			"name": "RoleRevoked",
			"type": "event"
		},
		{
			"inputs": [],
			"name": "ADMIN_ROLE",
			"outputs": [
				{
					"internalType": "bytes32",
					"name": "",
					"type": "bytes32"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "DEFAULT_ADMIN_ROLE",
			"outputs": [
				{
					"internalType": "bytes32",
					"name": "",
					"type": "bytes32"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "GAS_REQUESTER_ROLE",
			"outputs": [
				{
					"internalType": "bytes32",
					"name": "",
					"type": "bytes32"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "REFILLER_ROLE",
			"outputs": [
				{
					"internalType": "bytes32",
					"name": "",
					"type": "bytes32"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "gasBalance",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "bytes32",
					"name": "role",
					"type": "bytes32"
				}
			],
			"name": "getRoleAdmin",
			"outputs": [
				{
					"internalType": "bytes32",
					"name": "",
					"type": "bytes32"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "bytes32",
					"name": "role",
					"type": "bytes32"
				},
				{
					"internalType": "address",
					"name": "account",
					"type": "address"
				}
			],
			"name": "grantRole",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "bytes32",
					"name": "role",
					"type": "bytes32"
				},
				{
					"internalType": "address",
					"name": "account",
					"type": "address"
				}
			],
			"name": "hasRole",
			"outputs": [
				{
					"internalType": "bool",
					"name": "",
					"type": "bool"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "amount",
					"type": "uint256"
				}
			],
			"name": "receiveGas",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "refillStation",
			"outputs": [],
			"stateMutability": "payable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "bytes32",
					"name": "role",
					"type": "bytes32"
				},
				{
					"internalType": "address",
					"name": "callerConfirmation",
					"type": "address"
				}
			],
			"name": "renounceRole",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "bytes32",
					"name": "role",
					"type": "bytes32"
				},
				{
					"internalType": "address",
					"name": "account",
					"type": "address"
				}
			],
			"name": "revokeRole",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "bytes4",
					"name": "interfaceId",
					"type": "bytes4"
				}
			],
			"name": "supportsInterface",
			"outputs": [
				{
					"internalType": "bool",
					"name": "",
					"type": "bool"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"stateMutability": "payable",
			"type": "receive"
		}
	]
}

const ABI_Archive = {
    genesis_v3: [
	{
	    "anonymous": false,
	    "inputs": [
		{
		    "indexed": false,
		    "internalType": "address",
		    "name": "previousAdmin",
		    "type": "address"
		},
		{
		    "indexed": false,
		    "internalType": "address",
		    "name": "newAdmin",
		    "type": "address"
		}
	    ],
	    "name": "AdminChanged",
	    "type": "event"
	},
	{
	    "anonymous": false,
	    "inputs": [
		{
		    "indexed": true,
		    "internalType": "address",
		    "name": "beacon",
		    "type": "address"
		}
	    ],
	    "name": "BeaconUpgraded",
	    "type": "event"
	},
	{
	    "anonymous": false,
	    "inputs": [
		{
		    "indexed": false,
		    "internalType": "uint8",
		    "name": "version",
		    "type": "uint8"
		}
	    ],
	    "name": "Initialized",
	    "type": "event"
	},
	{
	    "anonymous": false,
	    "inputs": [
		{
		    "indexed": true,
		    "internalType": "address",
		    "name": "previousOwner",
		    "type": "address"
		},
		{
		    "indexed": true,
		    "internalType": "address",
		    "name": "newOwner",
		    "type": "address"
		}
	    ],
	    "name": "OwnershipTransferred",
	    "type": "event"
	},
	{
	    "anonymous": false,
	    "inputs": [
		{
		    "indexed": false,
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		},
		{
		    "indexed": false,
		    "internalType": "string",
		    "name": "message",
		    "type": "string"
		}
	    ],
	    "name": "Published",
	    "type": "event"
	},
	{
	    "anonymous": false,
	    "inputs": [
		{
		    "indexed": true,
		    "internalType": "address",
		    "name": "implementation",
		    "type": "address"
		}
	    ],
	    "name": "Upgraded",
	    "type": "event"
	},
	{
	    "inputs": [],
	    "name": "countPublishedDocuments",
	    "outputs": [
		{
		    "internalType": "uint256",
		    "name": "_count",
		    "type": "uint256"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "countTags",
	    "outputs": [
		{
		    "internalType": "uint256",
		    "name": "_count",
		    "type": "uint256"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		},
		{
		    "internalType": "uint256",
		    "name": "entryIndex",
		    "type": "uint256"
		},
		{
		    "internalType": "string",
		    "name": "entry",
		    "type": "string"
		}
	    ],
	    "name": "editDocEntry",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "getAllTags",
	    "outputs": [
		{
		    "internalType": "string[]",
		    "name": "_allTags",
		    "type": "string[]"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "uint256",
		    "name": "index",
		    "type": "uint256"
		}
	    ],
	    "name": "getPublishedDocument",
	    "outputs": [
		{
		    "components": [
			{
			    "internalType": "address",
			    "name": "document",
			    "type": "address"
			},
			{
			    "internalType": "string",
			    "name": "title",
			    "type": "string"
			},
			{
			    "internalType": "string[]",
			    "name": "tags",
			    "type": "string[]"
			},
			{
			    "internalType": "uint256",
			    "name": "timestamp",
			    "type": "uint256"
			}
		    ],
		    "internalType": "struct Genesis_V2.PublishedDocument",
		    "name": "_doc",
		    "type": "tuple"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "string",
		    "name": "_name",
		    "type": "string"
		}
	    ],
	    "name": "initialize",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "",
		    "type": "address"
		}
	    ],
	    "name": "myDocs",
	    "outputs": [
		{
		    "internalType": "string",
		    "name": "key",
		    "type": "string"
		},
		{
		    "internalType": "bool",
		    "name": "isSealed",
		    "type": "bool"
		},
		{
		    "internalType": "uint8",
		    "name": "version",
		    "type": "uint8"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "name",
	    "outputs": [
		{
		    "internalType": "string",
		    "name": "",
		    "type": "string"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "operator",
	    "outputs": [
		{
		    "internalType": "address",
		    "name": "",
		    "type": "address"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "owner",
	    "outputs": [
		{
		    "internalType": "address",
		    "name": "",
		    "type": "address"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "proxiableUUID",
	    "outputs": [
		{
		    "internalType": "bytes32",
		    "name": "",
		    "type": "bytes32"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		},
		{
		    "internalType": "string",
		    "name": "_key",
		    "type": "string"
		}
	    ],
	    "name": "publish",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		},
		{
		    "internalType": "string",
		    "name": "_key",
		    "type": "string"
		},
		{
		    "internalType": "string",
		    "name": "_title",
		    "type": "string"
		},
		{
		    "internalType": "string[]",
		    "name": "_tags",
		    "type": "string[]"
		}
	    ],
	    "name": "publishWithDetails",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_address",
		    "type": "address"
		}
	    ],
	    "name": "readDocument",
	    "outputs": [
		{
		    "components": [
			{
			    "internalType": "string",
			    "name": "entry",
			    "type": "string"
			},
			{
			    "internalType": "uint256",
			    "name": "timestamp",
			    "type": "uint256"
			}
		    ],
		    "internalType": "struct Genesis.Web3DocEntry[]",
		    "name": "_entries",
		    "type": "tuple[]"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "renounceOwnership",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "newOwner",
		    "type": "address"
		}
	    ],
	    "name": "transferOwnership",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "newImplementation",
		    "type": "address"
		}
	    ],
	    "name": "upgradeTo",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "newImplementation",
		    "type": "address"
		},
		{
		    "internalType": "bytes",
		    "name": "data",
		    "type": "bytes"
		}
	    ],
	    "name": "upgradeToAndCall",
	    "outputs": [],
	    "stateMutability": "payable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		},
		{
		    "internalType": "string",
		    "name": "entry",
		    "type": "string"
		},
		{
		    "internalType": "address",
		    "name": "sender",
		    "type": "address"
		},
		{
		    "internalType": "bytes",
		    "name": "_signature",
		    "type": "bytes"
		}
	    ],
	    "name": "verify",
	    "outputs": [
		{
		    "internalType": "address",
		    "name": "",
		    "type": "address"
		},
		{
		    "internalType": "bool",
		    "name": "",
		    "type": "bool"
		}
	    ],
	    "stateMutability": "pure",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "version",
	    "outputs": [
		{
		    "internalType": "uint8",
		    "name": "",
		    "type": "uint8"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		},
		{
		    "internalType": "string",
		    "name": "entry",
		    "type": "string"
		}
	    ],
	    "name": "writeToDoc",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		},
		{
		    "components": [
			{
			    "internalType": "string",
			    "name": "entry",
			    "type": "string"
			},
			{
			    "internalType": "uint256",
			    "name": "timestamp",
			    "type": "uint256"
			}
		    ],
		    "internalType": "struct Genesis.Web3DocEntry",
		    "name": "_web3DocEntry",
		    "type": "tuple"
		},
		{
		    "internalType": "address",
		    "name": "sender",
		    "type": "address"
		},
		{
		    "internalType": "bytes",
		    "name": "_signature",
		    "type": "bytes"
		}
	    ],
	    "name": "writeToDocGasless",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	}
    ],

    genesis_v4: [
	{
	    "anonymous": false,
	    "inputs": [
		{
		    "indexed": false,
		    "internalType": "address",
		    "name": "previousAdmin",
		    "type": "address"
		},
		{
		    "indexed": false,
		    "internalType": "address",
		    "name": "newAdmin",
		    "type": "address"
		}
	    ],
	    "name": "AdminChanged",
	    "type": "event"
	},
	{
	    "anonymous": false,
	    "inputs": [
		{
		    "indexed": true,
		    "internalType": "address",
		    "name": "beacon",
		    "type": "address"
		}
	    ],
	    "name": "BeaconUpgraded",
	    "type": "event"
	},
	{
	    "anonymous": false,
	    "inputs": [
		{
		    "indexed": false,
		    "internalType": "uint8",
		    "name": "version",
		    "type": "uint8"
		}
	    ],
	    "name": "Initialized",
	    "type": "event"
	},
	{
	    "anonymous": false,
	    "inputs": [
		{
		    "indexed": true,
		    "internalType": "address",
		    "name": "previousOwner",
		    "type": "address"
		},
		{
		    "indexed": true,
		    "internalType": "address",
		    "name": "newOwner",
		    "type": "address"
		}
	    ],
	    "name": "OwnershipTransferred",
	    "type": "event"
	},
	{
	    "anonymous": false,
	    "inputs": [
		{
		    "indexed": false,
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		},
		{
		    "indexed": false,
		    "internalType": "string",
		    "name": "message",
		    "type": "string"
		}
	    ],
	    "name": "Published",
	    "type": "event"
	},
	{
	    "anonymous": false,
	    "inputs": [
		{
		    "indexed": true,
		    "internalType": "address",
		    "name": "implementation",
		    "type": "address"
		}
	    ],
	    "name": "Upgraded",
	    "type": "event"
	},
	{
	    "inputs": [],
	    "name": "countPublishedDocuments",
	    "outputs": [
		{
		    "internalType": "uint256",
		    "name": "_count",
		    "type": "uint256"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "countTags",
	    "outputs": [
		{
		    "internalType": "uint256",
		    "name": "_count",
		    "type": "uint256"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		},
		{
		    "internalType": "uint256",
		    "name": "entryIndex",
		    "type": "uint256"
		},
		{
		    "internalType": "string",
		    "name": "entry",
		    "type": "string"
		}
	    ],
	    "name": "editDocEntry",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "getAllTags",
	    "outputs": [
		{
		    "internalType": "string[]",
		    "name": "_allTags",
		    "type": "string[]"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "uint256",
		    "name": "index",
		    "type": "uint256"
		}
	    ],
	    "name": "getPublishedDocument",
	    "outputs": [
		{
		    "components": [
			{
			    "internalType": "address",
			    "name": "document",
			    "type": "address"
			},
			{
			    "internalType": "string",
			    "name": "title",
			    "type": "string"
			},
			{
			    "internalType": "string[]",
			    "name": "tags",
			    "type": "string[]"
			},
			{
			    "internalType": "uint256",
			    "name": "timestamp",
			    "type": "uint256"
			}
		    ],
		    "internalType": "struct Genesis_V2.PublishedDocument",
		    "name": "_doc",
		    "type": "tuple"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "string",
		    "name": "_name",
		    "type": "string"
		}
	    ],
	    "name": "initialize",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "",
		    "type": "address"
		}
	    ],
	    "name": "myDocs",
	    "outputs": [
		{
		    "internalType": "string",
		    "name": "key",
		    "type": "string"
		},
		{
		    "internalType": "bool",
		    "name": "isSealed",
		    "type": "bool"
		},
		{
		    "internalType": "uint8",
		    "name": "version",
		    "type": "uint8"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "name",
	    "outputs": [
		{
		    "internalType": "string",
		    "name": "",
		    "type": "string"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "operator",
	    "outputs": [
		{
		    "internalType": "address",
		    "name": "",
		    "type": "address"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "owner",
	    "outputs": [
		{
		    "internalType": "address",
		    "name": "",
		    "type": "address"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "proxiableUUID",
	    "outputs": [
		{
		    "internalType": "bytes32",
		    "name": "",
		    "type": "bytes32"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		},
		{
		    "internalType": "string",
		    "name": "_key",
		    "type": "string"
		}
	    ],
	    "name": "publish",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		},
		{
		    "internalType": "string",
		    "name": "_key",
		    "type": "string"
		},
		{
		    "internalType": "string",
		    "name": "_title",
		    "type": "string"
		},
		{
		    "internalType": "string[]",
		    "name": "_tags",
		    "type": "string[]"
		}
	    ],
	    "name": "publishWithDetails",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_address",
		    "type": "address"
		}
	    ],
	    "name": "readDocument",
	    "outputs": [
		{
		    "components": [
			{
			    "internalType": "string",
			    "name": "entry",
			    "type": "string"
			},
			{
			    "internalType": "uint256",
			    "name": "timestamp",
			    "type": "uint256"
			}
		    ],
		    "internalType": "struct Genesis.Web3DocEntry[]",
		    "name": "_entries",
		    "type": "tuple[]"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "renounceOwnership",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "newOwner",
		    "type": "address"
		}
	    ],
	    "name": "transferOwnership",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "newImplementation",
		    "type": "address"
		}
	    ],
	    "name": "upgradeTo",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "newImplementation",
		    "type": "address"
		},
		{
		    "internalType": "bytes",
		    "name": "data",
		    "type": "bytes"
		}
	    ],
	    "name": "upgradeToAndCall",
	    "outputs": [],
	    "stateMutability": "payable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		},
		{
		    "internalType": "string",
		    "name": "entry",
		    "type": "string"
		},
		{
		    "internalType": "address",
		    "name": "sender",
		    "type": "address"
		},
		{
		    "internalType": "bytes",
		    "name": "_signature",
		    "type": "bytes"
		}
	    ],
	    "name": "verify",
	    "outputs": [
		{
		    "internalType": "address",
		    "name": "",
		    "type": "address"
		},
		{
		    "internalType": "bool",
		    "name": "",
		    "type": "bool"
		}
	    ],
	    "stateMutability": "pure",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "version",
	    "outputs": [
		{
		    "internalType": "uint8",
		    "name": "",
		    "type": "uint8"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		},
		{
		    "internalType": "string",
		    "name": "entry",
		    "type": "string"
		}
	    ],
	    "name": "writeToDoc",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		},
		{
		    "components": [
			{
			    "internalType": "string",
			    "name": "entry",
			    "type": "string"
			},
			{
			    "internalType": "uint256",
			    "name": "timestamp",
			    "type": "uint256"
			}
		    ],
		    "internalType": "struct Genesis.Web3DocEntry",
		    "name": "_web3DocEntry",
		    "type": "tuple"
		},
		{
		    "internalType": "address",
		    "name": "sender",
		    "type": "address"
		},
		{
		    "internalType": "bytes",
		    "name": "_signature",
		    "type": "bytes"
		}
	    ],
	    "name": "writeToDocGasless",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	}
    ],
    genesis_v5: [
	{
	    "anonymous": false,
	    "inputs": [
		{
		    "indexed": false,
		    "internalType": "address",
		    "name": "previousAdmin",
		    "type": "address"
		},
		{
		    "indexed": false,
		    "internalType": "address",
		    "name": "newAdmin",
		    "type": "address"
		}
	    ],
	    "name": "AdminChanged",
	    "type": "event"
	},
	{
	    "anonymous": false,
	    "inputs": [
		{
		    "indexed": true,
		    "internalType": "address",
		    "name": "beacon",
		    "type": "address"
		}
	    ],
	    "name": "BeaconUpgraded",
	    "type": "event"
	},
	{
	    "anonymous": false,
	    "inputs": [
		{
		    "indexed": false,
		    "internalType": "uint8",
		    "name": "version",
		    "type": "uint8"
		}
	    ],
	    "name": "Initialized",
	    "type": "event"
	},
	{
	    "anonymous": false,
	    "inputs": [
		{
		    "indexed": true,
		    "internalType": "address",
		    "name": "previousOwner",
		    "type": "address"
		},
		{
		    "indexed": true,
		    "internalType": "address",
		    "name": "newOwner",
		    "type": "address"
		}
	    ],
	    "name": "OwnershipTransferred",
	    "type": "event"
	},
	{
	    "anonymous": false,
	    "inputs": [
		{
		    "indexed": false,
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		},
		{
		    "indexed": false,
		    "internalType": "string",
		    "name": "message",
		    "type": "string"
		}
	    ],
	    "name": "Published",
	    "type": "event"
	},
	{
	    "anonymous": false,
	    "inputs": [
		{
		    "indexed": true,
		    "internalType": "address",
		    "name": "implementation",
		    "type": "address"
		}
	    ],
	    "name": "Upgraded",
	    "type": "event"
	},
	{
	    "inputs": [],
	    "name": "countPublishedDocuments",
	    "outputs": [
		{
		    "internalType": "uint256",
		    "name": "_count",
		    "type": "uint256"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "countTags",
	    "outputs": [
		{
		    "internalType": "uint256",
		    "name": "_count",
		    "type": "uint256"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		},
		{
		    "internalType": "uint256",
		    "name": "entryIndex",
		    "type": "uint256"
		},
		{
		    "internalType": "string",
		    "name": "entry",
		    "type": "string"
		}
	    ],
	    "name": "editDocEntry",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "getAllTags",
	    "outputs": [
		{
		    "internalType": "string[]",
		    "name": "_allTags",
		    "type": "string[]"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "uint256",
		    "name": "index",
		    "type": "uint256"
		}
	    ],
	    "name": "getPublishedDocument",
	    "outputs": [
		{
		    "components": [
			{
			    "internalType": "address",
			    "name": "document",
			    "type": "address"
			},
			{
			    "internalType": "string",
			    "name": "title",
			    "type": "string"
			},
			{
			    "internalType": "string[]",
			    "name": "tags",
			    "type": "string[]"
			},
			{
			    "internalType": "uint256",
			    "name": "timestamp",
			    "type": "uint256"
			}
		    ],
		    "internalType": "struct Genesis_V2.PublishedDocument",
		    "name": "_doc",
		    "type": "tuple"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "string",
		    "name": "_name",
		    "type": "string"
		}
	    ],
	    "name": "initialize",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "",
		    "type": "address"
		}
	    ],
	    "name": "myDocs",
	    "outputs": [
		{
		    "internalType": "string",
		    "name": "key",
		    "type": "string"
		},
		{
		    "internalType": "bool",
		    "name": "isSealed",
		    "type": "bool"
		},
		{
		    "internalType": "uint8",
		    "name": "version",
		    "type": "uint8"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "name",
	    "outputs": [
		{
		    "internalType": "string",
		    "name": "",
		    "type": "string"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "operator",
	    "outputs": [
		{
		    "internalType": "address",
		    "name": "",
		    "type": "address"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "owner",
	    "outputs": [
		{
		    "internalType": "address",
		    "name": "",
		    "type": "address"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "proxiableUUID",
	    "outputs": [
		{
		    "internalType": "bytes32",
		    "name": "",
		    "type": "bytes32"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		},
		{
		    "internalType": "string",
		    "name": "_key",
		    "type": "string"
		}
	    ],
	    "name": "publish",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		},
		{
		    "internalType": "string",
		    "name": "_key",
		    "type": "string"
		},
		{
		    "internalType": "string",
		    "name": "_title",
		    "type": "string"
		},
		{
		    "internalType": "string[]",
		    "name": "_tags",
		    "type": "string[]"
		}
	    ],
	    "name": "publishWithDetails",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_address",
		    "type": "address"
		}
	    ],
	    "name": "readDocument",
	    "outputs": [
		{
		    "components": [
			{
			    "internalType": "string",
			    "name": "entry",
			    "type": "string"
			},
			{
			    "internalType": "uint256",
			    "name": "timestamp",
			    "type": "uint256"
			}
		    ],
		    "internalType": "struct Genesis.Web3DocEntry[]",
		    "name": "_entries",
		    "type": "tuple[]"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "renounceOwnership",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "newOwner",
		    "type": "address"
		}
	    ],
	    "name": "transferOwnership",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "newImplementation",
		    "type": "address"
		}
	    ],
	    "name": "upgradeTo",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "newImplementation",
		    "type": "address"
		},
		{
		    "internalType": "bytes",
		    "name": "data",
		    "type": "bytes"
		}
	    ],
	    "name": "upgradeToAndCall",
	    "outputs": [],
	    "stateMutability": "payable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		},
		{
		    "internalType": "string",
		    "name": "entry",
		    "type": "string"
		},
		{
		    "internalType": "address",
		    "name": "sender",
		    "type": "address"
		},
		{
		    "internalType": "bytes",
		    "name": "_signature",
		    "type": "bytes"
		}
	    ],
	    "name": "verify",
	    "outputs": [
		{
		    "internalType": "address",
		    "name": "",
		    "type": "address"
		},
		{
		    "internalType": "bool",
		    "name": "",
		    "type": "bool"
		}
	    ],
	    "stateMutability": "pure",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "version",
	    "outputs": [
		{
		    "internalType": "uint8",
		    "name": "",
		    "type": "uint8"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		},
		{
		    "internalType": "string",
		    "name": "entry",
		    "type": "string"
		}
	    ],
	    "name": "writeToDoc",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		},
		{
		    "components": [
			{
			    "internalType": "string",
			    "name": "entry",
			    "type": "string"
			},
			{
			    "internalType": "uint256",
			    "name": "timestamp",
			    "type": "uint256"
			}
		    ],
		    "internalType": "struct Genesis.Web3DocEntry",
		    "name": "_web3DocEntry",
		    "type": "tuple"
		},
		{
		    "internalType": "address",
		    "name": "sender",
		    "type": "address"
		},
		{
		    "internalType": "bytes",
		    "name": "_signature",
		    "type": "bytes"
		}
	    ],
	    "name": "writeToDocGasless",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	}
    ],
    genesis_v6: [
	{
	    "anonymous": false,
	    "inputs": [
		{
		    "indexed": false,
		    "internalType": "address",
		    "name": "previousAdmin",
		    "type": "address"
		},
		{
		    "indexed": false,
		    "internalType": "address",
		    "name": "newAdmin",
		    "type": "address"
		}
	    ],
	    "name": "AdminChanged",
	    "type": "event"
	},
	{
	    "anonymous": false,
	    "inputs": [
		{
		    "indexed": true,
		    "internalType": "address",
		    "name": "beacon",
		    "type": "address"
		}
	    ],
	    "name": "BeaconUpgraded",
	    "type": "event"
	},
	{
	    "anonymous": false,
	    "inputs": [
		{
		    "indexed": false,
		    "internalType": "uint8",
		    "name": "version",
		    "type": "uint8"
		}
	    ],
	    "name": "Initialized",
	    "type": "event"
	},
	{
	    "anonymous": false,
	    "inputs": [
		{
		    "indexed": true,
		    "internalType": "address",
		    "name": "previousOwner",
		    "type": "address"
		},
		{
		    "indexed": true,
		    "internalType": "address",
		    "name": "newOwner",
		    "type": "address"
		}
	    ],
	    "name": "OwnershipTransferred",
	    "type": "event"
	},
	{
	    "anonymous": false,
	    "inputs": [
		{
		    "indexed": false,
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		},
		{
		    "indexed": false,
		    "internalType": "string",
		    "name": "message",
		    "type": "string"
		}
	    ],
	    "name": "Published",
	    "type": "event"
	},
	{
	    "anonymous": false,
	    "inputs": [
		{
		    "indexed": true,
		    "internalType": "address",
		    "name": "implementation",
		    "type": "address"
		}
	    ],
	    "name": "Upgraded",
	    "type": "event"
	},
	{
	    "inputs": [],
	    "name": "countPublishedDocuments",
	    "outputs": [
		{
		    "internalType": "uint256",
		    "name": "_count",
		    "type": "uint256"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "countTags",
	    "outputs": [
		{
		    "internalType": "uint256",
		    "name": "_count",
		    "type": "uint256"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		},
		{
		    "internalType": "uint256",
		    "name": "entryIndex",
		    "type": "uint256"
		},
		{
		    "internalType": "string",
		    "name": "entry",
		    "type": "string"
		}
	    ],
	    "name": "editDocEntry",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "getAllTags",
	    "outputs": [
		{
		    "internalType": "string[]",
		    "name": "_allTags",
		    "type": "string[]"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "uint256",
		    "name": "index",
		    "type": "uint256"
		}
	    ],
	    "name": "getPublishedDocument",
	    "outputs": [
		{
		    "components": [
			{
			    "internalType": "address",
			    "name": "document",
			    "type": "address"
			},
			{
			    "internalType": "string",
			    "name": "title",
			    "type": "string"
			},
			{
			    "internalType": "string[]",
			    "name": "tags",
			    "type": "string[]"
			},
			{
			    "internalType": "uint256",
			    "name": "timestamp",
			    "type": "uint256"
			}
		    ],
		    "internalType": "struct Genesis_V2.PublishedDocument",
		    "name": "_doc",
		    "type": "tuple"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		}
	    ],
	    "name": "getPublishedDocumentPerAddress",
	    "outputs": [
		{
		    "components": [
			{
			    "internalType": "address",
			    "name": "document",
			    "type": "address"
			},
			{
			    "internalType": "string",
			    "name": "title",
			    "type": "string"
			},
			{
			    "internalType": "string[]",
			    "name": "tags",
			    "type": "string[]"
			},
			{
			    "internalType": "uint256",
			    "name": "timestamp",
			    "type": "uint256"
			}
		    ],
		    "internalType": "struct Genesis_V2.PublishedDocument",
		    "name": "_doc",
		    "type": "tuple"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "string",
		    "name": "_name",
		    "type": "string"
		}
	    ],
	    "name": "initialize",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "",
		    "type": "address"
		}
	    ],
	    "name": "myDocs",
	    "outputs": [
		{
		    "internalType": "string",
		    "name": "key",
		    "type": "string"
		},
		{
		    "internalType": "bool",
		    "name": "isSealed",
		    "type": "bool"
		},
		{
		    "internalType": "uint8",
		    "name": "version",
		    "type": "uint8"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "name",
	    "outputs": [
		{
		    "internalType": "string",
		    "name": "",
		    "type": "string"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "operator",
	    "outputs": [
		{
		    "internalType": "address",
		    "name": "",
		    "type": "address"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "owner",
	    "outputs": [
		{
		    "internalType": "address",
		    "name": "",
		    "type": "address"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "proxiableUUID",
	    "outputs": [
		{
		    "internalType": "bytes32",
		    "name": "",
		    "type": "bytes32"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		},
		{
		    "internalType": "string",
		    "name": "_key",
		    "type": "string"
		}
	    ],
	    "name": "publish",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		},
		{
		    "internalType": "string",
		    "name": "_key",
		    "type": "string"
		},
		{
		    "internalType": "string",
		    "name": "_title",
		    "type": "string"
		},
		{
		    "internalType": "string[]",
		    "name": "_tags",
		    "type": "string[]"
		}
	    ],
	    "name": "publishWithDetails",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_address",
		    "type": "address"
		}
	    ],
	    "name": "readDocument",
	    "outputs": [
		{
		    "components": [
			{
			    "internalType": "string",
			    "name": "entry",
			    "type": "string"
			},
			{
			    "internalType": "uint256",
			    "name": "timestamp",
			    "type": "uint256"
			}
		    ],
		    "internalType": "struct Genesis.Web3DocEntry[]",
		    "name": "_entries",
		    "type": "tuple[]"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "renounceOwnership",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "newOwner",
		    "type": "address"
		}
	    ],
	    "name": "transferOwnership",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "newImplementation",
		    "type": "address"
		}
	    ],
	    "name": "upgradeTo",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "newImplementation",
		    "type": "address"
		},
		{
		    "internalType": "bytes",
		    "name": "data",
		    "type": "bytes"
		}
	    ],
	    "name": "upgradeToAndCall",
	    "outputs": [],
	    "stateMutability": "payable",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "version",
	    "outputs": [
		{
		    "internalType": "uint8",
		    "name": "",
		    "type": "uint8"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		},
		{
		    "internalType": "string",
		    "name": "entry",
		    "type": "string"
		}
	    ],
	    "name": "writeToDoc",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	}
    ],
    genesis_v7: [
	{
	    "anonymous": false,
	    "inputs": [
		{
		    "indexed": false,
		    "internalType": "address",
		    "name": "previousAdmin",
		    "type": "address"
		},
		{
		    "indexed": false,
		    "internalType": "address",
		    "name": "newAdmin",
		    "type": "address"
		}
	    ],
	    "name": "AdminChanged",
	    "type": "event"
	},
	{
	    "anonymous": false,
	    "inputs": [
		{
		    "indexed": true,
		    "internalType": "address",
		    "name": "beacon",
		    "type": "address"
		}
	    ],
	    "name": "BeaconUpgraded",
	    "type": "event"
	},
	{
	    "anonymous": false,
	    "inputs": [
		{
		    "indexed": false,
		    "internalType": "uint8",
		    "name": "version",
		    "type": "uint8"
		}
	    ],
	    "name": "Initialized",
	    "type": "event"
	},
	{
	    "anonymous": false,
	    "inputs": [
		{
		    "indexed": true,
		    "internalType": "address",
		    "name": "previousOwner",
		    "type": "address"
		},
		{
		    "indexed": true,
		    "internalType": "address",
		    "name": "newOwner",
		    "type": "address"
		}
	    ],
	    "name": "OwnershipTransferred",
	    "type": "event"
	},
	{
	    "anonymous": false,
	    "inputs": [
		{
		    "indexed": false,
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		},
		{
		    "indexed": false,
		    "internalType": "string",
		    "name": "message",
		    "type": "string"
		}
	    ],
	    "name": "Published",
	    "type": "event"
	},
	{
	    "anonymous": false,
	    "inputs": [
		{
		    "indexed": true,
		    "internalType": "address",
		    "name": "implementation",
		    "type": "address"
		}
	    ],
	    "name": "Upgraded",
	    "type": "event"
	},
	{
	    "inputs": [],
	    "name": "countPublishedDocuments",
	    "outputs": [
		{
		    "internalType": "uint256",
		    "name": "_count",
		    "type": "uint256"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "countTags",
	    "outputs": [
		{
		    "internalType": "uint256",
		    "name": "_count",
		    "type": "uint256"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		},
		{
		    "internalType": "uint256",
		    "name": "entryIndex",
		    "type": "uint256"
		},
		{
		    "internalType": "string",
		    "name": "entry",
		    "type": "string"
		}
	    ],
	    "name": "editDocEntry",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		},
		{
		    "components": [
			{
			    "internalType": "string",
			    "name": "entry",
			    "type": "string"
			},
			{
			    "internalType": "uint256",
			    "name": "timestamp",
			    "type": "uint256"
			}
		    ],
		    "internalType": "struct Genesis.Web3DocEntry",
		    "name": "_web3DocEntry",
		    "type": "tuple"
		},
		{
		    "internalType": "uint256",
		    "name": "entryIndex",
		    "type": "uint256"
		},
		{
		    "internalType": "address",
		    "name": "sender",
		    "type": "address"
		},
		{
		    "internalType": "bytes",
		    "name": "_signature",
		    "type": "bytes"
		}
	    ],
	    "name": "editDocEntryGasless",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "getAllTags",
	    "outputs": [
		{
		    "internalType": "string[]",
		    "name": "_allTags",
		    "type": "string[]"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "uint256",
		    "name": "index",
		    "type": "uint256"
		}
	    ],
	    "name": "getPublishedDocument",
	    "outputs": [
		{
		    "components": [
			{
			    "internalType": "address",
			    "name": "document",
			    "type": "address"
			},
			{
			    "internalType": "string",
			    "name": "title",
			    "type": "string"
			},
			{
			    "internalType": "string[]",
			    "name": "tags",
			    "type": "string[]"
			},
			{
			    "internalType": "uint256",
			    "name": "timestamp",
			    "type": "uint256"
			}
		    ],
		    "internalType": "struct Genesis_V2.PublishedDocument",
		    "name": "_doc",
		    "type": "tuple"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		}
	    ],
	    "name": "getPublishedDocumentPerAddress",
	    "outputs": [
		{
		    "components": [
			{
			    "internalType": "address",
			    "name": "document",
			    "type": "address"
			},
			{
			    "internalType": "string",
			    "name": "title",
			    "type": "string"
			},
			{
			    "internalType": "string[]",
			    "name": "tags",
			    "type": "string[]"
			},
			{
			    "internalType": "uint256",
			    "name": "timestamp",
			    "type": "uint256"
			}
		    ],
		    "internalType": "struct Genesis_V2.PublishedDocument",
		    "name": "_doc",
		    "type": "tuple"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "string",
		    "name": "_name",
		    "type": "string"
		}
	    ],
	    "name": "initialize",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "",
		    "type": "address"
		}
	    ],
	    "name": "myDocs",
	    "outputs": [
		{
		    "internalType": "string",
		    "name": "key",
		    "type": "string"
		},
		{
		    "internalType": "bool",
		    "name": "isSealed",
		    "type": "bool"
		},
		{
		    "internalType": "uint8",
		    "name": "version",
		    "type": "uint8"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "name",
	    "outputs": [
		{
		    "internalType": "string",
		    "name": "",
		    "type": "string"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "operator",
	    "outputs": [
		{
		    "internalType": "address",
		    "name": "",
		    "type": "address"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "owner",
	    "outputs": [
		{
		    "internalType": "address",
		    "name": "",
		    "type": "address"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "proxiableUUID",
	    "outputs": [
		{
		    "internalType": "bytes32",
		    "name": "",
		    "type": "bytes32"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		},
		{
		    "internalType": "string",
		    "name": "_key",
		    "type": "string"
		}
	    ],
	    "name": "publish",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		},
		{
		    "internalType": "string",
		    "name": "_key",
		    "type": "string"
		},
		{
		    "internalType": "string",
		    "name": "_title",
		    "type": "string"
		},
		{
		    "internalType": "string[]",
		    "name": "_tags",
		    "type": "string[]"
		}
	    ],
	    "name": "publishWithDetails",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_address",
		    "type": "address"
		}
	    ],
	    "name": "readDocument",
	    "outputs": [
		{
		    "components": [
			{
			    "internalType": "string",
			    "name": "entry",
			    "type": "string"
			},
			{
			    "internalType": "uint256",
			    "name": "timestamp",
			    "type": "uint256"
			}
		    ],
		    "internalType": "struct Genesis.Web3DocEntry[]",
		    "name": "_entries",
		    "type": "tuple[]"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "renounceOwnership",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "newOwner",
		    "type": "address"
		}
	    ],
	    "name": "transferOwnership",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "newImplementation",
		    "type": "address"
		}
	    ],
	    "name": "upgradeTo",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "newImplementation",
		    "type": "address"
		},
		{
		    "internalType": "bytes",
		    "name": "data",
		    "type": "bytes"
		}
	    ],
	    "name": "upgradeToAndCall",
	    "outputs": [],
	    "stateMutability": "payable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		},
		{
		    "internalType": "string",
		    "name": "entry",
		    "type": "string"
		},
		{
		    "internalType": "address",
		    "name": "sender",
		    "type": "address"
		},
		{
		    "internalType": "bytes",
		    "name": "_signature",
		    "type": "bytes"
		}
	    ],
	    "name": "verify",
	    "outputs": [
		{
		    "internalType": "address",
		    "name": "",
		    "type": "address"
		},
		{
		    "internalType": "bool",
		    "name": "",
		    "type": "bool"
		}
	    ],
	    "stateMutability": "pure",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "version",
	    "outputs": [
		{
		    "internalType": "uint8",
		    "name": "",
		    "type": "uint8"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		},
		{
		    "internalType": "string",
		    "name": "entry",
		    "type": "string"
		}
	    ],
	    "name": "writeToDoc",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_docAddress",
		    "type": "address"
		},
		{
		    "components": [
			{
			    "internalType": "string",
			    "name": "entry",
			    "type": "string"
			},
			{
			    "internalType": "uint256",
			    "name": "timestamp",
			    "type": "uint256"
			}
		    ],
		    "internalType": "struct Genesis.Web3DocEntry",
		    "name": "_web3DocEntry",
		    "type": "tuple"
		},
		{
		    "internalType": "address",
		    "name": "sender",
		    "type": "address"
		},
		{
		    "internalType": "bytes",
		    "name": "_signature",
		    "type": "bytes"
		}
	    ],
	    "name": "writeToDocGasless",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	}
    ],

	genesis_v2_new: [
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "root_document",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "document_id",
					"type": "uint256"
				}
			],
			"name": "addDocumentToWorkspace",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [],
			"stateMutability": "nonpayable",
			"type": "constructor"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "address",
					"name": "previousAdmin",
					"type": "address"
				},
				{
					"indexed": false,
					"internalType": "address",
					"name": "newAdmin",
					"type": "address"
				}
			],
			"name": "AdminChanged",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": true,
					"internalType": "address",
					"name": "beacon",
					"type": "address"
				}
			],
			"name": "BeaconUpgraded",
			"type": "event"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_docAddress",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "entryIndex",
					"type": "uint256"
				},
				{
					"internalType": "string",
					"name": "entry",
					"type": "string"
				}
			],
			"name": "editDocEntry",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "string",
					"name": "_name",
					"type": "string"
				},
				{
					"internalType": "address",
					"name": "_verification_manager",
					"type": "address"
				}
			],
			"name": "initialize",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "address",
					"name": "_sender",
					"type": "address"
				},
				{
					"indexed": false,
					"internalType": "string",
					"name": "message",
					"type": "string"
				}
			],
			"name": "InitWorkspace",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "uint8",
					"name": "version",
					"type": "uint8"
				}
			],
			"name": "Initialized",
			"type": "event"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "sender",
					"type": "address"
				},
				{
					"internalType": "address",
					"name": "root_document",
					"type": "address"
				},
				{
					"internalType": "bytes",
					"name": "_signature",
					"type": "bytes"
				}
			],
			"name": "initWorkspace",
			"outputs": [
				{
					"components": [
						{
							"internalType": "address",
							"name": "owner",
							"type": "address"
						},
						{
							"internalType": "uint256",
							"name": "timestamp",
							"type": "uint256"
						},
						{
							"internalType": "uint256[]",
							"name": "document_ids",
							"type": "uint256[]"
						}
					],
					"internalType": "struct WorkspaceAuthenticatorManager.Workspace",
					"name": "_workspace",
					"type": "tuple"
				}
			],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": true,
					"internalType": "address",
					"name": "previousOwner",
					"type": "address"
				},
				{
					"indexed": true,
					"internalType": "address",
					"name": "newOwner",
					"type": "address"
				}
			],
			"name": "OwnershipTransferred",
			"type": "event"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_docAddress",
					"type": "address"
				},
				{
					"internalType": "string",
					"name": "_key",
					"type": "string"
				}
			],
			"name": "publish",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "address",
					"name": "_docAddress",
					"type": "address"
				},
				{
					"indexed": false,
					"internalType": "string",
					"name": "message",
					"type": "string"
				}
			],
			"name": "Published",
			"type": "event"
		},
		{
			"inputs": [],
			"name": "renounceOwnership",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "newOwner",
					"type": "address"
				}
			],
			"name": "transferOwnership",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": true,
					"internalType": "address",
					"name": "implementation",
					"type": "address"
				}
			],
			"name": "Upgraded",
			"type": "event"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "newImplementation",
					"type": "address"
				}
			],
			"name": "upgradeTo",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "newImplementation",
					"type": "address"
				},
				{
					"internalType": "bytes",
					"name": "data",
					"type": "bytes"
				}
			],
			"name": "upgradeToAndCall",
			"outputs": [],
			"stateMutability": "payable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_docAddress",
					"type": "address"
				},
				{
					"internalType": "string",
					"name": "entry",
					"type": "string"
				}
			],
			"name": "writeToDoc",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "root_document",
					"type": "address"
				}
			],
			"name": "getWorkspace",
			"outputs": [
				{
					"components": [
						{
							"internalType": "address",
							"name": "owner",
							"type": "address"
						},
						{
							"internalType": "uint256",
							"name": "timestamp",
							"type": "uint256"
						},
						{
							"internalType": "uint256[]",
							"name": "document_ids",
							"type": "uint256[]"
						}
					],
					"internalType": "struct WorkspaceAuthenticatorManager.Workspace",
					"name": "_workspace",
					"type": "tuple"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "sender",
					"type": "address"
				},
				{
					"internalType": "address",
					"name": "root_document",
					"type": "address"
				}
			],
			"name": "hasWorkspace",
			"outputs": [
				{
					"internalType": "bool",
					"name": "_isWorkspace",
					"type": "bool"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"name": "myDocs",
			"outputs": [
				{
					"internalType": "string",
					"name": "key",
					"type": "string"
				},
				{
					"internalType": "bool",
					"name": "isSealed",
					"type": "bool"
				},
				{
					"internalType": "uint8",
					"name": "version",
					"type": "uint8"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "name",
			"outputs": [
				{
					"internalType": "string",
					"name": "",
					"type": "string"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "operator",
			"outputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "owner",
			"outputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "proxiableUUID",
			"outputs": [
				{
					"internalType": "bytes32",
					"name": "",
					"type": "bytes32"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_address",
					"type": "address"
				}
			],
			"name": "readDocument",
			"outputs": [
				{
					"components": [
						{
							"internalType": "string",
							"name": "entry",
							"type": "string"
						},
						{
							"internalType": "uint256",
							"name": "timestamp",
							"type": "uint256"
						}
					],
					"internalType": "struct Genesis.Web3DocEntry[]",
					"name": "_entries",
					"type": "tuple[]"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "version",
			"outputs": [
				{
					"internalType": "uint8",
					"name": "",
					"type": "uint8"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "workspace_manager",
			"outputs": [
				{
					"internalType": "contract WorkspaceAuthenticatorManager",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		}
	],	//Has no functions like publishWithDetails -> Title and Tags
	genesis_v3_new: [
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "address",
					"name": "previousAdmin",
					"type": "address"
				},
				{
					"indexed": false,
					"internalType": "address",
					"name": "newAdmin",
					"type": "address"
				}
			],
			"name": "AdminChanged",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": true,
					"internalType": "address",
					"name": "beacon",
					"type": "address"
				}
			],
			"name": "BeaconUpgraded",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "uint8",
					"name": "version",
					"type": "uint8"
				}
			],
			"name": "Initialized",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": true,
					"internalType": "address",
					"name": "previousOwner",
					"type": "address"
				},
				{
					"indexed": true,
					"internalType": "address",
					"name": "newOwner",
					"type": "address"
				}
			],
			"name": "OwnershipTransferred",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "address",
					"name": "_docAddress",
					"type": "address"
				},
				{
					"indexed": false,
					"internalType": "string",
					"name": "message",
					"type": "string"
				}
			],
			"name": "Published",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": true,
					"internalType": "address",
					"name": "implementation",
					"type": "address"
				}
			],
			"name": "Upgraded",
			"type": "event"
		},
		{
			"inputs": [],
			"name": "countPublishedDocuments",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "_count",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "countTags",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "_count",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_docAddress",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "entryIndex",
					"type": "uint256"
				},
				{
					"internalType": "string",
					"name": "entry",
					"type": "string"
				}
			],
			"name": "editDocEntry",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_docAddress",
					"type": "address"
				},
				{
					"components": [
						{
							"internalType": "string",
							"name": "entry",
							"type": "string"
						},
						{
							"internalType": "uint256",
							"name": "timestamp",
							"type": "uint256"
						}
					],
					"internalType": "struct Genesis.Web3DocEntry",
					"name": "_web3DocEntry",
					"type": "tuple"
				},
				{
					"internalType": "uint256",
					"name": "entryIndex",
					"type": "uint256"
				},
				{
					"internalType": "address",
					"name": "sender",
					"type": "address"
				},
				{
					"internalType": "bytes",
					"name": "_signature",
					"type": "bytes"
				}
			],
			"name": "editDocEntryGasless",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "getAllTags",
			"outputs": [
				{
					"internalType": "string[]",
					"name": "_allTags",
					"type": "string[]"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "index",
					"type": "uint256"
				}
			],
			"name": "getPublishedDocument",
			"outputs": [
				{
					"components": [
						{
							"internalType": "address",
							"name": "document",
							"type": "address"
						},
						{
							"internalType": "string",
							"name": "title",
							"type": "string"
						},
						{
							"internalType": "string[]",
							"name": "tags",
							"type": "string[]"
						},
						{
							"internalType": "uint256",
							"name": "timestamp",
							"type": "uint256"
						}
					],
					"internalType": "struct Genesis_V2.PublishedDocument",
					"name": "_doc",
					"type": "tuple"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_docAddress",
					"type": "address"
				}
			],
			"name": "getPublishedDocumentPerAddress",
			"outputs": [
				{
					"components": [
						{
							"internalType": "address",
							"name": "document",
							"type": "address"
						},
						{
							"internalType": "string",
							"name": "title",
							"type": "string"
						},
						{
							"internalType": "string[]",
							"name": "tags",
							"type": "string[]"
						},
						{
							"internalType": "uint256",
							"name": "timestamp",
							"type": "uint256"
						}
					],
					"internalType": "struct Genesis_V2.PublishedDocument",
					"name": "_doc",
					"type": "tuple"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "string",
					"name": "_name",
					"type": "string"
				}
			],
			"name": "initialize",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"name": "myDocs",
			"outputs": [
				{
					"internalType": "string",
					"name": "key",
					"type": "string"
				},
				{
					"internalType": "bool",
					"name": "isSealed",
					"type": "bool"
				},
				{
					"internalType": "uint8",
					"name": "version",
					"type": "uint8"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "name",
			"outputs": [
				{
					"internalType": "string",
					"name": "",
					"type": "string"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "operator",
			"outputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "owner",
			"outputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "proxiableUUID",
			"outputs": [
				{
					"internalType": "bytes32",
					"name": "",
					"type": "bytes32"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_docAddress",
					"type": "address"
				},
				{
					"internalType": "string",
					"name": "_key",
					"type": "string"
				}
			],
			"name": "publish",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_docAddress",
					"type": "address"
				},
				{
					"internalType": "string",
					"name": "_key",
					"type": "string"
				},
				{
					"internalType": "string",
					"name": "_title",
					"type": "string"
				},
				{
					"internalType": "string[]",
					"name": "_tags",
					"type": "string[]"
				}
			],
			"name": "publishWithDetails",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_address",
					"type": "address"
				}
			],
			"name": "readDocument",
			"outputs": [
				{
					"components": [
						{
							"internalType": "string",
							"name": "entry",
							"type": "string"
						},
						{
							"internalType": "uint256",
							"name": "timestamp",
							"type": "uint256"
						}
					],
					"internalType": "struct Genesis.Web3DocEntry[]",
					"name": "_entries",
					"type": "tuple[]"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "renounceOwnership",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "newOwner",
					"type": "address"
				}
			],
			"name": "transferOwnership",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "newImplementation",
					"type": "address"
				}
			],
			"name": "upgradeTo",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "newImplementation",
					"type": "address"
				},
				{
					"internalType": "bytes",
					"name": "data",
					"type": "bytes"
				}
			],
			"name": "upgradeToAndCall",
			"outputs": [],
			"stateMutability": "payable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_docAddress",
					"type": "address"
				},
				{
					"internalType": "string",
					"name": "entry",
					"type": "string"
				},
				{
					"internalType": "address",
					"name": "sender",
					"type": "address"
				},
				{
					"internalType": "bytes",
					"name": "_signature",
					"type": "bytes"
				}
			],
			"name": "verify",
			"outputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				},
				{
					"internalType": "bool",
					"name": "",
					"type": "bool"
				}
			],
			"stateMutability": "pure",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "version",
			"outputs": [
				{
					"internalType": "uint8",
					"name": "",
					"type": "uint8"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_docAddress",
					"type": "address"
				},
				{
					"internalType": "string",
					"name": "entry",
					"type": "string"
				}
			],
			"name": "writeToDoc",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_docAddress",
					"type": "address"
				},
				{
					"components": [
						{
							"internalType": "string",
							"name": "entry",
							"type": "string"
						},
						{
							"internalType": "uint256",
							"name": "timestamp",
							"type": "uint256"
						}
					],
					"internalType": "struct Genesis.Web3DocEntry",
					"name": "_web3DocEntry",
					"type": "tuple"
				},
				{
					"internalType": "address",
					"name": "sender",
					"type": "address"
				},
				{
					"internalType": "bytes",
					"name": "_signature",
					"type": "bytes"
				}
			],
			"name": "writeToDocGasless",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		}
	],

}

export default ABI;