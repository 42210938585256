import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {
    Container,
    GasTxHistoryEntry,
    InfoContainer,
    MainContainer,
    TxHistoryContainer,
    TxHistoryEntry
} from "./GasStation.styles";
import {useDAO} from "../../components/dao/DaoProvider";
import Web3 from "web3";
import {useWeb3React} from "@web3-react/core";
import {Button, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import {
    AdminPanelSettings,
    ArrowBack,
    ArrowDownward,
    ArrowUpward,
    DoubleArrow,
    LocalFireDepartment,
    LocalGasStation
} from "@mui/icons-material";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from "react-responsive-carousel";
import {reduceKeyString} from "../../utils/StringUtils";
import {toast} from "react-toastify";

export function GasStation({back, visitLibrary}){

    const {account} = useWeb3React();
    const {gasStationContract, library} = useDAO();

    const [contractGasBalance, setContractGasBalance] = useState("0");

    const [isAdminRole, setIsAdminRole] = useState(false);
    const [isRefillerRole, setIsRefillerRole] = useState(false);
    const [isGasRequesterRole, setIsGasRequesterRole] = useState(false);

    const utils = new Web3().utils;

    const [roles, setRoles] = useState({
        ADMIN: "",
        REFILLER: "",
        GAS_REQUESTER: ""
    })

    // const [roleGrant, setRoleGrant] = useState("");
    // const [addressGrant, setAddressGrant] = useState("");

    const [roleGrantHistory, setRoleGrantHistory] = useState([]);
    const [gasStationHistory, setGasStationHistory] = useState([]);
    const [allowedGasRequesterAddresses, setAllowedGasRequesterAddresses] = useState(new Map());
    const updateGasRequesterMap = (k, v) => {
        setAllowedGasRequesterAddresses(new Map(allowedGasRequesterAddresses.set(k, v)))
    }


    useEffect(() => {

        (async() => {
            await fetchRoleInfos();
            await fetchGasStationInfos()

            gasStationContract.on('BalanceChanged', (oldBalance, newBalance) => {
                fetchRoleInfos()
                fetchGasStationInfos()
            })
        })();
    }, []);

    useEffect(() => {
        if(roles.ADMIN !== ""){
            (async() => {
                let roleGrantedFilter = gasStationContract.filters.RoleGranted();
                let roleRevokeFilter = gasStationContract.filters.RoleRevoked();

                let roleGrantedEvents = await gasStationContract.queryFilter(roleGrantedFilter);
                let roleRevokedEvents = await gasStationContract.queryFilter(roleRevokeFilter);

                let history = []

                for (let event of roleGrantedEvents) {
                    const role = Object.entries(roles).find(([key, value]) => value === event.args.role)[0];
                    let history_entry = {
                        topic: "GRANTED",
                        address: event.args.account,
                        blockNumber: event.blockNumber,
                        data: {
                            rolename: role,
                            role_hex: event.args.role
                        }
                    }
                    history.push(history_entry);
                }
                for (let event of roleRevokedEvents) {
                    const role = Object.entries(roles).find(([key, value]) => value === event.args.role)[0];
                    let history_entry = {
                        topic: "REVOKED",
                        address: event.args.account,
                        blockNumber: event.blockNumber,
                        data: {
                            rolename: role,
                            role_hex: event.args.role
                        }
                    }
                    history.push(history_entry);
                }
                let sorted_history = history.sort((a, b) => a.blockNumber - b.blockNumber);
                setRoleGrantHistory(sorted_history)
            })();
        }

    }, [roles]);

    useEffect(() => {

        if(roleGrantHistory.length > 0){
            for(let history_entry of roleGrantHistory){
                if(history_entry.data.role_hex === roles.GAS_REQUESTER){
                    updateGasRequesterMap(history_entry.address, (history_entry.topic === "GRANTED"))
                }
            }
        }

    }, [roleGrantHistory]);

    useEffect(() => {
        fetchRoleInfos()
        fetchGasStationInfos()
    }, [account]);

    const fetchGasStationInfos = async() => {
        let balanceChangedHistory = gasStationContract.filters.BalanceChanged();
        let balanceChangedEvent = await gasStationContract.queryFilter(balanceChangedHistory);

        let history = []

        for(let event of balanceChangedEvent){
            let entry = {
                block_number: event.blockNumber,
                topic: event.args.topic,
                sender: event.args.sender,
                balance_old: utils.fromWei(event.args.from.toString(), "ether"),
                balance_new: utils.fromWei(event.args.to.toString(), "ether"),
                balance_delta: utils.fromWei((event.args.to - event.args.from).toString(), "ether")
            }
            history.push(entry)
        }
        setGasStationHistory(history)
    }

    const fetchRoleInfos = async() => {
        let ADMIN_ROLE = await gasStationContract.ADMIN_ROLE();
        setIsAdminRole(await gasStationContract.hasRole(ADMIN_ROLE, account));

        let REFILLER_ROLE = await gasStationContract.REFILLER_ROLE();
        setIsRefillerRole(await gasStationContract.hasRole(REFILLER_ROLE, account));

        let GAS_REQUESTER_ROLE = await gasStationContract.GAS_REQUESTER_ROLE();
        setIsGasRequesterRole(await gasStationContract.hasRole(GAS_REQUESTER_ROLE, account));

        setRoles({...roles, ADMIN: ADMIN_ROLE, REFILLER: REFILLER_ROLE, GAS_REQUESTER: GAS_REQUESTER_ROLE})

        // let balance = await gasStationContract.balance
        let web3 = new Web3(library.provider);
        let balance = await web3.eth.getBalance(gasStationContract.address);
        setContractGasBalance(balance)
    }

    const hasFloatingNumbers = (string) => {
        return string.includes(".")
    }

    return(
        <div
            style={{display: "flex", flexDirection: "column", alignContent: "center", alignItems: "center"}}
        >
            <h1>Gas-Station Details</h1>
            <Button
                startIcon={<ArrowBack/>}
                variant={"text"}
                onClick={() => back()}
            >
                {/*{strings.BUTTON_BACK_DESC}*/}
                Overview
            </Button>

            <MainContainer>

                <TxHistoryContainer style={{gridColumn: "span 2", margin: "auto 6%"}}>
                    <Carousel
                        showArrows
                        showThumbs={false}
                        showIndicators={false}
                        swipeable

                        centerMode
                        centerSlidePercentage={18}
                        selectedItem={gasStationHistory.length}
                    >
                        {
                            [...gasStationHistory].map((entry, idx) => (
                                <GasTxHistoryEntry key={idx}>

                                    <div style={{
                                        position: "relative",
                                        // color: (entry.topic === "REFILLED") ? "white" : "",
                                        // backgroundColor: (entry.topic === "REFILLED") ? "green" : ""
                                    }}>
                                        <p style={{position: "absolute", top: "-2em", color: "grey"}}>{entry.block_number}</p>
                                        <p style={{position: "absolute", top: "2px", fontWeight: "bold"}}>{entry.topic}</p>
                                        <div style={{display: "flex", flexDirection: "row", color: (entry.topic === "REFILLED") ? "green" : "red"}}>
                                            {
                                                (parseFloat(entry.balance_delta) > 0)
                                                    ?
                                                    <ArrowUpward sx={{color: "green"}}/>
                                                    :
                                                    <ArrowDownward/>
                                            }
                                            <p style={{fontSize: "1.2em", fontWeight: "bold", }}>
                                                {
                                                    hasFloatingNumbers(entry.balance_delta)
                                                        ?
                                                        parseFloat(entry.balance_delta).toFixed(8)
                                                        :
                                                        entry.balance_delta
                                                }
                                                <span style={{color: "grey", fontWeight: "normal", paddingLeft: "6px"}}>CTO</span>
                                            </p>
                                        </div>

                                        <p style={{position: "absolute", bottom: "2px"}}>{reduceKeyString(entry.sender, 6, 4)}</p>
                                    </div>
                                    {
                                        (idx !== gasStationHistory.length - 1)
                                            ?
                                            <div>
                                                <DoubleArrow
                                                    sx={{fontSize: "3em", width: "unset", height: "unset"}}/>
                                            </div>
                                            :
                                            <></>
                                    }

                                </GasTxHistoryEntry>
                            ))
                        }
                    </Carousel>
                </TxHistoryContainer>

                <Container>
                    <InfoContainer style={{gridTemplateColumns: "40% auto"}}>
                        <label>Balance:</label>
                        <p>{utils.fromWei(contractGasBalance, "ether")} CTO</p>
                        <label>Address:</label>
                        <p>{gasStationContract.address}</p>
                    </InfoContainer>

                    <BalanceComponent
                        // callback_function={}
                    />

                    <RefillGasComponent
                        callback_function={fetchRoleInfos}
                    />
                    <ReceiveGasComponent
                        callback_function={fetchRoleInfos}
                    />

                    <TxHistoryContainer>
                        <h3>Allowed Gas-Requester</h3>
                        {
                            [...allowedGasRequesterAddresses].map(([key, value]) => (
                                (value) ?
                                    <div
                                        key={key}
                                        style={{
                                            cursor: "pointer",
                                            color: (key === account) ? "green" : "black",
                                            fontWeight: (key === account) ? "bold" : "unset"
                                        }}
                                        onClick={async() => {
                                            await navigator.clipboard.writeText(key);
                                            toast.success("Copied", {autoClose: 500})
                                        }}
                                    >{key}</div> : <></>
                            ))
                        }
                    </TxHistoryContainer>

                </Container>
                <Container>
                    <InfoContainer style={{gridTemplateColumns: "40% auto"}}>
                        <label>
                            Roles (
                                <span style={{color: "green"}}>Granted</span>
                                /
                                <span style={{color: "lightgrey"}}>Not Granted</span>
                            )
                        </label>
                        <div>
                            {
                                (isAdminRole) ? <AdminPanelSettings sx={{color: "green"}} titleAccess={"Admin Granted"}/> : <AdminPanelSettings sx={{color: "lightgrey"}} titleAccess={"Not Granted"}/>
                            }
                            {
                                (isRefillerRole) ? <LocalGasStation sx={{color: "green"}} titleAccess={"Refuel Granted"}/> : <LocalGasStation sx={{color: "lightgrey"}} titleAccess={"Not Granted"}/>
                            }
                            {
                                (isGasRequesterRole) ? <LocalFireDepartment sx={{color: "green"}} titleAccess={"Consume Granted"}/> : <LocalFireDepartment sx={{color: "lightgrey"}} titleAccess={"Not Granted"}/>
                            }
                        </div>
                    </InfoContainer>

                    <InfoContainer style={{gridTemplateColumns: "12% 26% auto max-content"}}>
                        <RoleGrantComponent
                            roles={roles}
                            callback_function={fetchRoleInfos}
                        />
                        <RevokeRoleComponent
                            roles={roles}
                            callback_function={fetchRoleInfos}
                        />
                    </InfoContainer>


                    <TxHistoryContainer>
                        <h3>Role-Granting History</h3>
                        {
                            roleGrantHistory.map((entry, idx) => {
                                return <TxEntry
                                    key={idx}
                                    topic={entry.topic}
                                    blockNumber={entry.blockNumber}
                                    address={entry.address}
                                    data={entry.data.rolename}
                                ></TxEntry>
                            })
                        }
                    </TxHistoryContainer>



                </Container>
            </MainContainer>
        </div>

    )

}

GasStation.propTypes = {
    back: PropTypes.func,
    visitLibrary: PropTypes.func
}

const BalanceComponent = ({callback_function}) => {

    const {library} = useWeb3React();
    const utils = new Web3().utils;

    const [address, setAddress] = useState("")
    const [balance, setBalance] = useState("0");
    const handleFetchBalance = async() => {
        let balance = await library.getBalance(address);
        console.log(balance)
        setBalance(utils.fromWei(balance.toString(), "ether"))
    }

    return(
        <InfoContainer>
            <label>Balance: {balance}</label>
            <TextField
                size="small"
                label={"Address"}
                value={address}
                onChange={(e) => {
                    setAddress(e.target.value)
                }}
            />
            <Button
                variant={"contained"}
                onClick={handleFetchBalance}
                disabled={address === ""}
            >
                <SendIcon/>
            </Button>
        </InfoContainer>
    )
}
BalanceComponent.propTypes = {
    callback_function: PropTypes.func
}

const RefillGasComponent = ({callback_function}) => {

    const {gasStationContract, library} = useDAO();
    const [amount, setAmount] = useState("0")

    const utils = new Web3().utils

    const handleGrantRole = async() => {
        let tx = await gasStationContract.refillStation({value: utils.toWei(amount, "ether")})
        tx.wait(1).then(result => {
            console.log(result)
            setAmount("0")
            callback_function()
        }).catch()
    }

    return(
        <InfoContainer>
            <label style={{gridRow: "span 3"}}>Refill Gas-Station</label>
            <TextField
                size="small"
                label={"Amount"}
                value={amount}
                onChange={(e) => {
                    let value = e.target.value

                    const regex = /^(0(\.\d*)?|[1-9]\d*(\.\d*)?)$/;
                    if(regex.test(value) || value === "") {
                        setAmount(value)
                    }else {
                        e.preventDefault()
                    }
                }}
            />
            <Button
                variant={"contained"}
                onClick={handleGrantRole}
                disabled={amount === ""}
            >
                <SendIcon/>
            </Button>
        </InfoContainer>
    )
}
RefillGasComponent.propTypes = {
    callback_function: PropTypes.func
}

const ReceiveGasComponent = ({callback_function}) => {

    const {gasStationContract, library} = useDAO();
    const [amount, setAmount] = useState("0")

    const utils = new Web3().utils

    useEffect(() => {
    }, []);

    const handleGrantRole = async() => {
        let tx = await gasStationContract.receiveGas(utils.toWei(amount, "ether"))
        tx.wait(1).then(result => {
            console.log(result)
            callback_function()
            setAmount("0")
        }).catch()
    }

    return(
        <InfoContainer>
            <label style={{gridRow: "span 2"}}>Receive Gas</label>

            <TextField
                size="small"
                label={"Amount"}
                value={amount}
                onChange={(e) => {
                    let value = e.target.value

                    const regex = /^(0(\.\d*)?|[1-9]\d*(\.\d*)?)$/;
                    if(regex.test(value) || value === "") {
                        setAmount(value)
                    }else {
                        e.preventDefault()
                    }
                }}
            />
            <div
                style={{display: "grid", gap: "12px", gridTemplateColumns: "auto auto"}}
            >
                <Button
                    variant={"outlined"}
                    onClick={async() => {
                        let balance = await library.getBalance(gasStationContract.address);
                        setAmount(utils.fromWei(balance.toString(), "ether"))
                    }}
                >
                    Max.
                </Button>
                <Button
                    variant={"contained"}
                    onClick={handleGrantRole}
                    disabled={amount === ""}
                >
                    <SendIcon/>
                </Button>
            </div>

        </InfoContainer>
    )
}
ReceiveGasComponent.propTypes = {
    callback_function: PropTypes.func
}

const RoleGrantComponent = ({roles, callback_function}) => {

    const {gasStationContract, library} = useDAO();

    const [selectedRole, setSelectedRole] = useState("");
    const [addressGrant, setAddressGrant] = useState("");

    const getRoleIcon = (rolename) => {
        switch (rolename) {
            case "ADMIN": return <AdminPanelSettings/>;
            case "REFILLER": return <LocalGasStation/>;
            case "GAS_REQUESTER": return <LocalFireDepartment/>;
        }
    }

    const handleGrantRole = async() => {
        const role = Object.entries(roles).find(([key, value]) => value === selectedRole)[0];
        console.log(
            role,
            addressGrant
        )
        let tx = await gasStationContract.grantRole(selectedRole, addressGrant)
        tx.wait(1).then(result => {
            console.log(result)
            callback_function()
        }).catch()
    }

    return(
        <>
        {/*<style={{gridTemplateColumns: "12% 20% auto max-content"}}>*/}
            <label>Grant Role</label>
            <FormControl size="small">
                <InputLabel id="role_select_label">Role</InputLabel>
                <Select
                    labelId="role_select_label"
                    value={selectedRole}
                    label="Role"
                    onChange={(e) => {
                        setSelectedRole(e.target.value)
                    }}
                >
                    {
                        Object.entries(roles).map(([rolename, rolevalue]) => (
                            <MenuItem key={rolename} value={rolevalue}>
                                <div style={{display: "flex", flexDirection: "row"}}>
                                    {getRoleIcon(rolename)}
                                    {rolename}
                                </div>
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>

            <TextField
                size="small"
                label={"Address"}
                onChange={(e) => {
                    setAddressGrant(e.target.value)
                }}
            />
            <Button
                // style={{gridRow: "span 2", height: "100%"}}
                variant={"contained"}
                onClick={handleGrantRole}
                disabled={(selectedRole.length <= 0 || addressGrant.length < 42)}
            >
                <SendIcon/>
            </Button>
        </>
    )

}
RoleGrantComponent.propTypes = {
    roles: PropTypes.array,
    callback_function: PropTypes.func,
}

const RevokeRoleComponent = ({roles, callback_function}) => {

    const {gasStationContract, library} = useDAO();

    const [selectedRole, setSelectedRole] = useState("");
    const [addressGrant, setAddressGrant] = useState("");

    const getRoleIcon = (rolename) => {
        switch (rolename) {
            case "ADMIN": return <AdminPanelSettings/>;
            case "REFILLER": return <LocalGasStation/>;
            case "GAS_REQUESTER": return <LocalFireDepartment/>;
        }
    }

    const handleRevokeRole = async() => {
        const role = Object.entries(roles).find(([key, value]) => value === selectedRole)[0];
        console.log(
            role,
            addressGrant
        )
        let tx = await gasStationContract.revokeRole(selectedRole, addressGrant)
        tx.wait(1).then(result => {
            console.log(result)
            callback_function()
        }).catch()
    }

    return(
        <>
            <label>Revoke Role</label>
            <FormControl size="small">
                <InputLabel id="role_select_label">Role</InputLabel>
                <Select
                    labelId="role_select_label"
                    value={selectedRole}
                    label="Role"
                    onChange={(e) => {
                        setSelectedRole(e.target.value)
                    }}
                >
                    {
                        Object.entries(roles).map(([rolename, rolevalue]) => (
                            <MenuItem key={rolename} value={rolevalue}>
                                <div style={{display: "flex", flexDirection: "row"}}>
                                    {getRoleIcon(rolename)}
                                    {rolename}
                                </div>
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            <TextField
                size="small"
                label={"Address"}
                onChange={(e) => {
                    setAddressGrant(e.target.value)
                }}
            />
            <Button
                variant={"contained"}
                onClick={handleRevokeRole}
                disabled={(selectedRole.length <= 0 || addressGrant.length < 42)}
            >
                <SendIcon/>
            </Button>
        </>
    )

}
RevokeRoleComponent.propTypes = {
    roles: PropTypes.array,
    callback_function: PropTypes.func,
}

const TxEntry = ({blockNumber, topic, address, data}) => {
    return(
        <TxHistoryEntry>
            <p>{blockNumber}</p>
            <p style={{color: (topic === "GRANTED" ? "green" : "red")}}>{topic}</p>
            <p>{address}</p>
            <p>{data}</p>
        </TxHistoryEntry>
    )
}

TxEntry.propTypes = {
    topic: PropTypes.string,
    address: PropTypes.string,
    blockNumber: PropTypes.number,
    data: PropTypes.any
}
