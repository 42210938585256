import {useWorkspace} from "../../../components/workspace/WorkspaceProvider";
import {useDAO} from "../../../components/dao/DaoProvider";
import {useWeb3React} from "@web3-react/core";

/**
 * SignatureUtils used for EIP712-Signatures
 * @return {{generateWorkspaceParams: (function(*): Promise<{types: {InitWorkspace: [{name: string, type: string}], EIP712Domain: [{name: string, type: string},{name: string, type: string},{name: string, type: string},{name: string, type: string}]}, primaryType: string, domain: {chainId, name: string, version: string, verifyingContract}, message: {root}}>)}}
 * @constructor
 */
export function SignatureUtils(){

    const {account, chainId, library} = useWeb3React();
    const {workspace} = useWorkspace();
    const web3docs = useDAO();
    const workspaceContract = web3docs.storageContract;

    // console.log(workspace)

    const getDomain = (chainId, verifyingContract) => {
        return{
            name: "Cryptago",
            version: "1",
            chainId: chainId,
            verifyingContract: verifyingContract,
        }
    }
    const getEIP712Domain = () => {
        return [
            {name: 'name', type: 'string'},
            {name: 'version', type: 'string'},
            {name: 'chainId', type: 'uint256'},
            {name: 'verifyingContract', type: 'address'},
        ]
    }
    const getInitWorkspaceSignature = () => {
        return [
            {name: 'root', type: 'address'}
        ]
    }

    const __getDocumentSignature = () => {
        return [
            {name: 'root_document', type: 'address'},
            {name: 'document_id', type: 'uint'}
        ]
    }

    const __getDocumentContentSignature = () => {
        return [
            {name: 'doc_address', type: 'address'},
            {name: 'content', type: 'string'}
        ]
    }

    // const getMessage = () => {
    //     let root_document = workspace.getWorkspaceDocument(0);
    //     return {
    //         root: root_document.address
    //     }
    // }

    const generateWorkspaceParams = async(root_document_address) => {

        let workspaceManagerAddress = await workspaceContract.workspace_manager();
        let domain = getDomain(chainId, workspaceManagerAddress);
        let eip712domain = getEIP712Domain();
        let initWorkspaceSig = getInitWorkspaceSignature();
        // let message = getMessage();

        // console.log("Domain", domain);
        // console.log("EIP712", eip712domain);
        // console.log("InitWorkspaceSig", initWorkspaceSig)
        // console.log("Message", message)

        const msgParams = {
            domain: domain,
            // message: message,	    // Defining the message signing data content.
            message: {root: root_document_address},
            primaryType: 'InitWorkspace',
            types: {
                // TODO: Clarify if EIP712Domain refers to the domain the contract is hosted on
                EIP712Domain: eip712domain,
                // Permit: Permit,
                InitWorkspace: initWorkspaceSig

            },
        };
        return msgParams
    }

    const generateDocumentSignatureParams = async(root_document_address, document_id) => {
        let workspaceManagerAddress = await workspaceContract.workspace_manager();
        // let workspaceManagerAddress = "0x8d17CA112Cec193E76CcD1e28bAFdcD0B397207F";

        let domain = getDomain(chainId, workspaceManagerAddress);
        let eip712domain = getEIP712Domain();
        let documentSig = __getDocumentSignature();

        // console.log(root_document_address)

        const msgParams = {
            domain: domain,
            // message: message,	    // Defining the message signing data content.
            message: {
                root_document: root_document_address,
                document_id: document_id
            }

            ,
            primaryType: 'AddDocument',
            types: {
                // TODO: Clarify if EIP712Domain refers to the domain the contract is hosted on
                EIP712Domain: eip712domain,
                // Permit: Permit,
                AddDocument: documentSig

            },
        };
        console.log(msgParams)
        return msgParams;

    }

    const generateDocumentContentSignatureParams = async(_docAddress, encrypted_content) => {

        let workspaceManagerAddress = await workspaceContract.workspace_manager();
        let domain = getDomain(chainId, workspaceManagerAddress);
        let eip712domain = getEIP712Domain();
        let documentContentSig = __getDocumentContentSignature();


        const msgParams = {
            domain: domain,
            message: {
                doc_address: _docAddress,
                content: encrypted_content
            },
            primaryType: 'WriteDocumentContent',
            types: {
                EIP712Domain: eip712domain,
                // Permit: Permit,
                WriteDocumentContent: documentContentSig

            },
        };
        return msgParams;
    }

    return{generateWorkspaceParams, generateDocumentSignatureParams, generateDocumentContentSignatureParams}
}