import styled from "styled-components";

export const SelectionDiv = styled.div`
    height: 100%;
    width: 100%;
    //height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
        font-size: 0.8em;

        h1 {
            font-size: 1.5em;
        }
    }
`;

export const ViewSelectionDiv = styled.div`
    display: flex;
    flex-direction: row;
    padding: 20px;
    gap: 20px;
    align-items: center;
    justify-content: center;

    h1 {
        height: 60px;
    }
`;

export const SelectionTile = styled.div`
    position: relative;
    height: max-content;
    width: 200px;
    border: 2px solid black;
    border-radius: 10px;
    padding: 10px;
    color: ${(props) => props.theme.global.base};
    text-align: center;
    cursor: pointer;
    //overflow: hidden;

    &:not([disabled]) {
        & > div {
            display: none;
        }
    }
    
    &[disabled] {
        border: 2px solid lightgrey;
        color: lightgrey !important;

        //pointer-events: none;

        svg {
            fill: lightgrey;
        }

        & > div {
            display: block;
            //opacity: 0.5;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(128, 128, 128, 0.20);

            div {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                transform: rotate(-45deg);

                hr {
                    width: 100%;
                    //transform: rotate(-45deg);
                    border: 1px solid black;
                }

                label {
                    color: black;
                    font-weight: bold;
                    //transform: rotate(-45deg);
                    transform-origin: center; /* Set the rotation origin to the middle */
                }
            }

        }


    }


    img, svg {
        width: 64px !important;
    }

    &:hover {
        background-color: ${(props) => props.theme.global.base + "CC"};
        color: white;
    }

    @media screen and (max-width: 768px) {
        width: 100px;
        img, svg {
            width: 40px !important;
        }

    }

    .info-bubble {
        position: absolute;
        top: -20px;
        right: -10px;
        //border: 1px solid red;
        border-radius: 50%;
        background-color: darkred;
        color: white;
        font-weight: bold;
        padding: 8px;
        font-size: 0.8em;
    }

`;