import React, {useEffect, useState} from 'react';

import PropTypes from "prop-types";

import {useWeb3React} from "@web3-react/core";
import Web3 from "web3";


// ---Web3Lib Components ---
import {useDAO} from "../../../components/dao/DaoProvider";
import {useWorkspace} from "../../../components/workspace/WorkspaceProvider";
import {useDocumentView} from "./document/DocumentViewProvider";


//MUI Components
import {Button, TextField} from "@mui/material";

// ---ICONS---
import EditIcon from '@mui/icons-material/Edit';
import DescriptionIcon from "@mui/icons-material/Description";


//Custom UI
import {WorkspaceMenu} from "./menu/Menu";
import {RootComponent} from "./Workspace.styles";

import {DocumentHeading} from "./document/Document.styles";
import {Document} from "./document/Document";
import {Avatar} from "../components/Avatar";
import UnlockModalComponent from "../../../components/diary/components/modals/UnlockDocumentModal";
import PublishDocumentModal from "../../../components/diary/components/modals/PublishDocumentModal";

// --- API ---
import Relayer from "../../../components/relay_api/RelayAPI";

//Utils
import {SignatureUtils} from "./SignatureUtils";
import {toast} from "react-toastify";
import {usePrevious} from "@react-pdf-viewer/core";
import {NetworkDotAnimation, NetworkDotDiv} from "../../../components/common/uiUtils/Utils";

function WorkspaceDesktop(props) {

    const {account, chainId, library} = useWeb3React();
    const prevAccount = usePrevious(account);

    // const {strings} = useLanguageService()

    // const theme = useTheme();

    let web3docs = useDAO();
    let workspaceContract = web3docs.storageContract;
    console.log(workspaceContract)

    const {workspace, unlocked, resetWorkspace, utils: workspaceUtils, currentDocument: workspaceCurrentDocument} = useWorkspace();
    const [workspaceExist, setWorkspaceExist] = useState(false);
    const [loadingWorkspace, setLoadingWorkspace] = useState(false);


    const [showModal, setShowModal] = useState(false)
    const [isMenuClosed, setIsMenuClosed] = useState(false);


    const [documentTitle, setDocumentTitle] = useState("");

    const [currentDocument, setCurrentDocument] = useState(null);
    const prevCurrentDocument = usePrevious(currentDocument);

    const [documents, setDocuments] = useState(new Map());

    const [rootDocument, setRootDocument] = useState();

    const utils = SignatureUtils();


    //DocumentViewProvider
    const {pages, resetDocumentView, getDocumentContent} = useDocumentView();


    //Sync-Button
    const [syncButtonText, setSyncButtonText] = useState("Save");
    const [syncButtonIcon, setSyncButtonIcon] = useState(<NetworkDotDiv />);

    const [showPublishModal, setShowPublishModal] = useState(false);

    //If Workspace is LOCKED
    // useEffect(() => { if(workspace.isLocked()){ setShowModal(true) }}, [workspace.isLocked()]);

    //If Workspace is UNLOCKED
    //FETCH ALL DOCUMENTS OF WORKSPACE
    useEffect(() => {

        if (workspace.isUnlocked()) {

            setLoadingWorkspace(true);

            (async () => {

                //FIXME: Leads to multiple Toasts. Need to Unsubscribe when leaving
                //LISTEN ON EVENT
                workspaceContract.on('InitWorkspace', (_sender, message) => {
                    console.log("EVENT", _sender, message)
                    if (_sender === account) {
                        setLoadingWorkspace(false);
                        toast.success(message)
                        setWorkspaceExist(true);
                    }
                });

                let root_document = workspace.getWorkspaceDocument(0);
                setRootDocument(root_document.address);

                // console.log("Root-Document", root_document)
                let hasWorkspace = await workspaceContract.hasWorkspace(account, root_document.address);
                setWorkspaceExist(hasWorkspace);

                console.log("Workspace exist?", hasWorkspace)

                // //FIXME: REMOVE ME
                // let ws = await workspaceContract.getWorkspace(root_document.address);
                // console.log("GetWorkspace(root_document)", ws)


                if (hasWorkspace) {
                    setLoadingWorkspace(false);
                } else {
                    // resetWorkspaceDocuments()
                    initWorkspace()
                }

            })();
        }
        // else{
        // resetWorkspace();
        // }
    }, [unlocked]);

    useEffect(() => {

        if (account !== prevAccount)
            lockWorkspace();

    }, [account])

    useEffect(() => {
        console.log("CurrentDocument", currentDocument)
    }, [currentDocument]);

    const resetWorkspaceDocuments = () => {
        setDocuments(new Map())
        setRootDocument(null);
        setCurrentDocument(null);
        setDocumentTitle("");
        resetWorkspace()

        console.warn("RESETTING WORKSPACE")
    }

    /**
     * Resetting Local-Workspace aka local-storage cryptographical Wallet as Workspace
     */
    const lockWorkspace = () => {
        // console.log("LOOOOOOOOOCK WORKSPACE")
        setDocuments(new Map());
        setRootDocument(null);
        setCurrentDocument(null);
        setDocumentTitle("");
        resetWorkspace();
        resetDocumentView();
    }

    const initWorkspace = () => {

        setLoadingWorkspace(true);

        (async () => {

            let root_document = workspace.getWorkspaceDocument(0);
            let workspaceSignature = await utils.generateWorkspaceParams(root_document.address);
            library.provider.request({
                method: 'eth_signTypedData_v4',	//MetaMask Function
                params: [account, JSON.stringify(workspaceSignature)]
            }).then(async (signature) => {
                let response = await Relayer.relayInitWorkspace({
                    sender: account,
                    root_document: root_document.address,
                    signature: signature
                })
                let result = await response.json();
                if (response.status === 200) {
                    console.log(result)
                    // toast.success(result.transactionHash)
                } else {
                    // console.log(response)
                    // console.log(result)
                    if (result.error)
                        toast.error(result.reason)
                    else
                        toast.error(result.reason)
                    setLoadingWorkspace(false)
                }
                // setLoadingWorkspace(false)
            }).catch(error => {
                // console.log(error)
                toast.error(error.message)
                setLoadingWorkspace(false);
            })
        })();
    }

    const updateDocumentMap = (k, v) => {
        setDocuments(new Map(documents.set(k, v)))
    }
    const removeDocument = (k, v) => {
        console.log("Remove document", k)
        documents.delete(k);
        setDocuments(new Map(documents))
        // let map = new Map(documents)
    }
    const addDocumentsToMenu = async (documents = []) => {
        console.log("ADD TO MENUE")
        for (let document of documents) {
            await updateDocument(document, "done")
        }
    }
    const updateDocument = async (document, status) => {

        let doc_details = await workspaceContract.myDocs(document.address);
        console.log("Add Document to view", document, doc_details)

        let details = {
            title: null,
            key: "",
            sealed: doc_details.isSealed
        }

        if(doc_details.isSealed){
            let published_doc_details = await workspaceContract.getPublishedDocumentPerAddress(document.address);
            console.log("Pub Details", published_doc_details)
            details.title = published_doc_details.title
        }

        let doc = {
            id: document.fingerprint,
            index: document.index,
            address: document.address,

            details: details,
            icon: <DescriptionIcon/>,
            status: status

            // title: document.address,
            // id: document.fingerprint,
            // icon: <DescriptionIcon/>,


            // sealed: docObj.isSealed,
            // publicKey: publishedDocument,
            // privateKey: docObj.key,
        }
        // console.log(doc)
        updateDocumentMap(document.address, doc)

        // let doc = {
        //     title: document.address,
        //     id: document.fingerprint,
        //     icon: <DescriptionIcon/>
        // }
        // setDocuments([...documents, doc]);
    }

    const switchDocument = async (document) => {
        console.log("Switch Document", document)
        // if(currentDocument === null){
        setCurrentDocument(document)
        setDocumentTitle(document.id)
        workspaceUtils.setCurrentDocument(document.index);
        resetDocumentView()
    }

    //FIXME: Move to WorkspaceProvider
    const generateDocument = () => {
        (async () => {
            // let randomSeed = Math.floor(Math.random() * 10000) + 1;
            // console.log("Random-Seed", randomSeed)

            let web3 = new Web3();

            let random_seed = web3.utils.randomHex(1); //16777215 max Documents
            // console.log("Hex Random Seed", random_seed)

            let space = await workspaceContract.getWorkspace(rootDocument);

            let new_document = null;

            while (new_document == null) {

                // console.log(random_seed, parseInt(random_seed, 16), space.document_ids.map(seeds => seeds.toNumber()).sort((a,b) => a-b))

                let root_document = workspace.getWorkspaceRootDocument()

                if (!space.document_ids.map(seeds => seeds._hex).includes(random_seed)) {
                    let seed = parseInt(random_seed, 16);
                    new_document = workspace.getWorkspaceDocument(seed);

                    // console.log(new_document)

                    let documentSignature = await utils.generateDocumentSignatureParams(root_document.address, seed);
                    // console.log(documentSignature)

                    //Some check
                    if (documentSignature.message.root_document !== root_document.address) throw Error("Root-Document-Address error in checks")

                    await updateDocument(new_document, "init");

                    library.provider.request({
                        method: 'eth_signTypedData_v4',	//MetaMask Function
                        params: [account, JSON.stringify(documentSignature)]
                    }).then(async (signature) => {

                        console.log(signature)
                        console.log(documentSignature.message.root_document)
                        console.log(documentSignature.message.document_id)

                        let response = await Relayer.relayAddDocument({
                            sender: account,
                            root_document: documentSignature.message.root_document,
                            document_id: documentSignature.message.document_id,
                            signature: signature
                        })
                        console.log("RES", response)

                        let result = await response.json();
                        if (response.status === 200) {
                            console.log(result)
                            // toast.success(result.transactionHash)
                            document.getElementById(new_document.address).firstChild.classList.replace("document_init", "document_done")
                            toast.success("New document created: " + result.hash)
                        } else {
                            if (result.error) {
                                toast.error(result.error.reason)
                                removeDocument(new_document.address)
                            } else {
                                toast.error(result.reason)
                                removeDocument(new_document.address)
                            }
                        }
                    }).catch(error => {
                        console.log(error)
                        toast.error(error.message)
                        removeDocument(new_document.address)
                        // setLoadingWorkspace(false);
                    })


                    /*
                    let tx = await workspaceContract.addDocumentToWorkspace(rootDocument, seed);
                    // console.log("RESULT", tx);
                    updateDocument(new_document, "init");

                    tx.wait(1).then((result) => {
                        console.log("Confirmed", result)
                        // updateDocument(new_document, "done");
                        document.getElementById(new_document.address).firstChild.classList.replace("document_init", "document_done")
                        toast.success("New document created")
                    })
                    */


                } else {
                    console.log("DOCUMENT-ID ALEADY EXIST!!!!", random_seed)
                    random_seed = web3.utils.randomHex(1);
                }


            }

        })();
    }

    const saveDocument = async () => {
        setSyncButtonIcon(
            <NetworkDotDiv>
                <NetworkDotAnimation/>
            </NetworkDotDiv>
        )
        setSyncButtonText("Syncing")

        let content = getDocumentContent();
        let encryptedContent = await workspace.encryptMessage(content);
        console.log("CONTENT", encryptedContent)
        console.log("Size", new TextEncoder().encode(encryptedContent).length)

        console.log(utils)
        let documentContentSignature = await utils.generateDocumentContentSignatureParams(currentDocument.address, encryptedContent);
        console.log(documentContentSignature)
        if (documentContentSignature.message.doc_address !== currentDocument.address) throw Error("Root-Document-Address error in checks")

        library.provider.request({
            method: 'eth_signTypedData_v4',	//MetaMask Function
            params: [account, JSON.stringify(documentContentSignature)]
        }).then(async (signature) => {

            console.log("DocumentContentSignature", signature)

            let response = await Relayer.relayWriteDocumentContent({
                sender: account,
                doc_address: documentContentSignature.message.doc_address,
                content: documentContentSignature.message.content,
                signature: signature
            })
            console.log("RES", response)
            let result = await response.json();
            if (response.status === 200) {
                console.log(result)
                toast.success("Document saved: " + result.result.hash)
                setSyncButtonIcon(<NetworkDotDiv/>)
                setSyncButtonText("Save")
            } else {
                console.log(result)
                if (result.error) {
                    toast.error(result.error.reason)
                } else {
                    toast.error(result.reason)
                }
            }
        }).catch(error => {
            console.log(error)
            toast.error(error.message)
        })

        // workspaceContract.writeToDoc(currentDocument.address, encryptedContent, 256)
        //     .then((tx) => {
        //
        //         tx.wait(1).then(minedTx => {
        //             setSyncButtonIcon(
        //                 <NetworkDotDiv style={{backgroundColor: "green"}}>
        //                 </NetworkDotDiv>
        //             )
        //             setSyncButtonText("Saved!")
        //             setTimeout(() => {
        //                 setSyncButtonIcon(<NetworkDotDiv/>)
        //                 setSyncButtonText("Save")
        //             }, 2000)
        //         })
        //
        //     }).catch(error => {
        //         if(error.code === "ACTION_REJECTED"){
        //             setSyncButtonIcon(<NetworkDotDiv style={{backgroundColor: "red"}} />)
        //             setSyncButtonText("Rejected")
        //             setTimeout(() => {
        //                 setSyncButtonIcon(<NetworkDotDiv/>)
        //                 setSyncButtonText("Save")
        //             }, 2000)
        //         }
        //
        //     })
    }

    const publishWithDetails = async (detailObj) => {
        console.log("Publishing with details", detailObj)
        console.log("Publish Document", workspaceCurrentDocument)

        if (Object.hasOwn(detailObj, "title") && Object.hasOwn(detailObj, "tags")) {
            console.log("Details provided...", detailObj)

            console.log(web3docs)

            web3docs.storageContract.publishWithDetails(
                workspaceCurrentDocument.address,
                workspaceCurrentDocument.privateKey,
                detailObj.title,
                detailObj.tags
            ).then((tx) => {
                console.log("Publishing document with Title and Tags")
                tx.wait().then(result => {
                    console.log("Result", result)
                    if (result.status === 1) {

                        web3docs.storageContract.myDocs(workspaceCurrentDocument.address).then(document => {
                            console.log("Web3Document", document)
                            console.log("WorkspaceCurrentDocument", workspaceCurrentDocument)
                            if(document.isSealed){
                                //TODO: ADD SEALED
                                toast.success("You successfully published your Document")
                                updateDocument(workspaceCurrentDocument, "done")
                            }
                        })
                    }
                })
            })

            // diaryContract.publishWithDetails(diary.owner.address, diary.owner.privateKey, detailObj.title, detailObj.tags).then((tx) => {
            //
            //     console.log("Publishing document with Title and Tags")
            //
            //     tx.wait().then(result => {
            //         console.log("Result", result)
            //         if (result.status === 1) {
            //             diaryContract.myDocs(diary.owner.address).then(diary => {
            //                 console.log(diary)
            //                 if (diary.isSealed) setIsSealed(diary.isSealed)
            //             })
            //         }
            //         toast.success("You successfully published your Diary(" + diary.owner.address + ")")
            //     })
            //
            // }).catch((error) => {
            //     toast.error(error.reason, {autoClose: 3000, transition: Flip})
            // })
        } else {
            throw new Error("Details not provided..." + JSON.stringify({title: "<string>", tags: ["#tag1", "#tag2"]}))
        }

    }

    const saveDocumentIPFS = async () => {

        setSyncButtonIcon(
            <NetworkDotDiv>
                <NetworkDotAnimation/>
            </NetworkDotDiv>
        )
        setSyncButtonText("Syncing")

        // console.log("Save Document", currentDocument)
        // console.log(pages)
        // console.log(utils)
        // console.log(workspace)

        let content = ""
        console.warn("Using IPFS u you need to decomment code under this msg ")
        // let content = pages.map((page) => {
        //     return page.page_ref.value
        // }).join("")

        console.log(content)
        // console.log(workspace)

        console.log(currentDocument.address, workspaceCurrentDocument)
        let encryptedContent = await workspace.encryptMessage(content);
        console.log("Encrypted Content", encryptedContent)

        // missing useIPFS()
        // const response = await ipfs.saveDocument(
        //     account,
        //     workspace.getWorkspaceRootDocument().address,
        //     {
        //         title: documentTitle,
        //         tags: [],
        //         id: currentDocument.index,
        //         content: encryptedContent
        //     }
        // )
        // if (response.status === 200) {
        //     // toast.success(await response.json());
        //     setSyncButtonIcon(
        //         <NetworkDotDiv style={{backgroundColor: "green"}}>
        //         </NetworkDotDiv>
        //     )
        //     setSyncButtonText("Saved!")
        //     setTimeout(() => {
        //         setSyncButtonIcon(
        //             <NetworkDotDiv>
        //             </NetworkDotDiv>
        //         )
        //         setSyncButtonText("Save")
        //     }, 2000)
        // } else {
        //     toast.error("Error on saving Document", {position: "bottom-right", autoClose: 1000})
        //     setSyncButtonIcon(
        //         <NetworkDotDiv style={{backgroundColor: "red"}}>
        //         </NetworkDotDiv>
        //     )
        // }
    }

    return (
        <RootComponent>
            <WorkspaceMenu

                back={props.back}

                isClosed={isMenuClosed}
                setIsClosed={setIsMenuClosed}
                // handleUnlock={handleUnlock}
                initWorkspace={initWorkspace}
                loading={loadingWorkspace}
                workspaceExist={workspaceExist}

                lockWorkspace={lockWorkspace} //"Logout" from Cryptic LOCAL-Workspace

                documents={documents}
                addDocumentsToMenu={addDocumentsToMenu}
                generateDocument={generateDocument}

                setCurrentDocument={switchDocument}
                currentDocument={currentDocument}
            >
            </WorkspaceMenu>

            <div
                style={{width: "100%", position: "relative"}}
                // onClick={handleUnlock}
            >
                <DocumentHeader
                    name={"header"}
                    title={documentTitle}
                    setTitle={setDocumentTitle}
                    showAvatar={isMenuClosed}
                    openMenu={() => {
                        setIsMenuClosed(!isMenuClosed)
                    }}
                >
                    <label>{currentDocument?.address}</label>
                    {
                        (currentDocument)
                            ?
                            <>
                                <Button
                                    style={{marginLeft: "auto", marginRight: 6}}
                                    variant={"outlined"}
                                    onClick={saveDocument}
                                    startIcon={syncButtonIcon}
                                >
                                    {syncButtonText}
                                </Button>
                                <Button
                                    variant={"contained"}
                                    style={{marginRight: 6}}
                                    onClick={() => {setShowPublishModal(true)}}
                                >
                                    Publish
                                </Button>
                            </> : <></>
                    }

                </DocumentHeader>
                {
                    unlocked
                        ?
                        // <DocumentViewProvider>
                        <>
                            <Document
                                setTitle={setDocumentTitle}
                                currentDocument={currentDocument}
                                handleSaveDocument={saveDocument}
                                // handlePublishDocument={publishDocument}
                            />
                            <PublishDocumentModal
                                documentTitle={documentTitle}
                                setDocumentTitle={setDocumentTitle}
                                showModal={showPublishModal}
                                setShowModal={setShowPublishModal}
                                publishWithDetails={publishWithDetails}
                            />
                        </>
                        // </DocumentViewProvider>
                        :
                        <UnlockModalComponent
                            documentOverlay
                            showModal={true}
                            setShowModal={() => {
                            }}
                        />
                }
            </div>


            {/*<Document*/}
            {/*    onClick={handleUnlock}*/}

            {/*    title={documentTitle}*/}
            {/*    setTitle={setDocumentTitle}*/}
            {/*    currentDocument={currentDocument}*/}

            {/*    isMenuClosed={isMenuClosed}*/}
            {/*    openMenu={() => {setIsMenuClosed(!isMenuClosed)}}*/}
            {/*>*/}
            {/*</Document>*/}

        </RootComponent>
    )

}

WorkspaceDesktop.propTypes = {
    back: PropTypes.func
}


/**
 * Header of the Document for Meta-Infos about the Document
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
function DocumentHeader(props) {

    const inputRef = React.useRef();

    const FONT_SIZE = 20;
    const DEFAULT_INPUT_WIDTH = 200;

    const [textValue, setTextValue] = useState("")
    const [inputWidth, setInputWidth] = useState(DEFAULT_INPUT_WIDTH);

    const {children} = props;

    useEffect(() => {
        if (textValue.length * FONT_SIZE / 2.21 > DEFAULT_INPUT_WIDTH) {
            setInputWidth((textValue.length + 1) * FONT_SIZE / 2.1)
        } else {
            setInputWidth(DEFAULT_INPUT_WIDTH)
        }
    }, [textValue])

    return (
        <DocumentHeading>

            {
                // props.showAvatar ?
                <div
                    className={"avatar"}
                    style={{
                        visibility: (props.showAvatar) ? "visible" : "hidden",
                        opacity: (props.showAvatar) ? "1" : "0",
                        width: (props.showAvatar) ? "var(--avatar-width)" : "0",
                        minWidth: (props.showAvatar) ? "var(--avatar-width)" : "0"
                    }}
                    onClick={() => {
                        props.openMenu()
                    }}
                >
                    <Avatar/>
                </div>
                // :
                // <></>
            }

            <div className={"textfield-container"}>
                <TextField
                    inputRef={inputRef}
                    InputProps={{
                        disableUnderline: true,
                        style: {
                            width: inputWidth + "px",
                            fontSize: FONT_SIZE + "px"
                        }
                    }}
                    margin="dense"
                    label="Document title"
                    variant="standard"
                    value={props.title}
                    // fullWidth={true}
                    onChange={(event) => {
                        props.setTitle(event.target.value)
                        setTextValue(event.target.value)
                    }}
                    onClick={(event) => {
                        event.preventDefault();
                    }}
                />
            </div>
            <div
                className={"edit-btn"}
                onClick={() => {
                    inputRef.current.focus()
                }}>
                <EditIcon sx={{color: 'action.active', m: 2}}/>
            </div>
            {children}
        </DocumentHeading>
    )
}

DocumentHeader.propTypes = {
    title: PropTypes.string,
    setTitle: PropTypes.func,
    showAvatar: PropTypes.bool,
    openMenu: PropTypes.func,
    children: PropTypes.node
}

const Publisher = () => {

    const [showPublishModal, setShowPublishModal] = useState(false);

    const publishWithDetails = (detailObj) => {
        console.log("Publishing with details", detailObj)

        // if (Object.hasOwn(detailObj, "title") && Object.hasOwn(detailObj, "tags")) {
        //     console.log("Details provided...", detailObj)
        //     diaryContract.publishWithDetails(diary.owner.address, diary.owner.privateKey, detailObj.title, detailObj.tags).then((tx) => {
        //
        //         console.log("Publishing document with Title and Tags")
        //
        //         tx.wait().then(result => {
        //             console.log("Result", result)
        //             if (result.status === 1) {
        //                 diaryContract.myDocs(diary.owner.address).then(diary => {
        //                     console.log(diary)
        //                     if (diary.isSealed) setIsSealed(diary.isSealed)
        //                 })
        //             }
        //             toast.success("You successfully published your Diary(" + diary.owner.address + ")")
        //         })
        //
        //     }).catch((error) => {
        //         toast.error(error.reason, {autoClose: 3000, transition: Flip})
        //     })
        // } else {
        //     throw new Error("Details not provided..." + JSON.stringify({title: "<string>", tags: ["#tag1", "#tag2"]}))
        // }


    }

    return(
        <>


        </>
    )
}

export default WorkspaceDesktop;