import styled from "styled-components";

export const DocumentContentFrame = styled.div`

    position: relative;
    height: calc(100% - var(--document-header-height));
    overflow-y: auto;

    background-color: #d3d3d330;

    //visibility: visible;
    //
    //@media print {
    //  body * {
    //    visibility: hidden;
    //  }
    //
    //  #content_frame, #content_frame * {
    //    visibility: visible;
    //  }
    //
    //  #content_frame {
    //    position: absolute;
    //    left: 0;
    //    top: 0;
    //  }
    //}

    //@media screen {
    //  .print {
    //    display: none;
    //  }
    //}

`;
export const DocumentHeading = styled.header`
    width: 100%;

    height: var(--document-header-height);
    display: flex;

    align-items: center;
    background-color: lightgrey;

    .edit-btn {
        cursor: pointer;
        //border-radius: 50%;

        :hover {
            //background-color: lightgrey;
        }
    }

    .textfield-container input {
        padding-bottom: 20px;
        height: 50px;
    }

    .textfield-container label {
        height: 100%;
        top: 4px;
        font-size: 1.6rem;
    }

    .textfield-container label[data-shrink="true"] {
        top: 10px;
        font-size: 1rem;
    }

    .avatar {
        //position: absolute;
        //top: 10px;
        //top: calc( var(--document-header-height) + 5%);
        //left: 10px;

        margin: 0px 20px;

        --avatar-width: 50px;
        --avatar-height: 50px;

        width: var(--avatar-width);
        height: var(--avatar-height);

        cursor: pointer;
        border-radius: 50%;
        border: 2px solid white;
        overflow: hidden;
        opacity: 0;

        transition: visibility 0s, opacity 0.5s linear;

        :hover {
            border: 2px solid grey;
        }

        img {
            width: var(--avatar-width);
            height: var(--avatar-height);
        }

    }

`;

export const RichTextContainer = styled.div`
    
    margin: 0 auto;
    margin-bottom: 100px;
    padding-top: 40px;
    max-width: 740px;
    padding-left: 20px;
    padding-right: 20px;
    //background-color: wheat;
    
    //background-color: white;

    &.ql-placeholder {
        
        display: flex;
        justify-content: center;
        align-items: center;
        
        height: -webkit-fill-available;

        > div {
            aspect-ratio: 1/1.41421;
            height: 80%;
            color: grey;
            border: 4px solid lightgrey;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

    }
    
    .ql-container{
        background-color: white;

        min-height: 297mm;
        
        font-size: 16px;
        //box-shadow: rgba(6, 24, 44, 0.1) 0px 0px 0px 1px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
    }
    
    .ql-editor{
        min-height: 297mm;
        //padding: 0;
    }

    .ql-tooltip {
        position: fixed;
        min-width: 300px;
        border-radius: 12px !important;
    }

    .ql-toolbar {
        display: flex;
        flex-direction: column;

        .ql-formats {
            margin: 8px !important;

            .ql-color-picker line:first-child, .ql-background {
                opacity: 0.8;
                stroke-width: 4;
                stroke-linecap: square;
            }

            .ql-size, .ql-font, .ql-header {
                border: 1px solid white;
            }
        }

        .ql-picker-label {
            display: flex;
            align-items: center;
        }

    }
    .ql-picker.ql-font {
        .ql-picker-item {
            font-size: 0;

            &:before {
                content: attr(data-value) !important;
                font-size: 14px;
            }
        }
    }

    .ql-picker.ql-font {
        .ql-active {
            &:before {
                content: attr(data-value) !important;
                font-size: 14px;
            }
        }
    }

    //

    .ql-picker.ql-font .ql-picker-label[data-value="Roboto"]::before,
    .ql-picker.ql-font .ql-picker-item[data-value="Roboto"]::before {
        font-family: "Roboto", cursive;;
        content: "Roboto" !important;
    }
    .ql-font-Roboto {
        font-family: "Roboto";
    }

    .ql-font-Monospace {
        font-family: "Monospace";
    }

    .ql-font-Arial {
        font-family: "Arial, Helvetica, sans-serif";
    }

    .ql-font-Consolas {
        font-family: "Consolas";
    }
    
`;

export const RichTextContainer2 = styled.div`

    //position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 10mm;

    padding-top: 40px;
    padding-bottom: 40px;

    &.ql-placeholder {
        height: -webkit-fill-available;

        > div {
            aspect-ratio: 1/1.41421;
            height: 80%;
            //width: 50%;
            //margin: 20%;
            color: grey;
            border: 4px solid lightgrey;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

    }

    .ql-active {
        background: radial-gradient(#444 10%, #808080) !important;
        border-radius: 6px;
    }

    .ql-container {
        min-width: 210mm;
        width: 210mm;
        min-height: 297mm;
        height: 297mm;
        background-color: white;
        border-radius: 4px;

        box-shadow: rgba(6, 24, 44, 0.1) 0px 0px 0px 1px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;

        font-size: 18px;
    }

    //.showGrid{
    //  background-image: repeating-linear-gradient(0deg, transparent, transparent 18px, rgba(128, 128, 128, 0.5) 1mm, rgba(128, 128, 128, 0.5) 5mm), 
    //  repeating-linear-gradient(90deg, transparent, transparent 16px, rgba(128, 128, 128, 0.5) 1mm, rgba(128, 128, 128, 0.5) 4.5mm);
    //  border: solid grey 0.5px;
    //}

    .ql-editor {
        //background-color: #02baff;
        padding: 25mm;

        //--left-padding: 25mm;
        //--right-padding: 25mm;
        //
        //--top-padding: 25mm;
        //--bottom-padding: 25mm;
        //
        //
        //width: calc(210mm - var(--right-padding)*2 );
        //height: calc(297mm - var(--bottom-padding)*2 );
        //
        //margin-left: var(--left-padding);
        //margin-top: var(--top-padding);
        
        li{
            color: unset;
        }
        
    }

    .ql-tooltip {
        border-radius: 12px !important;
    }

    .ql-toolbar {
        display: flex;
        flex-direction: column;

        .ql-formats {
            margin: 8px !important;

            .ql-color-picker line:first-child, .ql-background {
                opacity: 0.8;
                stroke-width: 4;
                stroke-linecap: square;
            }

            .ql-size, .ql-font, .ql-header {
                border: 1px solid white;
            }
        }

        .ql-picker-label {
            display: flex;
            align-items: center;
        }

    }


    .ql-picker.ql-font {
        .ql-picker-item {
            font-size: 0;

            &:before {
                content: attr(data-value) !important;
                font-size: 14px;
            }
        }
    }

    .ql-picker.ql-font {
        .ql-active {
            &:before {
                content: attr(data-value) !important;
                font-size: 14px;
            }
        }
    }

    //

    .ql-picker.ql-font .ql-picker-label[data-value="Roboto"]::before,
    .ql-picker.ql-font .ql-picker-item[data-value="Roboto"]::before {
        font-family: "Roboto", cursive;;
        content: "Roboto" !important;
    }

    //
    //.ql-picker.ql-font .ql-picker-label[data-value="Raleway"]::before,
    //.ql-picker.ql-font .ql-picker-item[data-value="Raleway"]::before
    //{
    //    font-family: "Raleway", cursive;;
    //    content: "Raleway" !important;
    //}
    //
    //.ql-picker.ql-font .ql-picker-label[data-value="Montserrat"]::before,
    //.ql-picker.ql-font .ql-picker-item[data-value="Montserrat"]::before
    //{
    //    font-family: "Montserrat", cursive;;
    //    content: "Montserrat" !important;
    //}
    //
    //.ql-picker.ql-font .ql-picker-label[data-value="Lato"]::before,
    //.ql-picker.ql-font .ql-picker-item[data-value="Lato"]::before
    //{
    //    font-family: "Lato", cursive;;
    //    content: "Lato" !important;
    //}
    //
    //.ql-picker.ql-font .ql-picker-label[data-value="Rubik"]::before,
    //.ql-picker.ql-font .ql-picker-item[data-value="Rubik"]::before
    //{
    //    font-family: "Rubik", cursive;;
    //    content: "Rubik" !important;
    //}
    //
    ///* Set content font-families */

    .ql-font-Roboto {
        font-family: "Roboto";
    }

    .ql-font-Monospace {
        font-family: "Monospace";
    }

    .ql-font-Arial {
        font-family: "Arial, Helvetica, sans-serif";
    }

    .ql-font-Consolas {
        font-family: "Consolas";
    }

    //.ql-font-Raleway {
    //    font-family: "Raleway";
    //}
    //.ql-font-Montserrat {
    //    font-family: "Montserrat";
    //}
    //.ql-font-Lato {
    //    font-family: "Lato";
    //}
    //.ql-font-Rubik {
    //    font-family: "Rubik";
    //}
`;