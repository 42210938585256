import React from 'react';
import {WorkspaceService} from "../../components/workspace/WorkspaceService";

import {useDeviceSelectors, useMobileOrientation} from 'react-device-detect';


import {WorkspaceMobile} from "./mobile/WorkspaceMobile";
import WorkspaceDesktop from "./desktop/WorkspaceDesktop";
import PropTypes from "prop-types";
import {DocumentViewProvider} from "./desktop/document/DocumentViewProvider";


export function Workspace({back, visitLibrary}){

    const { isLandscape, orientation } = useMobileOrientation()
    const [selectors, data] = useDeviceSelectors(window.navigator.userAgent)


    const renderBrowserView = () => {
        console.log("Render BrowserView")
        return(
            <div style={{height: "100vh"}}>
                {/*<BrowserView style={{*/}
                {/*    height: "100vh"*/}
                {/*}}>*/}
                    <DocumentViewProvider>
                        {/*<IpfsProvider>*/}
                        <WorkspaceDesktop back={back}/>
                        {/*</IpfsProvider>*/}
                    </DocumentViewProvider>
                {/*</BrowserView>*/}
            </div>

        )
    }

    const renderMobileView = () => {
        console.log("Render MobileView")


        return (
            <div>
                {/*<MobileView>*/}
                    <WorkspaceMobile
                        back={back}
                        visitLibrary={visitLibrary}
                    />
                {/*</MobileView>*/}
            </div>

        )
    }

    const renderDefaultView = () => { return renderBrowserView() }

    const renderView = () => {
        if(selectors.isDesktop) return renderBrowserView();
        else if(selectors.isMobile) return renderMobileView();
        // else if(selectors.isMobile) return renderMobileView();
        // else if(selectors.isTablet) return renderMobileView();
        else return renderDefaultView();
    }

    return(
        <WorkspaceService>

            {
                renderView()
            }

        </WorkspaceService>
    )

}

Workspace.propTypes = {
    back: PropTypes.func,
    visitLibrary: PropTypes.func
}
