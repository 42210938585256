import PropTypes from "prop-types";
import {useLanguageService} from "../../language/LanguageProvider";
import {
    DiaryInfoDiv,
    DocumentDiv,
    DocumentImageDiv,
    DocumentInfoDiv,
    DocumentMetaDataDiv,
    DocumentPricingDiv,
    LibraryDocumentContent,
    LibraryHeader,
    LibrarySearchDiv,
    ReadOverlay,
    SearchIconWrapper
} from "../../components/published/Library.styles";
import {Autocomplete, Button, InputBase} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, {useEffect, useState} from "react";
import {reduceKeyString} from "../../utils/StringUtils";
import Logo from "../../components/common/logo/Logo";
import {TagViewer} from "../../components/diary/components/modals/PublishDocumentModal";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import {useDAO} from "../../components/dao/DaoProvider";
import {useWorkspace} from "../../components/workspace/WorkspaceProvider";
import {Document} from "../workspace/desktop/document/Document";
import {ArrowBack} from "@mui/icons-material";
import {DocumentViewProvider} from "../workspace/desktop/document/DocumentViewProvider";

const VIEW = {
    LOBBY: "LOBBY",
    PREVIEW: "PREVIEW"
}

/**
 * PublishDocuments is the View of all published Books/Documents
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function LibraryV2(props) {

    const {strings} = useLanguageService();
    const [view, setView] = useState(VIEW.LOBBY);

    const {workspace, preview, resetWorkspace} = useWorkspace();

    const {storageContract: web3DocsContract, init: daoInit} = useDAO();

    const [documents, setDocuments] = useState(new Map());
    const updateDocumentMap = (k, v) => {
        setDocuments(new Map(documents.set(k, v)))
    }

    const [searchCriteria, setSearchCriteria] = useState([])
    const [tags, setTags] = useState([]);
    const [init, setInit] = useState(false);

    useEffect(() => {
        if (daoInit) {
            (async () => {

                console.log("DAO-INIT-STATE: ", daoInit)

                console.log(web3DocsContract)

                let contractName = await web3DocsContract.name();
                console.log(contractName)

                let publishedFilter = web3DocsContract.filters.Published();
                let events = await web3DocsContract.queryFilter(publishedFilter);

                // let fetchedDocuments = [];
                let documentMap = new Map();

                try {
                    console.log(events)
                    for (let event of events) {
                        let publishedDocument = event.args._docAddress;
                        let docObj = await web3DocsContract.myDocs(publishedDocument);
                        let doc = {
                            sealed: docObj.isSealed,
                            publicKey: publishedDocument,
                            privateKey: docObj.key,
                            details: null
                        };
                        documentMap.set(publishedDocument, doc);
                        // fetchedDocuments.push(doc)
                    }
                    // setDocuments(fetchedDocuments);
                    setDocuments(documentMap);
                    console.log(documentMap)
                    setInit(true);
                } catch (e) {
                    console.error(e)
                }

                // let publishedFilter = diaryContract.filters.Published();
                // let events = await diaryContract.queryFilter(publishedFilter);
                // let fetchedDiaries = [];
                //
                // try{
                // for(let event of events) {
                //     let publishedDiary = event.args._diaryAddress;
                //     let diaryObj = await diaryContract.myDocs(publishedDiary);
                //     let diary = {
                // 	sealed: diaryObj.isSealed,
                // 	publicKey: publishedDiary,
                // 	privateKey: diaryObj.key
                //     };
                //     fetchedDiaries.push(diary)
                // }
                // setDiaries(fetchedDiaries);
                // }catch(e){
                // console.error(e)
                // }

            })();
        }

    }, [daoInit])

    useEffect(() => {

        if (daoInit && init) {
            (async () => {
                console.log(documents)
                //Documents with Title and Text
                if ((await web3DocsContract.version()) >= 2) {
                    let tags = await web3DocsContract.getAllTags();
                    setTags(Array.from(new Set(tags)));

                    // let countPublishedDocuments = await web3DocsContract.countPublishedDocuments()

                    console.log(documents.keys())
                    for (let _docAddress of documents.keys()) {
                        console.log(_docAddress)
                        let publishedDocument = await web3DocsContract.getPublishedDocumentPerAddress(_docAddress);
                        console.log(publishedDocument)
                        if (documents.has(publishedDocument.document)) {
                            let docObj = documents.get(publishedDocument.document);
                            docObj.details = {
                                tags: publishedDocument.tags,
                                title: publishedDocument.title,
                                timestamp: publishedDocument.timestamp
                            }
                            updateDocumentMap(publishedDocument.document, docObj);
                        } else {
                            console.log(documents, "does not")
                        }
                    }


                    // for(let i=0; i < countPublishedDocuments; i++){
                    // let publishedDocument = await web3DocsContract.getPublishedDocument(i);
                    // let documentId = publishedDocument.document;
                    //
                    // if(documents.has(documentId)){
                    //     let docObj = documents.get(documentId);
                    //     docObj.details = {
                    // 	tags: publishedDocument.tags,
                    // 	title: publishedDocument.title,
                    // 	timestamp: publishedDocument.timestamp
                    //     }
                    //     updateDocumentMap(documentId, docObj);
                    // }else{
                    //     console.log(documents, "does not")
                    // }
                    // }
                }
            })();
        }
    }, [init])

    const setPreviewView = (documentToPreview) => {
        console.log(workspace)
        preview.setDocumentToPreview(documentToPreview.privateKey);
        setView(VIEW.PREVIEW);
    }

    const getDocumentsWithCriteria = () => {

        if (searchCriteria.length === 0) return documents;
        else {
            let titleFiltered = [...documents].filter(([documentId, document]) => document.details ? searchCriteria.some(rx => (
                rx.test(document.details.title.toLowerCase())
                ||
                rx.test(document.details.tags)
            )) : false);
            console.log("TitleFiltered: ", titleFiltered)

            return new Map(titleFiltered);
        }
    }

    if (view === VIEW.PREVIEW) {
        console.log(preview.document)

        if(preview.document !== undefined){
            return (
                <div style={{height: "100%"}}>
                    {/*<h1>{diaryToPreview.publicKey}</h1>*/}

                    {/*<MyDiary*/}
                    {/*    back={() => {*/}
                    {/*        setView(VIEW.LOBBY)*/}
                    {/*        workspace.reset();*/}
                    {/*    }}*/}
                    {/*    preview={true}    //Used for SettingsPanel*/}
                    {/*/>*/}
                    <DocumentViewProvider>
                        <Document
                            // setTitle={setDocumentTitle}
                            currentDocument={{
                                address: preview.document.address,
                                details: {
                                    title: "CHANGEME"
                                }
                            }}
                            // handleSaveDocument={saveDocument}

                            // handlePublishDocument={publishDocument}
                        />
                    </DocumentViewProvider>
                    <Button onClick={() => {
                        setView(VIEW.LOBBY);
                        resetWorkspace();
                    }}>Back</Button>

                </div>

            )
        }else{
            return <>Loading....</>
        }


    } else if (view === VIEW.LOBBY) {
        return (
            <div style={{height: "100%", display: "flex", flexDirection: "column"}}>
                <LibraryHeader>
                    <h1>{strings.LIBRARY_HEADER}</h1>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                        padding: "0px 5%",
                    }}>
                        <LibrarySearch documents={documents} searchCriteria={searchCriteria.map(c => c.source)}
                                       setSearchCriteria={setSearchCriteria} tags={tags}/>
                        <fieldset>
                            <legend>{strings.LIBRARY_CRITERIA}</legend>
                            <TagViewer
                                selectedTags={searchCriteria.map(c => c.source)}
                                setSelectedTags={(values) => setSearchCriteria(values.map(x => new RegExp(x)))}
                                style={{
                                    // justifyContent: "center",
                                    fontSize: "0.9em",
                                    // height: "20px",
                                    borderRadius: "5px",
                                }}
                            />
                        </fieldset>
                    </div>
                    <div style={{
                        backgroundColor: "white",
                        padding: "12px",
                        // display:
                    }}>
                        <Button
                            style={{
                                position: "absolute",
                                // float: "left",
                                left: 0,
                                transform: "translateY(-24%)",
                                lineHeight: "0"
                            }}
                            startIcon={<ArrowBack/>}
                            variant={"text"}
                            onClick={() => props.back()}
                        >
                            {strings.BUTTON_BACK_DESC}
                        </Button>
                        <span>{[...getDocumentsWithCriteria().values()].length} {strings.LIBRARY_SEARCH_FOUND}</span>
                    </div>
                </LibraryHeader>

                {/*<hr/>*/}
                {
                    (documents.size === 0)
                        ?
                        <LibraryDocumentContent style={{textAlign: "center"}}>
                            <p>No documents found!</p>
                        </LibraryDocumentContent>
                        :
                        <LibraryDocumentContent>
                            {
                                [...getDocumentsWithCriteria().values()].map((document, key) => {
                                    return (
                                        <DocumentPreview document={document} key={key} number={key}
                                                         setDocumentForPreview={(diary) => setPreviewView(diary)}/>
                                    )
                                })
                                // documents.map((document, key) => {
                                //     return (
                                // 	<>
                                // 	    <DocumentPreview document={document} key={key} number={key} setDocumentForPreview={(diary) => setPreviewView(diary)}/>
                                // 	    {/*<hr style={{width: "100%"}}/>*/}
                                // 	</>
                                //     )
                                // })
                            }
                        </LibraryDocumentContent>
                }

                {/*<hr/>*/}

                {/*<h1>List of published Documents with Title and Tags</h1>*/}

                {/*<LibraryDiv>*/}
                {/*    <div>*/}
                {/*	<h3>Tags</h3>*/}
                {/*	{*/}
                {/*	    tags.map((tag,key) =>*/}
                {/*		 <label key={key}> {tag} </label>*/}
                {/*	    )*/}
                {/*	}*/}
                {/*    </div>*/}
                {/*    {*/}

                {/*    }*/}
                {/*</LibraryDiv>*/}


                {/*<ExitDiv>*/}
                {/*    <Exit onClick={() => props.back()}/>*/}
                {/*</ExitDiv>*/}

            </div>
        )
    }

}

LibraryV2.propTypes = {
    // address: PropTypes.string,
    // abi: PropTypes.array,
    back: PropTypes.func
}

function LibrarySearch(props) {

    const {strings} = useLanguageService();

    const handleChange = (value, newValue) => {
        let keywords = newValue.map(x => new RegExp(x));
        props.setSearchCriteria(keywords)
    }

    return (
        <LibrarySearchDiv>
            <Autocomplete
                freeSolo
                multiple
                value={props.searchCriteria}
                options={props.tags}
                getOptionLabel={(option) => option}
                sx={{
                    minWidth: "calc(100% - 44px)"
                }}
                renderInput={(params) => {
                    const {InputLabelProps, InputProps, ...rest} = params;
                    return <InputBase
                        sx={{paddingInlineStart: "12px"}}
                        {...params.InputProps}
                        {...rest}
                        autoFocus
                        placeholder={strings.LIBRARY_SEARCH_INPUT_PLACEHOLDER}
                    />
                }}
                renderTags={(value, getTagProps) =>
                    <></>
                }

                onChange={handleChange}
            />
            <SearchIconWrapper>
                <SearchIcon style={{stroke: "white", strokeWidth: "1"}}/>
            </SearchIconWrapper>
        </LibrarySearchDiv>
    )

}

LibrarySearch.propTypes = {
    documents: PropTypes.object,
    tags: PropTypes.array,
    searchCriteria: PropTypes.array,
    setSearchCriteria: PropTypes.func
}

function DocumentPreview(props) {

    const document = props.document;

    const [showOverlay, setShowOverlay] = useState(false);

    let pricing = false;

    return (
        <DocumentDiv
            onMouseEnter={() => {
                setShowOverlay(true)
            }}
            onMouseLeave={() => {
                setShowOverlay(false)
            }}
        >
            <DiaryInfoDiv style={{
                filter: showOverlay ? "blur(4px)" : "unset",
            }}>
                {
                    (document.details !== null) ?
                        <>
                            <DocumentImageDiv>
                                <img src={"https://picsum.photos/200/300"} alt={""}/>
                            </DocumentImageDiv>
                            <DocumentMetaDataDiv>
                                <p>ID: {reduceKeyString(document.publicKey, 6, 4)}</p>
                                <Logo src={"/images/opensea_blue.svg"} style={{
                                    width: "24px"
                                }}/>
                            </DocumentMetaDataDiv>
                            <DocumentInfoDiv>
                                <header>
                                    <label>{document.details.title}</label>
                                    {/*<p>ID: {reduceKeyString(document.publicKey, 6, 4)}</p>*/}
                                    <p className={"author"}>Author: [AUTOR] </p>
                                </header>
                                <section>
                                    <TagViewer
                                        selectedTags={document.details.tags}
                                        viewOnly
                                        style={{
                                            // justifyContent: "space-between",
                                            fontSize: "0.8em",
                                            height: "20px",
                                            borderRadius: "5px",
                                        }}
                                    />
                                </section>
                            </DocumentInfoDiv>

                            <DocumentPricingDiv>
                                <span>Placeholder so it will not disappear</span>
                            </DocumentPricingDiv>
                        </>
                        :
                        <>
                            <DocumentImageDiv>
                                <img src={"https://placehold.co/800x400"} alt={"placeholder"}/>
                            </DocumentImageDiv>
                            <DocumentInfoDiv>
                                <header>
                                    Book #{props.number}
                                    {/*<p className={"author"}>Author: [AUTOR] </p>*/}
                                </header>
                                <section>
                                    <p>ID: {reduceKeyString(document.publicKey, 6, 4)}</p>
                                </section>
                            </DocumentInfoDiv>
                        </>
                    // :
                    // <>
                    // <header>Book #{props.number}</header>
                    // <section>
                    //     <p>ID: {reduceKeyString(document.publicKey, 6, 4)}</p>
                    // </section>
                    // </>

                }
                {/*<p>ID: {reduceKeyString(document.publicKey, 6, 4)}</p>*/}
            </DiaryInfoDiv>


            <ReadOverlay
                style={{
                    display: showOverlay ? "flex" : "none",
                    backgroundColor: showOverlay ? "rgba(0,0,0,0.2)" : "transparent",
                }}
                onClick={() => props.setDocumentForPreview(document)}
            >
                <p>Read</p>
                <LocalLibraryIcon fontSize={"large"}/>
                {/*<Button variant={"contained"} onClick={() => props.setDiaryForPreview(diary)}>*/}
                {/*<ReadMoreIcon fontSize={"large"}/>*/}
                {/*</Button>*/}
            </ReadOverlay>

        </DocumentDiv>
    )

}

DocumentPreview.propTypes = {
    document: PropTypes.object,
    number: PropTypes.number,
    setDocumentForPreview: PropTypes.func
}

export default LibraryV2;