import styled from "styled-components";
import {device} from "../../../../theme/DeviceStyling";


export const NavBarWrapper = styled.nav`
  position: relative;
  background-color: ${(props) => props.theme.global.base_contra};
  z-index: 2;
  @media ${device.max.tablet} {
    background-color: transparent;
  }
  
`;

export const NavBar = styled.div`
  position: relative;
  
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: var(--navbar-height);
  //max-height: 90px;
  z-index: 1;
  

  //margin: 0 auto;
  width: 85vw;
  margin: 0 auto;
`;

export const LogoContainer = styled.div`
  
  cursor: pointer;
  
  img{
    max-width: 220px;
    height: auto;
  }
  
  @media ${device.max.tablet} {
    img{
      max-width: 140px;
    }
  }
`;

export const LinksContainer = styled.div`
  a{
    margin-right: 3rem;
    text-decoration: none;
    color: black;
    font-size: 1.1rem;
    font-weight: 600;
  }
  
  .menuItem {
      font-size: 1.15rem;
  }

  @media ${device.max.tablet} {
    display: none;
  }
`;

export const AppButton = styled.button`
  padding: 0.9rem 1.75rem;
  background-color: white;
  color: #0582d2;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
`;

export const MenuContainer = styled.div`
  
  display: none;
  
  svg {
    font-size: 1.5rem;
    cursor: pointer;
  }

  @media ${device.max.tablet} {
    display: flex;
    color: white;
    
    border-radius: 50%;
    border: 1px solid white;
    padding: 0.5rem;
    
    :hover{
      background-color: white;
      color: black;
    }
  }
`;