import React from 'react';

import styles from "./Landing.module.css";

import Navbar from "./components/navbar/Navbar";

import Home from "./components/home/Home";
import About from "./components/about/About";
import Footer from "../../branding/Footer";
import Work from "./components/work/Work";
import Testimonial from "./components/testimonial/Testimonial";
import Contact from "./components/contact/Contact";
import FaqSection from "./components/faq/FAQ";

function LandingPage(){

    return(
	<div className={styles.Top}>
	    <Navbar />
	    <div className={styles.Landing}>
			<Home/>
			<Contact />
			<About />
			<Work />
			<Testimonial />
			<FaqSection />
	    </div>
	    <Footer/>
	</div>
    )
}

export default LandingPage;