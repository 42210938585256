import React, {createContext, useContext, useMemo, useState} from "react";
import PropTypes from "prop-types";
import {Contract, ethers} from "ethers";
import ABI from "../abi";
import LoadingHexagon from "../common/loadingHexagon/LoadingHexagon";
import {useLanguageService} from "../../language/LanguageProvider";

const DaoContext = createContext(undefined);

/**
 * Top-Level Provider which is used to interact with the Web3Lib-Storage-Contract
 * Loads the Storage-Contract
 * Has all the necessary functions to sign a message(for e.g. Gasless-Tx)
 *
 * @param props
 * @constructor
 * @return {
 * storageContract: Contract,
 * readOnly: bool,
 * signer: Web3Provider.signer,
 * library: Web3Provider,
 * isGasLess: bool,
 * setGasLess: fn,
 * signEntry: fn,	//Method for GasLess-Tx
 * init: bool
 * }
 */
export function Web3DocDaoProvider({gasStation, web3lib, children, readOnly, library}) {

    const {strings} = useLanguageService();
    // const {activate, deactivate, active, chainId, account, library} = useWeb3React();
    const [storageContract, setStorageContract] = useState({});
    const [gasStationContract, setGasStationContract] = useState({});

    const [init, setInit] = useState(false);

    const [signer, setSigner] = useState((readOnly) ? library : library.getSigner())

    const [gasLess, setGasLess] = useState(false);

    // console.log("Dao-Factory", web3lib)

    const _getLibraryContract = async (web3lib, signer) => {
        let version_contract = new Contract(web3lib.address, ABI.genesis_version, signer);
        let version = await version_contract.version();

        return new Contract(web3lib.address, ABI["genesis_v" + version], signer);
    }

    const _getGasStationContract = async (gasStation, signer) => {
        return new Contract(gasStation.address, ABI.gas_station, signer);
    }

    const signEntry = async (_docAddress, msg, sender) => {

        let network = await library.getNetwork();

        // console.log(network.chainId);

        const types = ["address", "string", "address"];
        const params = [_docAddress, msg, sender];
        const message = ethers.utils.solidityKeccak256(types, params);
        console.log("PARAMS: ", params)
        console.log("MESSAGE: ", message)
        const arrayifyMessage = ethers.utils.arrayify(message);
        // console.log(arrayifyMessage)
        console.log(signer)
        const signature = await signer.signMessage(arrayifyMessage);	//TODO: Change to TypedData_v4 EIP712 later
        console.log(signature);

        return {params: params, signature: signature};

        // const msgParams = {
        //     domain: {
        // 	name: 'cryptago.eu',
        // 	version: '1',
        // 	chainId: network.chainId,
        // 	verifyingContract: storageContract.address,
        //     },
        //     message: {
        // 	entry: entry,
        // 	// timestamp: timestamp
        //     },
        //     primaryType: 'Web3DocEntry',
        //     types: {
        // 	EIP712Domain: [
        // 	    { name: 'name', type: 'string' },
        // 	    { name: 'version', type: 'string'},
        // 	    { name: 'chainId', type: 'uint256'},
        // 	    { name: 'verifyingContract', type: 'address'}
        // 	],
        // 	Web3DocEntry: [
        // 	    { name: 'entry', type: 'string'},
        // 	    // { name: 'timestamp', type: 'uint256'}
        // 	],
        //     }
        // };
        // console.log(msgParams)
        //
        // const signature = await library.provider.request({
        //     method: 'eth_signTypedData_v4',	//MetaMask Function
        //     params: [address, JSON.stringify(msgParams)]
        // })
        // console.log("Signature", signature);
        // return {signature: signature, params: msgParams};
    }


    const subscribeToEvents = () => {
        console.log("Subscribe to Event - NewBlock")
        library.on('block', (block) => {
            console.log("New Block", block)
        })
    }

    useMemo(() => {

        (async () => {

            let mode = readOnly ? "READ-ONLY" : "FULL";
            console.log("LIB:", library.getSigner())

            // let signer = (readOnly) ? library : library.getSigner();	//If ReadOnly, there is no Signer available therefore setting provider as signer

            console.log("%c ----- Web3Lib Initialization[" + mode + "] -----", "font-size: medium")
            let web3DocStorageContract = await _getLibraryContract(web3lib, signer);
            console.log("%c Library-Address: " + web3DocStorageContract.address, "font-size: small")
            console.log("%c Library-Name: " + await web3DocStorageContract.name(), "font-size: small")
            console.log("%c Library-Version: " + await web3DocStorageContract.version(), "font-size: small")
            console.log("%c Library-Contract: ", "font-size: small", web3DocStorageContract)
            setStorageContract(web3DocStorageContract);
            console.log("%c ----- Web3Lib Initialization finished-----", "font-size: medium");

            // subscribeToEvents()
            console.log("%c ----- GasStation Initialization -----", "font-size: medium")
            let gasStationContract = await _getGasStationContract(gasStation, signer);
            console.log("%c GasStation-Address: " + gasStationContract.address, "font-size: small")
            console.log("%c Library-Contract: ", "font-size: small", gasStationContract)
            setGasStationContract(gasStationContract);
            console.log("%c ----- GasStation Initialization finished-----", "font-size: medium");

            setInit(true);
        })();
    }, [])

    if (!init) {
        return <LoadingHexagon>{strings.LOADING} {strings.VIEW_LIBRARY}...</LoadingHexagon>
    } else {
        return (
            <DaoContext.Provider
                value={{
                    storageContract,
                    gasStationContract,
                    readOnly,
                    signer: signer,
                    library: library,
                    isGasLess: gasLess,
                    setGasLess,
                    signEntry,	//Method for GasLess-Tx

                    init,
                }}
            >
                {children}
            </DaoContext.Provider>
        )
    }
}

Web3DocDaoProvider.propTypes = {
    readOnly: PropTypes.bool.isRequired,

    library: PropTypes.object.isRequired,

    web3lib: PropTypes.shape({
        address: PropTypes.string.isRequired,
        abi: PropTypes.array
    }),
    gasStation: PropTypes.shape({
        address: PropTypes.string.isRequired
    }),
    children: PropTypes.node,

}

export function useDAO() {

    const context = useContext(DaoContext);
    if (!context) throw Error("useDAO can only be used with DaoProvider component")
    return context;

}