import {useTheme} from "styled-components";
import React, {useEffect, useState} from "react";
import LockIcon from "@mui/icons-material/LockOutlined";
import LockOpenIcon from "@mui/icons-material/LockOpenOutlined";
import {Button, CircularProgress, TextField} from "@mui/material";
import {UnlockModal} from "../../styling/Diary.styles";
import CloseIcon from "@mui/icons-material/Close";
import styles from "../../styling/diary.module.css";
import PropTypes from "prop-types";
import {useLanguageService} from "../../../../language/LanguageProvider";
import {useWorkspace} from "../../../workspace/WorkspaceProvider";

function UnlockModalComponent(props) {

    const {strings} = useLanguageService();
    const theme = useTheme();
    // const {diary, web3, unlocked} = useDiary();
    const {workspace, unlocked} = useWorkspace();

    const [lockIcon, setLockIcon] = useState(<LockIcon style={{fontSize: "6em", color: theme.global.base}}/>)
    const [buttonIcon, setButtonIcon] = useState(<LockOpenIcon/>)

    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("")
    const [passwordMatch, setPasswordMatch] = useState(null);
    const [errorText, setErrorText] = useState("");

    const [unlockedAnimation, setUnlockedAnimation] = useState(false);		//Change this to diary.isUnlocked
    const unlock = () => {
        setButtonIcon(<CircularProgress color="inherit" size={"2.0em"}/>)
        setTimeout(() => {
            workspace.unlockWorkspace(password).then((result) => {
                if (result) {
                    setLockIcon(<LockOpenIcon style={{fontSize: "6em", color: theme.global.base}}/>)
                    setUnlockedAnimation(true)
                    clear()
                    props.setShowModal(false);
                } else {
                    //Not successfull
                }
            }).catch((error) => {
                console.error(error)
                console.table(error)
                setPasswordMatch(false)
                setErrorText(error.message)
                setButtonIcon(<LockOpenIcon/>)
            })
        }, 1000);
    }

    const unlockWithConfirm = () => {
        if (password === passwordConfirm) {
            unlock();
        } else {
            setPasswordMatch(false)
            setErrorText("Passwords do not match")
            setTimeout(() => {
                setPasswordMatch(null)
                setErrorText("")
            }, 3000)
        }

    }

    const clear = () => {
        setPassword("")
        setPasswordConfirm("")
        setErrorText("")
        setPasswordMatch(null);
    }

    useEffect(() => {
        if (unlocked === true) {
            props.setShowModal(false);
            //RESET
            setLockIcon(<LockIcon style={{fontSize: "6em", color: theme.global.base}}/>)
            setButtonIcon(<LockOpenIcon/>)
            setPassword("")
            setPasswordConfirm("")
            setErrorText("")
            setPasswordMatch(null);
            setUnlockedAnimation(false)
        }
    }, [unlocked])

    return (
        <UnlockModal style={{display: (props.showModal) ? "grid" : "none"}} className={ props?.documentOverlay ? "documentOverlay" : ""}>

            <div id={"content"}>
                {
                    props?.documentOverlay ?
                        <></>
                        :
                        <div id={"closeBtn"} onClick={() => props.setShowModal(false)}><CloseIcon/></div>
                }

                <div>
                    {lockIcon}
                    {
                        (unlockedAnimation === true) ? <div className={styles.firework}></div> : <></>
                    }

                </div>

                {
                    (workspace.hasIdentity())
                        ?
                        <>
                            <div style={{color: "black"}}>
                                <p>{strings.DOCUMENT_LOCKED}</p>
                                <p>{strings.LOCK_IDENTITY_EXIST} </p>
                            </div>
                            <div style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
                                <TextField
                                    inputRef={(input) => input && input.focus()}
                                    required
                                    id="outlined-password-input"
                                    label="Password"
                                    type="password"
                                    autoComplete="current-password"
                                    value={password}
                                    onChange={(elem) => {
                                        setPassword(elem.target.value);
                                    }}
                                    onKeyDown={(e) => e.key === "Enter" ? unlock() : ""}
                                    error={(passwordMatch === false)}
                                    helperText={(passwordMatch === false) ? errorText : null}
                                />
                                {/*<Button variant={"contained"} onClick={unlock}>{buttonIcon}</Button>*/}
                            </div>
                            <Button
                                variant={"contained"}
                                onClick={unlock}
                                style={{height: "40px", width: "100px"}}
                            >
                                {buttonIcon}
                            </Button>
                        </>

                        :

                        <>
                            <div style={{color: "black"}}>
                                <p>{strings.LOCK_IDENTITY_NOT_EXIST}</p>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "12px",
                                justifyContent: "center",
                                alignContent: "center"
                            }}>
                                <TextField
                                    required
                                    id="outlined-password-input"
                                    label="Password"
                                    type="password"
                                    autoComplete="current-password"
                                    value={password}
                                    onChange={(elem) => {
                                        setPassword(elem.target.value);
                                    }}
                                />
                                <TextField
                                    required
                                    error={(passwordMatch === false)}
                                    id="outlined-password-input"
                                    label="Confirm password"
                                    type="password"
                                    autoComplete="current-password"
                                    value={passwordConfirm}
                                    onChange={(elem) => {
                                        setPasswordConfirm(elem.target.value);
                                    }}
                                    onKeyDown={(e) => e.key === "Enter" ? unlockWithConfirm() : ""}
                                    helperText={(passwordMatch === false) ? errorText : null}
                                />
                            </div>
                            <Button
                                variant={"contained"}
                                onClick={unlockWithConfirm}
                                style={{height: "40px", width: "100px"}}
                            >
                                {buttonIcon}
                            </Button>
                        </>

                }


            </div>
        </UnlockModal>
    )
}

UnlockModalComponent.propTypes = {
    documentOverlay: PropTypes.bool,
    showModal: PropTypes.bool,
    setShowModal: PropTypes.func,
    closeModal: PropTypes.func,
	workspace: PropTypes.any,
	unlocked: PropTypes.bool
}

export default UnlockModalComponent;