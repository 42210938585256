import React from 'react';
import BannerBackground from "../../../assets/background.png";
import {useLanguageService} from "../../../../language/LanguageProvider";

import "./home.css";
import "../../FontStyle.styles.css"

function Home(){

    const {landing, landingPR} = useLanguageService();

    return (
	<div className="home-section-container">
	    <div className={"home-banner-container"}>
		<div className={"home-bannerImage-container"}>
		    <img src={BannerBackground} alt="" />
		</div>
		<div className={"home-text-section"}>
		    <h1 className="primary-heading" data-text={landingPR.SLOGAN_1}>
			{landingPR.SLOGAN_1}
		    </h1>
		    <p className="primary-text" data-text={landingPR.HEADER_1}>
			{landingPR.HEADER_1}
		    </p>

		    {/*<ExploreButton>*/}
			{/*	{landing.BUTTON_EXPLORE_TEXT} <SearchIcon />{" "}*/}
		    {/*</ExploreButton>*/}

		</div>
		{/*<div className="home-image-section">*/}
		{/*    <img src={BannerImageSrc} alt="" />*/}
		{/*</div>*/}
	    </div>
	</div>
    );
}

export default Home;