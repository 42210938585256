import React from 'react';
import styled from "styled-components";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';

import {Avatar} from "../../components/Avatar";
import PropTypes from "prop-types";
import {useNavigate} from "react-router-dom";

export function TopMenu({back}){


    return(
        <MenuFooter style={{position: "relative", bottom: "unset", top: 0}}>
            <div onClick={() => {back()}}>
                <ArrowBackIcon/>
            </div>
        </MenuFooter>
    )
}
TopMenu.propTypes = {
    back: PropTypes.func
}

export function FooterMenu({visitLibrary}){

    const navigate = useNavigate();

    const avatarSize = "32px";

    return(
        <MenuFooter>
            <div onClick={() => {navigate("home")}}>
                <HomeIcon/>
            </div>
            <div onClick={() => {visitLibrary()}}>
                <SearchIcon/>
            </div>
            <div>
                <div
                    style={{width: avatarSize, height: avatarSize, borderRadius: "50%", overflow: "hidden"}}
                    onClick={() => {navigate("account")}}
                >
                    <Avatar/>
                </div>
            </div>
        </MenuFooter>
    )

}
FooterMenu.propTypes = {
    // back: PropTypes.func,
    visitLibrary: PropTypes.func
}

const MenuFooter = styled.footer`

  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;

  width: 100%;
  height: 50px;

  //padding-left: 20px;

  color: lightgrey;
    //background-color: ${props => props.theme.global.base};
  background: grey;

  > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    background: grey;

    :hover {
      //border-radius: 50%;
      cursor: pointer;
      background: radial-gradient(rgba(211, 211, 211, 0.3), grey 60%);
    }

  }

  > svg {
    font-size: 1.8em;
  }
}

`;