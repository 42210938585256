import {useDAO} from "../../../../components/dao/DaoProvider";
import {useWorkspace} from "../../../../components/workspace/WorkspaceProvider";
import React, {useEffect, useMemo, useRef, useState} from "react";
import PropTypes from "prop-types";
import {DocumentContentFrame, RichTextContainer} from "./Document.styles";

import ReactQuill from "react-quill";

import {useDocumentView} from "./DocumentViewProvider";
import {useReactToPrint} from "react-to-print";
import {useWeb3React} from "@web3-react/core";

export function Document(props) {

    const {account, chainId, library} = useWeb3React();

    const printableComponentRef = useRef();

    let web3docs = useDAO();
    let workspaceContract = web3docs.storageContract;

    const {workspace} = useWorkspace();

    const {documentViewLoaded, setDocumentContent} = useDocumentView();

    const {currentDocument} = props;

    //Document Props
    // const [toggleContextMenu] = useState(false);

    useEffect(() => {
        // console.log("CURR DOC", currentDocument)
        console.log(currentDocument, documentViewLoaded)
        if (currentDocument !== null && documentViewLoaded) {
            (async () => {
                let title = currentDocument.details?.title ? currentDocument.details.title : currentDocument.id

                // console.log(currentDocument)
                let web3Document = await workspaceContract.readDocument(currentDocument.address)
                console.log("Web3 Doc", web3Document)
                if(web3Document.content.length > 0){
                    let web3DocumentContentDecrypted = await workspace.decryptMessage(web3Document.content.join(""));
                    setDocumentContent(web3DocumentContentDecrypted);
                }else{
                    // setDocumentContent("");
                    // setDocumentContent("");
                }

            })();
        }

    }, [currentDocument, documentViewLoaded]);

    const handlePrint = useReactToPrint({
        content: () => printableComponentRef.current
    })
    const handleKeyDown = (event) => {
        // console.log(event)
        if (event.ctrlKey) {
            if (event.key === 'p') {
                event.preventDefault();
                handlePrint(event)
            }

            if(event.key === 's') {
                event.preventDefault();
                console.log("Save Document")
                // console.log(documentContent)

                props.handleSaveDocument();
            }
        }
    }

    if (currentDocument) {
        return (
            <DocumentContentFrame
                id={"content_frame"}
                ref={printableComponentRef}
                onKeyDown={handleKeyDown}
            >
                <RichTextContainer id={"container"}>
                    {/*<DocumentViewProvider>*/}
                        <EditorPageViewer
                            // content={documentContent}
                            // content={documentEntries.map(entries => entries.entry).join("")}
                        />
                    {/*</DocumentViewProvider>*/}
                </RichTextContainer>
            </DocumentContentFrame>
        )
    } else {
        return (
            <DocumentContentFrame
                // id={"content_frame"}
                // ref={printableComponentRef}
                // onKeyDown={handleKeyDown}
            >
                <RichTextContainer id={"container"} className={"ql-placeholder"}>
                    <div>
                        <p>Select a document to preview</p>
                    </div>
                </RichTextContainer>

            </DocumentContentFrame>
        )

    }


}

Document.propTypes = {
    title: PropTypes.string,
    setTitle: PropTypes.func,

    currentDocument: PropTypes.object,

    isMenuClosed: PropTypes.bool,
    openMenu: PropTypes.func,
    onClick: PropTypes.func,
    handleSaveDocument: PropTypes.func,
    // handlePublishDocument: PropTypes.func,
}

const KEYCODE = {
    BACKSPACE: 8,
    ENTER: 13,
    ARROW_UP: 38,
    ARROW_DOWN: 40,
    DELETE: 46
}
const SOURCE = {
    USER: "user",
    API: "api",
    SILENT: "silent",
    NEW_PAGE: "new_page",

}

const EditorPageViewer = (props) => {

    // const {content} = props;
    const {pages} = useDocumentView();

    useMemo(() => {
        import("react-quill/dist/quill.snow.css")
        import("react-quill/dist/quill.bubble.css")
        import("react-quill/dist/quill.core.css")

        import("./archive/utils/DocumentUtils.js")

    }, []);

    return (
        <>
            {Array.from(pages.entries()).map(([pageId, pageData]) => (
                <QuillEditor
                    key={pageId}
                    page_data={pageData}
                />
            ))}
        </>
    )

}
EditorPageViewer.propTypes = {
    content: PropTypes.string
}



/**
 * QuillEditor which represents a single page with Word-Like behaviour using QuillJs
 * @param page_data
 * @return {JSX.Element}
 * @constructor
 */
const QuillEditor = ({page_data}) => {

    // const [value, setValue] = useState(""); //Used for filling?!

    const viewProvider = useDocumentView()
    const {currentPage, pages, utils} = viewProvider;

    const quillRef = useRef(null);

    const [showGrid, setShowGrid] = useState(true);
    const showGridString = `
        repeating-linear-gradient(0deg, transparent, transparent 18px, grey 1mm, grey 5mm),
        repeating-linear-gradient(90deg, transparent, transparent 16px, grey 1mm, grey 4.5mm)
    `;
    const showBorderString = `
        solid grey 0.5px
    `;

    // useEffect(() => {
    //
    //     console.log(value)
    //
    // }, [value])

    useMemo(() => {
        // console.log("Init QuillEditor", page_data)

        return () => {
            if(quillRef.current){
                quillRef.current.editor.removeEventListener('contextmenu')
                quillRef.current.editor.removeEventListener('click')
            }
        }

    }, []);

    useEffect(() => {
        if (quillRef.current) {
            utils.pages.setRef(quillRef);

            console.log(quillRef)
            let editor = quillRef.current.editor;

            editor.root.addEventListener('click', (event) => {
                let tooltipContainer = editor.container.querySelector(".ql-tooltip");
                if(!tooltipContainer.className.includes("ql-hidden")){
                    tooltipContainer.classList.add("ql-hidden")
                }
            })

            editor.root.addEventListener('contextmenu', (event) => {

                event.preventDefault()

                let tooltipContainer = editor.container.querySelector(".ql-tooltip");

                if(tooltipContainer.className.includes("ql-hidden")){
                    tooltipContainer.classList.remove("ql-hidden")

                }

                let editor_dimensions = editor.root.getBoundingClientRect();
                let tooltip_dimensions = tooltipContainer.getBoundingClientRect();

                let dimensions = {
                    top: event.pageY - editor_dimensions.top + 10,
                    left: event.pageX - editor_dimensions.left - tooltip_dimensions.width/2
                }

                tooltipContainer.style.top = dimensions.top+"px";
                tooltipContainer.style.left = dimensions.left+"px";
            })


            // console.log("Setting REF... Page-Data: ", page_data)

            // viewProvider.utils.pages.setCurrentPage(page_data)
        }
    }, [quillRef]);

    useEffect(() => {
        // console.log("PAAAAGES", pages)
        //IF page 0 was loaded
        let firstPage = pages[0];
        if(firstPage.page_ref !== null){
            console.log("Setting Page 0 as currentPage in ViewProvider", firstPage)
            console.log(page_data)
            console.log(firstPage)
            viewProvider.utils.pages.setCurrentPage(firstPage)
        }

    }, [pages])

    useEffect(() => {
        // console.log("Page-DATA", page_data)

        //Fill with overflowing-content after NewPage was created
        if (page_data.page_ref !== null && page_data.content !== null) {
            //INSERT CONTENT
            let quillRef = page_data.page_ref;
            quillRef.getEditor().setContents(page_data.content, "user")
            if (page_data.focus) {
                // console.log("Focus?")
                quillRef.getEditor().focus();
            }
        }

    }, [page_data]);

    const handleEditorChange = (editorRef, content, delta, source) => {}

    const handleKeyDown = (editorRef, event) => {}
    const handleKeyUp = (editorRef, event) => {}

    return (
        <ReactQuill
            id={page_data.page_id}
            ref={quillRef}

            theme={"bubble"}
            modules={viewProvider.editorModules}

            // value={value}

            onFocus={(event) => {
                viewProvider.utils.pages.setCurrentPage(page_data)
            }}
            onChange={(content, delta, source, unprivilegedEditor) => {
                // setValue(content, delta, source, unprivilegedEditor)
                handleEditorChange(quillRef, content, delta, source)

                if(unprivilegedEditor.getSelection()){
                    const cursorPosition = unprivilegedEditor.getSelection().index;
                    const editorElement = quillRef.current.getEditor().root;
                    const range = unprivilegedEditor.getBounds(cursorPosition);

                    let pos = range.top - editorElement.clientHeight / 2;
                    console.table(range)
                    console.table(editorElement.clientHeight, editorElement.clientHeight/3)
                    let elem = document.getElementById('content_frame')
                    if(editorElement.clientHeight/3 < range.top) {
                        elem.scrollTop = range.bottom
                    }

                }


            }}
            onKeyDown={(event) => {
                handleKeyDown(quillRef, event)
            }}
            onKeyUp={(event) => {
                handleKeyUp(quillRef, event)
            }}
            scrollingContainer={"#content_frame"}
        >
        </ReactQuill>
    )

}
QuillEditor.propTypes = {
    page_data: PropTypes.object,
    // content: PropTypes.object
}

