import styled from "styled-components";

export const MainContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    border-top: 2px solid grey;
`;

export const Container = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    
    border-top: 2px solid grey;
`;



export const InfoContainer = styled.div`
    display: grid;
    align-items: center;
    grid-gap: 12px;
    grid-template-columns: 40% auto max-content;
    //border: 1px solid red;
    //padding: 6px;
    margin: 1em;
`;

export const TxHistoryContainer = styled.div`
    margin: 1em;
    display: flex;
    flex-direction: column;
    
    > div{
        //border: 1px solid grey;
        //border-radius: 6px;
    }
`;

export const TxHistoryEntry = styled.div`
    display: grid;
    grid-template-columns: max-content max-content 360px auto;
    flex-direction: row;
    gap: 6px;
    
    
    
`;

export const GasTxHistoryEntry = styled.div`
    position: relative;
    height: 80px;
    //width: 200px;
    //margin-left: 2em;
    margin-top: 3em;
    margin-bottom: 2em;
    
    display:grid;
    grid-template-columns: 80% auto;
    
    div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    div:first-child{
        border: 1px solid grey;
        border-radius: 6px;
    }
    
`;
