import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import {Facebook, Instagram, LinkedIn, Twitter,} from '@mui/icons-material';
import XIcon from '@mui/icons-material/X';

export function FollowButton({twitter, facebook, linkedIn, instagram}){

    const links = {
        TWITTER: "https://twitter.com/intent/follow?screen_name=CryptagoEU",
        FACEBOOK: "#",
        LINKEDIN: "#",
        INSTAGRAM: "https://www.instagram.com/cryptago/"
    }

    const renderTwitterButton = () => {
        return (
            <div>
                <a href={links.TWITTER} target="_blank"
                   rel="noreferrer">
                    <Twitter/>
                </a>
            </div>
        )
    }

    const renderXButton = () => {
        return (
            <div>
                <a href={links.TWITTER} target="_blank"
                   rel="noreferrer">
                    <XIcon/>
                </a>
            </div>
        )
    }
    const renderFacebookButton = () => {
        return (
            <div>
                <a href={links.FACEBOOK} target="_blank"
                   rel="noreferrer"><Facebook/></a>
            </div>
        )
    }
    const renderLinkedInButton = () => {
        return (
            <div>
                <a href={links.FACEBOOK} target="_blank"
                   rel="noreferrer"><LinkedIn/></a>
            </div>
        )
    }
    const renderInstagramButton = () => {
        return (
            <div>
                <a href={links.INSTAGRAM} target="_blank"
                   rel="noreferrer"><Instagram/></a>
            </div>
        )
    }

    return (
        <FollowButtonDiv>
            {
                (twitter) ? renderTwitterButton() : <></>
            }
            {
                (linkedIn) ? renderLinkedInButton() : <></>
            }
            {
                (facebook) ? renderFacebookButton() : <></>
            }
            {
                (instagram) ? renderInstagramButton() : <></>
            }
        </FollowButtonDiv>
    )

}

FollowButton.propTypes = {
    twitter: PropTypes.bool,
    facebook: PropTypes.bool,
    linkedIn: PropTypes.bool,
    instagram: PropTypes.bool
}

export const FollowButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center
`;

const FollowButtonDiv = styled.div`
    
    display: flex;
    flex-direction: column;
    
    
    > div{
        
        height: 32px;
        width: 32px;
        //border: 1px solid white;
        
        display: flex;
        justify-content: center;
        align-items: center;
        
        svg{
            fill: white;
        }
        
    }
    
`;

