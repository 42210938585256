import styled from "styled-components";

export const RootComponent = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  
  --document-header-height: 80px;
  overflow: hidden;
`;


