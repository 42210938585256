
const strings = {
    DIARY_TITLE: "Web3-Docs",
    DIARY_LOCKED: "Your workspace is locked.",
    LOCK_IDENTITY_EXIST: "Enter your password to unlock it.",
    LOCK_IDENTITY_NOT_EXIST: "Enter a password in order to create your own personal diary.",

    PUBLISHER: "by Cryptago",

    DOCUMENT_TITLE_PLACEHOLDER: "A well chosen descriptive title is more likely to be read than a short one",
    DOCUMENT_PUBLISH_DESCRIPTION: `
            You are one step away from publishing this document. 
            Feel free to categorize your document and dont forget to give it a title.  
            
    `,
    DOCUMENT_PUBLISH_DESCRIPTION_WARNING: `
        Once this document is once published it is completely public readable. This step is not reversible
    `
}

const Diary = {
    strings: strings
}

export default Diary;