import styled from "styled-components";

export const FollowDot = styled.div`
    display: flex;
    height: 100%;
    font-size: 2em;
  line-height: 0.4;
`;

export const AccountSettingsDiv = styled.div`
  
`