import React, {createContext, useContext} from "react";
import PropTypes from "prop-types";

const IPFSContext = createContext();

/**
 * @deprecated
 * @return {unknown}
 */
export const useIPFS = () => {
    return useContext(IPFSContext);
};

/**
 * @deprecated
 */
export const IpfsProvider = ({ children }) => {

    const ipfs_endpoint = "http://127.0.0.1:3001/ipfs/document"
    const gateway_endpoint = "https://web3.cryptago.eu/ipfs/"

    const ipfs = {

        saveDocument: async(auth_address, workspace_address, {title, tags, id, content}) => {
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // Set the content type based on your API requirements
                    'Authorization': `Bearer ${auth_address}`, // Include the authorization header
                },
                body: JSON.stringify({
                    auth_address: auth_address,
                    workspace_address: workspace_address,
                    document_data: {
                        title: title,
                        tags: tags,
                        id: id,
                        content: content
                    }
                })
            };

            try{

                const response = await fetch(ipfs_endpoint+"/save", options);

                if(response.status === 200){
                    return response;
                }else{
                    return false;
                }
            }catch(error){
                console.error(error.message)
            }

            //
            // try {
            //     // Make the fetch request
            //     const response = await fetch(ipfs_endpoint, options);
            //     // Check for errors in the response
            //     if (!response.ok) {
            //         console.warn(response)
            //         const res = await response.json();
            //         console.log(res)
            //         throw new Error(`HTTP error! Status: ${response.status}`);
            //     }
            //     // Parse the response as JSON
            //     const data = await response.json();
            //     // Handle the successful response data
            //     console.log('Data:', data);
            //     // Perform further processing or update UI as needed
            // } catch (error) {
            //     // Handle errors that occurred during the fetch or processing
            //     console.error('Error:', error.message);
            //     // Perform error-specific handling or show user-friendly messages
            // }


        },
        editDocument: async() => {

        },


        getDocumentCID: async(auth_address, root_address, document_id) => {
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // Set the content type based on your API requirements
                    'Authorization': `Bearer ${auth_address}`, // Include the authorization header
                },
                body: JSON.stringify({
                    auth_address: auth_address,
                    workspace_address: root_address,
                    document_id: document_id
                })
            };

            try {
                // Make the fetch request
                const response = await fetch(ipfs_endpoint+"/cid", options);
                // Check for errors in the response
                if (!response.ok) {
                    console.warn(response)
                    const res = await response.json();
                    console.log(res)
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                // Parse the response as JSON
                const data = await response.json();
                // Handle the successful response data
                console.log('Data:', data);
                return data.cid;
                // Perform further processing or update UI as needed
            } catch (error) {
                // Handle errors that occurred during the fetch or processing
                console.error('Error:', error.message);
                // Perform error-specific handling or show user-friendly messages
            }
        },

        getDocument: async(path) => {
            console.log("GetDocumentIPFS", path)
            try {
                // Make the fetch request
                const response = await fetch(gateway_endpoint + path);
                console.log("IPFS Response", response)
                // Check for errors in the response
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                // Parse the response as JSON
                const data = await response.text();

                // Handle the successful response data
                console.log('Data:', data);
                // Perform further processing or update UI as needed
                return data;
            } catch (error) {
                // Handle errors that occurred during the fetch or processing
                console.error('Error:', error.message);
                // Perform error-specific handling or show user-friendly messages
            }
        }

    }

    // Provide the IPFS instance through the context
    return (
        <IPFSContext.Provider value={ipfs}>
            {children}
        </IPFSContext.Provider>
    );
};

IpfsProvider.propTypes = {
    children: PropTypes.node,
}