import styled, {useTheme} from "styled-components";
import PropTypes from "prop-types";
import React, {useState} from "react";

import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import TwitterIcon from "@mui/icons-material/Twitter";

import FingerprintIcon from '@mui/icons-material/Fingerprint';

import EastIcon from '@mui/icons-material/East';
import {useLanguageService} from "../../language/LanguageProvider";
import Image from "../../components/common/imageFetcher/Image";
import {CircularProgress} from "@mui/material";

import MetaMask_SVG from "../assets/images/provider/metamask.svg";
import Trezor_SVG from "../assets/images/provider/trezor.svg";
import Binance_SVG from "../assets/images/provider/binance.svg";
import WalletConnect_SVG from "../assets/images/provider/walletconnect.svg";
import MyEtherWallet_SVG from "../assets/images/provider/myetherwallet.svg";

const AppItemDiv = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  
    width: 50px;
    //height: 50px;
  
    color: #3e3e3e;
    border-radius: 12px;
    padding: 12px;
      
    &[data-connect-wallet='true'] {
      //background-color: grey;
      border: 1px solid grey;
      border-radius: 12px;
      color: #3e3e3e;
      width: unset;

      :hover{
        background-color: grey;
        color: white;
      }
      
      label{
	font-size: 1em;
	pointer-events: none;
      }
      @media (max-width: 768px) {
	border-radius: 6px;
        //color: #a9a9a9;
	background-color: lightgrey;
      }
    }
  
    .iconDiv{
      //width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      span{
	color: grey;
	font-size: 0.8em;
      }
    }

    & div{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      aspect-ratio: 1/1;
       
      //background-color: grey;
      border-radius: 50%;
    }
  
    & div > img{
      width: 42px;
      height: 42px;
      margin: 6px 0;
      //background-color: grey;
      //border-radius: 50%;
      object-fit: contain;
      object-position: center;
    }
    & label{
      font-size: 12px;
      line-height: 16px;
    /*font-weight: 600;*/
      white-space: nowrap;
    }
    &[disabled]{
      opacity: 0.5;
      cursor: unset;
      pointer-events: none;
    }

    cursor: ${props => props.onClick ? 'pointer' : 'unset'};
`;

const AppItem = ({
    imgSrc,
    name,
    disabled=false,
    background=false,
    icon,
    onClick,
    showName=true,
    style,
    onMouseEnter,
    onMouseLeave
}) => {
    if(icon){
	return(
	    <AppItemDiv
		onClick={onClick}
		onMouseEnter={onMouseEnter}
		onMouseLeave={onMouseLeave}
		style={{flexDirection: "row", gap: "6px", ...style}}
		disabled={disabled}
		data-connect-wallet
	    >
		{icon}
		{showName ? <label>{name}</label> : <></>}
	    </AppItemDiv>
	)
    }else{
	return(
	    <AppItemDiv disabled={disabled} onClick={onClick} style={style}>
		<div style={{backgroundColor: (background ? "grey" : "unset")}}>
		{
		    (style) ?
			<img src={imgSrc} alt={name} style={style?.imgStyle? style.imgStyle : ""}/>
			:
			<img src={imgSrc} alt={name} />
		}
		</div>
		{showName ? <label>{name}</label> : <></>}
	    </AppItemDiv>
	)
    }
}
AppItem.propTypes = {
    name: PropTypes.string,
    imgSrc: PropTypes.string,
    icon: PropTypes.node,
    disabled: PropTypes.bool,
    showName: PropTypes.bool,
    background: PropTypes.bool,
    onClick: PropTypes.func,
    style: PropTypes.object,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func
}

let Provider = {
    METAMASK: "Metamask",
    TREZOR: "Trezor",
    BINANCE: "Binance Chain",
    WALLETCONNECT: "WalletConnect",
    MEW: "MEW"
}

export const Web3App = ({onClick, provider, disabled, showName, background, style}) => {
    // const metaMaskSVG = "https://raw.githubusercontent.com/MetaMask/brand-resources/master/SVG/metamask-fox.svg";

    let imgSVG;

    switch (Provider[provider.toUpperCase()]){
		// case Provider.METAMASK: imgSVG = "https://raw.githubusercontent.com/MetaMask/brand-resources/master/SVG/SVG_MetaMask_Icon_Color.svg"; break;
		case Provider.METAMASK: imgSVG = MetaMask_SVG; break;
		case Provider.TREZOR: imgSVG = Trezor_SVG; break;
		case Provider.BINANCE: imgSVG = Binance_SVG; break;
		case Provider.WALLETCONNECT: imgSVG = WalletConnect_SVG; break;
		case Provider.MEW: imgSVG = MyEtherWallet_SVG; break;
		default: imgSVG = MetaMask_SVG;
    }

    return (
	<AppItem
	    showName={showName}
	    name={Provider[provider.toUpperCase()]}
	    imgSrc={imgSVG}
	    onClick={onClick}
	    disabled={disabled ? disabled : false}
	    background={background ? background : false}
	    style={style}
	/>
    )
}
Web3App.propTypes = {
    disabled: PropTypes.bool,
    showName: PropTypes.bool,
    background: PropTypes.bool,
    onClick: PropTypes.func,
    style: PropTypes.object,
    provider: PropTypes.oneOf(["metamask", "trezor", "walletconnect", "binance", "mew"])
}


export const ConnectWalletButton = ({onClick}) => {
    return(
	<AppItem
	    name={"Connect Wallet"}
	    icon={<AccountBalanceWalletIcon/>}
	    onClick={onClick}
	/>
    )
}
ConnectWalletButton.propTypes = {
    onClick: PropTypes.func
}

export const FollowButton = ({onClick}) => {

    const {landingEarlyAccess} = useLanguageService();
    const theme = useTheme();

    const [backgroundColor, setBackgroundColor] = useState("white")
    const hoverEnter = () => {setBackgroundColor("lightgrey")}
    const hoverLeave = () => {setBackgroundColor("white")}

    const twitterDomain = "@CryptagoEU"

    return(
	<AppItem
	    name={landingEarlyAccess.FOLLOW+twitterDomain}
	    icon={<TwitterIcon/>}
	    onClick={onClick}
	    style={{
		// border: "unset",
		backgroundColor: backgroundColor,
		color: theme.global.base,
		padding: "10px 0",
		width: "100%"
	}}
	    onMouseEnter={hoverEnter}
	    onMouseLeave={hoverLeave}
	/>
    )
}
FollowButton.propTypes = {
    onClick: PropTypes.func
}

export const CreateSignUpCodeButton = ({onClick, loading, referalCode}) => {

    const {landingEarlyAccess} = useLanguageService();
    const theme = useTheme();

    const [backgroundColor, setBackgroundColor] = useState("white")
    const hoverEnter = () => {setBackgroundColor("lightgrey")}
    const hoverLeave = () => {setBackgroundColor("white")}

    if(loading){

	if(referalCode){
	    return(
		<AppItem
		    name={landingEarlyAccess.FINISH_REGISTER_CODE}
		    icon={<CircularProgress color="inherit"/>}
		    onClick={onClick}
		    style={{
			// border: "unset",
				backgroundColor: backgroundColor,
				color: theme.global.base,
				padding: "10px 0",
				width: "100%"
		    }}
		    onMouseEnter={hoverEnter}
		    onMouseLeave={hoverLeave}
		/>
	    )
	}

	return(
	    <AppItem
		name={landingEarlyAccess.GENERATE_REGISTER_CODE}
		icon={<CircularProgress color="inherit"/>}
		onClick={onClick}
		style={{
		    // border: "unset",
		    backgroundColor: backgroundColor,
		    color: theme.global.base,
		    padding: "10px 0",
		    width: "100%"
		}}
		onMouseEnter={hoverEnter}
		onMouseLeave={hoverLeave}
	    />
	)
    }else{
	if(referalCode){
	    return(
		<AppItem
		    name={landingEarlyAccess.VERIFY_REGISTER_CODE+": "+referalCode}
		    icon={<FingerprintIcon/>}
		    onClick={onClick}
		    style={{
			// border: "unset",
			backgroundColor: backgroundColor,
			color: theme.global.base,
			padding: "10px 0",
			width: "100%"
		    }}
		    onMouseEnter={hoverEnter}
		    onMouseLeave={hoverLeave}
		/>
	    )
	}
	return(
	    <AppItem
		name={landingEarlyAccess.CREATE_REGISTER_CODE}
		icon={<FingerprintIcon/>}
		onClick={onClick}
		style={{
		    // border: "unset",
		    backgroundColor: backgroundColor,
		    color: theme.global.base,
		    padding: "10px 0",
		    width: "100%"
		}}
		onMouseEnter={hoverEnter}
		onMouseLeave={hoverLeave}
	    />
	)
    }


}
CreateSignUpCodeButton.propTypes = {
    onClick: PropTypes.func,
    loading: PropTypes.bool,
    referalCode: PropTypes.string
}

export const SwitchNetworkButton = ({onClick, from, to}) => {

    const {landingEarlyAccess} = useLanguageService();
    const theme = useTheme();

    const [backgroundColor, setBackgroundColor] = useState("white")
    const hoverEnter = () => {setBackgroundColor("lightgrey")}
    const hoverLeave = () => {setBackgroundColor("white")}

    return(
	<AppItem
	    // name={landingEarlyAccess.SWITCH_NETWORK}
	    icon={
	    	<div style={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%", justifyContent: "space-evenly", aspectRatio: "unset"}}>
		    <div className={"iconDiv"}>
			<img src={Image.getImageSource(from.chainId)} style={{width: "100%", backgroundColor: "unset"}} alt={"chainId_"+from.chainId}/>
			<span>{from.name}</span>
		    </div>

		    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
			<EastIcon/>
			<label>{landingEarlyAccess.SWITCH_NETWORK}</label>
		    </div>
		    {/*<label>{landingEarlyAccess.SWITCH_NETWORK}</label>*/}

		    <div className={"iconDiv"}>
			<img src={Image.getImageSource(to.chainId)} style={{width: "100%", backgroundColor: "unset"}} alt={"chainId_"+from.chainId}/>
			<span>{to.name}</span>
		    </div>
		</div>
	    }
	    onClick={onClick}
	    style={{
		flexDirection: "column",
		// border: "unset",
		backgroundColor: backgroundColor,
		color: theme.global.base,
		padding: "10px 0",
		width: "100%",
		gap: 0,
		fontSize: "0.8em",
		fontWeight: "600"
	    }}
	    onMouseEnter={hoverEnter}
	    onMouseLeave={hoverLeave}
	/>
    )
}
SwitchNetworkButton.propTypes = {
    onClick: PropTypes.func,
    from: PropTypes.object,
    to: PropTypes.object,
}

export default AppItem;