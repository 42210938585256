import React, {createContext, useContext, useMemo, useState} from "react";
import PropTypes from "prop-types";

export const LANG = {
    en_US: { enc: "en_US", emoji: "🇺🇸", unified: "1f1fa-1f1f8",  label: "English"},
    de_DE: { enc: "de_DE", emoji: "🇩🇪", unified: "1f1e9-1f1ea", label: "Deutsch"},

}
const LanguageContext = createContext(undefined);

export function LanguageProvider({children, enc}) {

    const [language, setLanguage] = useState((enc ? enc : LANG.en_US));
    const [languageModel, setLanguageModel] = useState(null);

    const [languageLanding, setLanguageLanding] = useState(null);
    const [languagePR, setLanguagePR] = useState(null);

    const [languageFAQ, setLanguageFAQ] = useState(null);
    const [languageContact, setLanguageContact] = useState(null);

    const [languageEarlyAccess, setLanguageEarlyAccess] = useState(null);
    const [languageErrorTexts, setLanguageErrorTexts] = useState(null);

    const [languagePrivacy, setLanguagePrivacy] = useState(null);
    const [languageTerms, setLanguageTerms] = useState(null);

    const langImport = (language) => {
	console.log("%c ----- LanguageProvider ----- Init", "font-size: medium")
	console.log(language)
	import("./"+language.enc+"/string").then((content) => {
	    setLanguageModel(content.strings)
	    setLanguageLanding(content.landing)
	    setLanguagePR(content.PR)
	    setLanguageFAQ(content.FAQ)
	    setLanguageContact(content.Contact)
	    setLanguageEarlyAccess(content.EarlyAccess)
		setLanguageErrorTexts(content.ErrorTexts)
	    console.log("%c Using language: "+ language.enc, "font-size: small")
	}).catch((error) => {
	    console.warn(error)
	})
    }

    const changeLanguage = (lang) => {
	if(Object.keys(LANG).includes(lang.enc)){
	    setLanguage(lang);
	    console.log("Change language", lang)
	    langImport(lang);
	}else{
	    console.warn("Does not have", lang)
	}
	// (langType) => {(LANG.hasOwnProperty(langType)) ? setLanguage(langType) : Error(langType+" not supported. Supported languages "+LANG)},
    }

    useMemo(()=> {
	if(languageModel === null){ langImport(language); }
    }, [])

    if(languageModel !== null){
	console.log("%c ----- LanguageProvider ----- Init finished", "font-size: medium");
	return(
	    <LanguageContext.Provider
		value={{
		    changeLanguage,
		    availableLanguages: Object.values(LANG),
		    selectedLanguage: language,
		    strings: languageModel,
		    landing: languageLanding,
		    landingPR: languagePR,
		    landingFAQ: languageFAQ,
		    landingContact: languageContact,
		    landingEarlyAccess: languageEarlyAccess,
			errorTexts: languageErrorTexts
		}}
	    >
		{children}
	    </LanguageContext.Provider>

	)
    }else{
	console.log("%c Loading LanguageProvider", "font-size: small")

	// return(
	//     <LoadingSpinner title={"Loading"}/>
	// )
    }
}

LanguageProvider.propTypes = {
    children: PropTypes.node,
    enc: PropTypes.object
}

export function useLanguageService() {
    const context = useContext(LanguageContext);
    if (!context) throw Error('useLanguageService can only be used within the LanguageProvider component')
    return context;
}