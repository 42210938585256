import React from 'react';
import styled, {useTheme} from "styled-components";
import {useLanguageService} from "../language/LanguageProvider";
import Logo from "../components/common/logo/Logo";
import {MenuItem, Select} from "@mui/material";

import {Emoji} from 'emoji-picker-react';

import TwitterIcon from '@mui/icons-material/Twitter';
import {useNavigate} from "react-router-dom";

function Footer(props) {

    const {availableLanguages, changeLanguage, selectedLanguage, strings} = useLanguageService();
    const navigate = useNavigate();

    const theme = useTheme()
    // console.log(theme)

    const handleLanguageChange = (event) => {
        console.log(event.target.value)
        changeLanguage(event.target.value)
    };

    return (
        <NavFooter>
            <NavFooterContentWrapper>
                <NavFooterContent>
                    <NavFooterSection>
                        <header>About Cryptago</header>
                        <ul>
                            <li>
                                <a>{strings.OUR_VISION}</a>
                            </li>
                            <li>
                                <a href={"/about"}>{strings.ABOUT_US}</a>
                            </li>
                            <li><a href={"/legal/imprint"}>{strings.IMPRINT}</a></li>
                            <li style={{display: "flex", gap: "6px"}}>
                                <a href={"https://twitter.com/intent/follow?screen_name=CryptagoEU"} target="_blank"
                                   rel="noreferrer"><TwitterIcon/></a>
                                {/*<a href={""}><LinkedInIcon/></a>*/}
                                {/*<a href={""}><FacebookIcon/></a>*/}
                                {/*<a href={""}><YouTubeIcon/></a>*/}
                            </li>
                        </ul>
                    </NavFooterSection>
                    <NavFooterSectionSpacer/>
                    {/*<NavFooterSection>*/}
                    {/*<header>Need help?</header>*/}
                    {/*</NavFooterSection>*/}
                    {/*	<NavFooterSectionSpacer/>*/}
                    {/*<NavFooterSection>*/}
                    {/*<header>Need help?</header>*/}
                    {/*</NavFooterSection>*/}
                </NavFooterContent>
            </NavFooterContentWrapper>
            <hr/>
            <NavFooterContentWrapper style={{padding: "10px 0 10px 0"}}>
                <NavFooterContent
                    style={{display: "flex", alignItems: "center", justifyContent: "center", gap: "36px"}}>
                    <div className={"footer_logo"} onClick={() => {
                        navigate("/")
                    }}>
                        <Logo logoType={3} style={{width: "150px"}} />
                    </div>
                    <Select
                        sx={{
                            '& .MuiSelect-select': {
                                display: "flex",
                                alignItems: "center",
                                gap: "12px",
                                height: "20px",
                                width: "120px",
                                padding: "6px 14px"
                            }
                        }}
                        style={{width: "max-content", display: "flex"}}
                        value={selectedLanguage}
                        onChange={handleLanguageChange}
                    >
                        {
                            availableLanguages.map((value, key) => {
                                return (
                                    <MenuItem key={key} value={value} style={{display: "flex", gap: "12px"}}>
                                        <Emoji unified={value.unified}/>
                                        {value.label}
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </NavFooterContent>

            </NavFooterContentWrapper>
            <hr/>
            <NavFooterContentWrapper style={{padding: "6px 0", fontSize: "14px"}}>

                <NavFooterContent style={{textAlign: "center"}}>

                    <NavFooterSection style={{display: "block", maxWidth: "unset", paddingBottom: "6px"}}>
                        <ul className={"navLinks"}>
                            <li><a href={"/legal/terms"}>{strings.TERMS_OF_USE}</a></li>
                            <li><a href={"/legal/privacy"}>{strings.PRIVACY}</a></li>
                            <li><a href={"/legal/imprint"}>{strings.IMPRINT}</a></li>
                        </ul>
                        <span>{strings.COPYRIGHT}</span>
                    </NavFooterSection>

                </NavFooterContent>

            </NavFooterContentWrapper>
        </NavFooter>
    )

}

const NavFooter = styled.footer`
  position: relative;
  z-index: 100;
  width: 100%;
  background-color: ${(props) => props.theme.palette.navPanel};
  color: ${(props) => props.theme.palette.textColor};
`;
const NavFooterContentWrapper = styled.div`
  //padding: 40px 0;
  padding-top: 40px;
  max-width: 1000px;
  margin: 0 auto;

  display: table;

  @media screen and (max-width: 600px) {
    max-width: 90%;
    display: grid;
  }
`;
const NavFooterContent = styled.div`

  display: table-row;
  box-sizing: border-box;

  @media screen and (max-width: 600px) {
    display: table-row;

    & div[class="footer_logo"] img {
      width: 120px !important;
    }

  }
`;
const NavFooterSection = styled.section`
  display: table-cell;
  padding: 0 10px;
  min-width: 200px;

  header {
    margin: 6px 0 14px 0;
    font-weight: 700;
  }

  ul {
    padding: 0;
    margin: 0;
  }

  ul[class="navLinks"] {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    padding-bottom: 6px;

    & > li {
      margin: 0;
    }
  }

  ul, li {
    margin: 0 0 10px;
    list-style-type: none;
  }

  li > a {
    text-decoration: none;
    color: ${(props) => props.theme.palette.textColor};

    //padding: 0 0.6em;

    &:-webkit-any-link {
      color: ${(props) => props.theme.textColor};
      cursor: pointer;

      :hover {
        text-decoration: underline;
      }
    }
  }

  @media screen and (max-width: 600px) {
    min-width: unset;
    max-width: 80px;
    font-size: 0.8em;

  }

`;
const NavFooterSectionSpacer = styled.div`
  display: table-cell;
  width: 10%;
`;

export default Footer;