import styled from "styled-components";

export const TileDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  
    border-radius: 12px;
    cursor: pointer;
    //width: 100px;
    background-color: darkgrey;
    color: white;
    
  	font-size: 0.9em;
  //padding: 0 6px;
  
    network-desc, wallet-desc{
      
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 6px;
	padding: 0.1em 1em;
	
	//color: white;
	
	img{
	    width: 2em;
	    height: 2em;
	}
    }

    wallet-desc{
      	letter-spacing: 0.1em;
    }

    @media (max-width: 768px){
    	font-size: 0.8em;
    	border-radius: 6px;
    }
`;