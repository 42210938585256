import React, {useEffect, useMemo, useState} from 'react';
import Footer from "../../branding/Footer";
import Navbar from "../landing/components/navbar/Navbar";

import styles from "../landing/Landing.module.css"
import {useLocation, useNavigate} from "react-router-dom";
import ReactMarkdown from "react-markdown";
import {useLanguageService} from "../../language/LanguageProvider";
import NotFoundPage from "../_fallback/NotFound";

import "./Legal.css";

const SITE = {
    PRIVACY: "privacy",
    TERMS: "terms",
    IMPRINT: "imprint"
}

function Legal(){

    const location = useLocation();
    const navigate = useNavigate()

    const [site, setSite] = useState(SITE.TERMS);
    const [lastUpdate, setLastUpdate] = useState(new Date());

    const {strings, privacy, terms, selectedLanguage} = useLanguageService();

    const [textMarkdown, setTextMarkdown] = useState("");

    const getDateString = (date) => {
	let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
	return date.toLocaleDateString(selectedLanguage.enc.replace("_", "-"), options)
    }

    const loadText = (site) => {
	const md_path = require("../../language/"+selectedLanguage.enc+"/legal/"+site+".md");
	console.log(md_path)
	fetch(md_path).then((response) => {
	    return response.text();
	}).then((text) => {
	    setTextMarkdown(text);
	}).catch((error) => {
	    console.error(error)
	})
    }

    const handleChange = (event, value) => {
	event.preventDefault();
	navigate("/legal/"+event.target.name)
    }

    useMemo(()=> {
	if(location.pathname === "/legal/terms"){
	    setLastUpdate(new Date("2023-06-27"))
	    setSite(SITE.TERMS)
	    loadText(SITE.TERMS)
	}else if(location.pathname === "/legal/privacy"){
	    setLastUpdate(new Date("2023-06-27"))
	    setSite(SITE.PRIVACY)
	    loadText(SITE.PRIVACY)
	}else if(location.pathname === "/legal/imprint"){
	    setLastUpdate(new Date("2023-06-27"))
	    setSite(SITE.IMPRINT)
	    loadText(SITE.IMPRINT)
	    // console.log("Navigate")
	    // navigate("/legal/terms")
	    // // loadText(site)
	}
    }, [location, selectedLanguage])

    useEffect(() => {

	console.log(location.pathname)
	if(
	    location.pathname !== "/legal/terms" 	&&
	    location.pathname !== "/legal/privacy" 	&&
	    location.pathname !== "/legal/imprint"
	){
	    navigate("/legal/terms")
	}

    }, [])

    if(site === SITE.PRIVACY || site === SITE.TERMS || site === SITE.IMPRINT){
	return(
	    <div className={styles.Top}>
		<Navbar />
		<div className={styles.Landing+" privacy-page"}>

		    <div className={"privacy-container"}>

			<div className={"privacy-links-container"}>
			    <label>Links</label>
			    <ul>
				<li>
				    <a href={"privacy"} name={SITE.PRIVACY} onClick={handleChange} style={{fontWeight: site === SITE.PRIVACY ? "bold" : "normal" }}> {strings.PRIVACY} </a>
				</li>
				<li>
				    <a href={"terms"} name={SITE.TERMS} onClick={handleChange} style={{fontWeight: site === SITE.TERMS ? "bold" : "normal" }}> {strings.TERMS_OF_USE} </a>
				</li>
				<li>
				    <a href={"terms"} name={SITE.IMPRINT} onClick={handleChange} style={{fontWeight: site === SITE.IMPRINT ? "bold" : "normal" }}> {strings.IMPRINT} </a>
				</li>
			    </ul>
			</div>
			<div className={"privacy-content-container"}>
			    <div>
				{
				    (site === SITE.PRIVACY) ? <h2 className={"primary-heading legal-heading"}>{ strings.PRIVACY}</h2> :
				    (site === SITE.TERMS) ? <h2 className={"legal-heading"}>{ strings.TERMS_OF_USE}</h2> :
				    (site === SITE.IMPRINT) ? <h2 className={"legal-heading"}>{ strings.IMPRINT}</h2> : <></>

				}
				<p className={"primary-text legal-text"}>{strings.LAST_UPDATE+": "+getDateString(lastUpdate)}</p>
			    </div>
			    <div className={"privacy-content-section"}>
				<ReactMarkdown>
				    {textMarkdown}
				</ReactMarkdown>
			    </div>
			</div>
		    </div>

		</div>
		<Footer/>
	    </div>
	)
    }else{
	return( <NotFoundPage/> )
    }


}

export default Legal;