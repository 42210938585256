import styled from "styled-components";

/**
 * Left Menubar
 **/
export const Menubar = styled.div`

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
  letter-spacing: 0.15px;

  display: flex;
  flex-direction: column;
  position: relative;

    //min-width: ${(props) => props.isClosed ? "0px" : "300px"};
  //max-width: 300px;
  width: ${(props) => props.isClosed ? "0px" : props.style.width + "px"};
  height: 100%;

  background-color: lightgrey;
  //border-right: 1px solid grey;
  box-shadow: 0 0 24px 0px rgb(0, 0, 0, 0.14);

  transition: ${(props) => props.isClosed ? "width 0.2s" : props.isResizing ? "unset" : "width 0.2s"};

  hr {
    width: 100%;
    color: #212121;
    opacity: 0.2;
  }

  overflow-x: hidden;
    
}

.close-icon-button {
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
  width: 42px;
  height: 80px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;

  svg {
    font-size: 32px;
  }

  :hover {
    background-color: lightgrey;
  }
}

`;

export const MenuDocumentList = styled.div`

    width: 100%;
    overflow-y: auto;

    ul {
        list-style-type: none;
        padding-left: 0;
        line-break: auto;
    }

    li {
        
        &.selected{
            color: white !important;
            background-color: ${props => props.theme.global.main} !important;
        }
        
        min-height: 28px;
        height: min-content;
        padding: 6px 16px 6px 28px;
        cursor: pointer;
        
        &:hover {
            color: black;
            background-color: ${(props) => (props.hover ? "lightgrey" : null)};;
        }

        div {
            //height: 100%;
            min-height: 28px;
            display: flex;
            column-gap: 32px;
            align-items: center;
            //pointer-events: none;

            svg{
                font-size: 1.8em;
            }
            label {
                //line-height: 1.4;
                pointer-events: none;
                font-size: 14px;
                user-select: none;
            }

            button {
                height: 40px;
                background-color: ${props => props.theme.global.base};
                border: unset;
                color: white;
                cursor: pointer;

                :hover {
                    background-color: ${props => props.theme.global.auxiliary_1};
                }
            }

        }

        div[class="document_init"] {
            opacity: 0.5;
        }

        div[class="document_init"]:after {
            content: " ";
            display: block;
            position: relative;
            //top: -19.5px;
            width: 12px;
            height: 12px;
            //margin: 8px;
            border-radius: 50%;
            border: 3px solid #000;
            border-color: ${(props) => props.theme.global.base} transparent ${(props) => props.theme.global.base} transparent;
            animation: lds-dual-ring 2.5s ease-in-out infinite;
        }

        @keyframes lds-dual-ring {
            0% {
                transform: rotate(0deg);
            }
            25% {
                transform: rotate(180deg);
            }
            50% {
                transform: rotate(360deg);
            }
            80% {
                transform: rotate(900deg);
            }
            100% {
                transform: rotate(900deg);
            }
        }
    }
    
`;



export const ResizeDragger = styled.div`
  height: 100%;
  cursor: col-resize;
  background-color: grey;

  position: relative;
  width: ${(props) => props.dragger_size + "px"};

  .dragger-icon {
    position: absolute;
    background-color: grey;
    height: 32px;
    width: 16px;
    top: 50%;
    left: ${(props) => (-8 + props.dragger_size / 2) + "px"};

    border-radius: 30%;

    display: flex;
    justify-content: center;
    align-items: center;
  }

`;

export const AccountSwitcher = styled.div`

  width: 100%;

  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;
  padding: 20px 0 0px 0;
  row-gap: 4px;

  & div:nth-child(2) {
    display: flex;
    column-gap: 4px;
    //background-color: #4cd964;
  }

  & div > :where(p) {
    color: #000;
    opacity: 0.6;
  }

`;
export const AvatarImage = styled.div`
  img {
    // width: ${props => props.style?.width? props.style.width : "64px"};
    // height: ${props => props.style?.height? props.style.height : "64px"};
  }
`;
export const BreadCrumbler = styled.div`
  //width: 100%;

  display: flex;
  column-gap: 13px;

  padding-top: 12px;
  padding-left: 10px;

  header {
    font-size: 1.2rem;
  }

  img {
    max-width: unset;
    width: 50px;
    height: 50px;
  }

  ul {
    padding-left: 0;
    margin: 2px;
    display: flex;
    //background-color: #4cd964;
    color: lightgrey;
    //
    list-style-type: " > ";

    li:first-child {
      list-style-type: none;
      margin-left: 0;
    }

    li {
      color: rgba(0, 0, 0, 0.6);
      margin-left: 16px;
      //display: inline-block;
    }

  }

`;