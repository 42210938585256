import styled from "styled-components";

export const EarlyAccessDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    max-width: 700px;
    background-color: lightgrey;    
    //color: white;
    width: 100%;
    //margin-bottom: 3rem;
    padding: 1rem;
    border-radius: 2rem;
    
    margin-top: 1em;
    padding-bottom: 2em;
    
    gap: 16px;
    
    .connect-wallet-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      
      @media (max-width: 768px){
        flex-direction: column;
      }
    }
  
    .wallet-provider-container{
      max-width: 380px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
    }
    .provider-infos{
      display: grid;
      grid-template-columns: 1fr 1fr;
      //height: 30px;
      gap: 12px;
    }
  
    .download{
      display: flex;
      flex-direction: row;
    }
    
    @media (max-width: 768px){
    	flex-direction: column;
    }
`;