import React from 'react';

import "./contact.css";
import {useLanguageService} from "../../../../language/LanguageProvider";

import EarlyAccess from "./onboarding/EarlyAccess";

function Contact(){

    const {landingContact} = useLanguageService();

    return(
	<div className={"contact-section-container"}>
	    <p className={"primary-subheading"}>
		{landingContact.HEADING}
	    </p>
	    <h1 className={"primary-heading"}>{landingContact.SIGN_IN}</h1>


	    <EarlyAccess>
	    </EarlyAccess>

	</div>
    )
}








export default Contact;