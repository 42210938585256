import styled from "styled-components";
import BackgroundImg from "../assets/background_full.png";

export const UnderConstructionContainer = styled.div`
    
    height: 100vh;
    background-image: url(${BackgroundImg});
    background-size: 400vh;
    background-repeat: no-repeat;
    
    & > div{
        height: 100%;
        display: grid;
        //grid-template-rows: auto auto 40%;
        align-content: center;
        justify-items: center;
        gap: 20px;
        color: white;
        
        backdrop-filter: blur(8px);
    
        h1 {
            text-transform: uppercase;
            //word-spacing: 100vw;
            font-size: 2.6em;
            padding-bottom: 1em;
        }
        
        p{
            font-size: 18px; /* Adjust the font size as needed */
            line-height: 1.6; /* Set a comfortable line height */
            margin-bottom: 20px; /* Add spacing between paragraphs */
            //text-align: justify;
        }
    
    }
    
    
    h2 {
        overflow: hidden;
        text-align: center;
        color: white;
    }
    h2:before,
    h2:after {
        background-color: #fff;
        content: "";
        display: inline-block;
        height: 3px;
        position: relative;
        vertical-align: middle;
        width: 80px;
        border-radius: 5px;
    }
    h2:before {
        right: 0.5em;
        margin-left: -50%;
    }
    h2:after {
        left: 0.5em;
        margin-right: -50%;
    }
    
`;