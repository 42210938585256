import React from 'react';

import "./about.css";
import {useLanguageService} from "../../../../language/LanguageProvider";

import NewDocumentImage from "../../../assets/images/MyWorksapce_Init.png";

function About(){

    const {landing, landingPR} = useLanguageService();

    const imglink = "https://picsum.photos/200/300";

    return(
	<div className={"about-section-container"}>
	    <div className={"about-background-image-container"}>
		<img src={NewDocumentImage} alt={""}/>
	    </div>
	    <div className={"about-section-text-container"}>
		<p className={"primary-subheading"}>About</p>
		<h1 className={"primary-heading"}>
		    {landingPR.SLOGAN_2}
		</h1>
		<p className={"primary-text"}>
		    {landingPR.DESCRIPTION_1}
		</p>
		{/*<p className={"primary-text"}>*/}
		{/*    {landingPR.DESCRIPTION_1}*/}
		{/*</p>*/}
		<div className={"about-buttons-container"}>
		    {/*<ExploreButton>*/}
			{/*{landing.BUTTON_LEARN_MORE}*/}
		    {/*</ExploreButton>*/}
		    {/*<button>*/}
			{/*Temp*/}
		    {/*</button>*/}
		</div>
	    </div>
	</div>
    )
}

export default About;
