function Relayer(){

    // const url = "http://127.0.0.1:5001/web3lib-relayer-cryptago/europe-central2/relayer/"
    let url = "";



    // if(window.location.hostname.includes("localhost") || window.location.hostname.includes(("127.0.0.1"))){
    //     // url = "http://127.0.0.1:5001/web3lib-relayer-cryptago/europe-central2/relayer/"
    //     url = "http://127.0.0.1:3030/"
    // }else{
        url = "https://europe-central2-web3lib-relayer-cryptago.cloudfunctions.net/relayer/";
    // }




    const ENDPOINT = {
        connect: "connect",
        confirm: "confirm",
        relayEntry: "submit",
        relayEditEntry: "submit",
        initWorkspace: "workspace",
        document: "document"
    }

    const headers = new Headers({
        "Content-Type": "application/json"
    });

    async function confirm({sender, signature, code}){
        let obj = {
            sender: sender,
            signature: signature,
            code: code
        }
        const relayRequest = new Request(url + ENDPOINT.confirm, {
            method: "POST",
            body: JSON.stringify(obj),
            headers: headers,
            mode: 'cors',
            cache: 'default',
        });
        return fetch(relayRequest);
    }

    async function connect({sender, chainId, domain, uri}){

        let obj = {
            sender: sender,
            chainId: chainId,
            domain: domain,
            uri: uri
        }
        const relayRequest = new Request(url + ENDPOINT.connect, {
            method: "POST",
            body: JSON.stringify(obj),
            headers: headers,
            mode: 'cors',
            cache: 'default',
        });
        return fetch(relayRequest);
    }

    /**
     * @deprecated
     * @param docAddress
     * @param msg
     * @param timestamp
     * @param sender
     * @param signature
     * @return {Promise<Response>}
     */
    async function relayEntry(docAddress, msg, timestamp, sender, signature){
        // const r = signature.slice(0, 66);
        // const s = "0x" + signature.slice(66, 130);
        // const v = parseInt(signature.slice(130, 132), 16);
        // console.log({v,r,s})

        let obj = {
            docAddress: docAddress,
            message: msg,
            timestamp: timestamp,
            sender: sender,
            params: signature.params,
            signature: signature.signature
        }

        const relayRequest = new Request(url + ENDPOINT.relayEntry, {
            method: "POST",
            body: JSON.stringify(obj),
            headers: headers,
            mode: 'cors',
            cache: 'default',
        });
        return fetch(relayRequest);
    }

    async function relayEditEntry(docAddress, msg, editIndex, timestamp, sender, signature){
        // const r = signature.slice(0, 66);
        // const s = "0x" + signature.slice(66, 130);
        // const v = parseInt(signature.slice(130, 132), 16);
        // console.log({v,r,s})

        let obj = {
            docAddress: docAddress,
            message: msg,
            editIndex: editIndex,
            timestamp: timestamp,
            sender: sender,
            params: signature.params,
            signature: signature.signature
        }

        const relayRequest = new Request(url + ENDPOINT.relayEditEntry, {
            method: "POST",
            body: JSON.stringify(obj),
            headers: headers,
            mode: 'cors',
            cache: 'default',
        });
        return fetch(relayRequest);

    }

    async function relayInitWorkspace({sender, root_document, signature}){
        let obj = {
            sender: sender,
            root_document: root_document,
            signature: signature
        }
        const relayRequest = new Request(url + ENDPOINT.initWorkspace, {
            method: "POST",
            body: JSON.stringify(obj),
            headers: headers,
            mode: 'cors',
            cache: 'default',
        });
        return fetch(relayRequest);
    }

    async function relayAddDocument({sender, root_document, document_id, signature}){
        let obj = {
            sender: sender,
            root_document: root_document,
            document_id: document_id,
            signature: signature
        }
        const relayRequest = new Request(url + ENDPOINT.document+"/new", {
            method: "POST",
            body: JSON.stringify(obj),
            headers: headers,
            mode: 'cors',
            cache: 'default',
        });
        return fetch(relayRequest);
    }

    async function relayWriteDocumentContent({sender, doc_address, content, signature}){
        let obj = {
            sender: sender,
            doc_address: doc_address,
            content: content,
            signature: signature
        }
        const relayRequest = new Request(url + ENDPOINT.document+"/save", {
            method: "POST",
            body: JSON.stringify(obj),
            headers: headers,
            mode: 'cors',
            cache: 'default'
        });
        return fetch(relayRequest);
    }

    return {relayEntry, relayEditEntry, connect, confirm, relayInitWorkspace, relayAddDocument, relayWriteDocumentContent}
}

const relayer = new Relayer();

export default relayer;