import {Contract} from "ethers";

const ADDRESS = "0x4278C98f350846BcAB9E8132859F7a6Ef465823f"
const ABI = [
    {
	"inputs": [
	    {
		"internalType": "address",
		"name": "sender",
		"type": "address"
	    },
	    {
		"internalType": "uint256",
		"name": "code",
		"type": "uint256"
	    },
	    {
		"internalType": "bytes",
		"name": "_signature",
		"type": "bytes"
	    }
	],
	"name": "submitVerificationCode",
	"outputs": [
	    {
		"components": [
		    {
			"internalType": "address",
			"name": "owner",
			"type": "address"
		    },
		    {
			"internalType": "uint256",
			"name": "timestamp",
			"type": "uint256"
		    },
		    {
			"internalType": "bool",
			"name": "verified",
			"type": "bool"
		    }
		],
		"internalType": "struct VerificationManager.SignIn",
		"name": "_signIn",
		"type": "tuple"
	    }
	],
	"stateMutability": "nonpayable",
	"type": "function"
    },
    {
	"anonymous": false,
	"inputs": [
	    {
		"components": [
		    {
			"internalType": "address",
			"name": "owner",
			"type": "address"
		    },
		    {
			"internalType": "uint256",
			"name": "timestamp",
			"type": "uint256"
		    },
		    {
			"internalType": "bool",
			"name": "verified",
			"type": "bool"
		    }
		],
		"indexed": false,
		"internalType": "struct VerificationManager.SignIn",
		"name": "",
		"type": "tuple"
	    }
	],
	"name": "Verified",
	"type": "event"
    },
    {
	"inputs": [],
	"name": "countRegisteredAccounts",
	"outputs": [
	    {
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	    }
	],
	"stateMutability": "view",
	"type": "function"
    },
    {
	"inputs": [
	    {
		"internalType": "address",
		"name": "sender",
		"type": "address"
	    }
	],
	"name": "isVerified",
	"outputs": [
	    {
		"internalType": "bool",
		"name": "_verified",
		"type": "bool"
	    }
	],
	"stateMutability": "view",
	"type": "function"
    },
    {
	"inputs": [
	    {
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	    }
	],
	"name": "signings",
	"outputs": [
	    {
		"internalType": "address",
		"name": "owner",
		"type": "address"
	    },
	    {
		"internalType": "uint256",
		"name": "timestamp",
		"type": "uint256"
	    },
	    {
		"internalType": "bool",
		"name": "verified",
		"type": "bool"
	    }
	],
	"stateMutability": "view",
	"type": "function"
    },
    {
	"inputs": [
	    {
		"internalType": "address",
		"name": "",
		"type": "address"
	    }
	],
	"name": "verificationCode",
	"outputs": [
	    {
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	    }
	],
	"stateMutability": "view",
	"type": "function"
    },
    {
	"inputs": [
	    {
		"internalType": "address",
		"name": "sender",
		"type": "address"
	    },
	    {
		"internalType": "uint256",
		"name": "code",
		"type": "uint256"
	    },
	    {
		"internalType": "bytes",
		"name": "_signature",
		"type": "bytes"
	    }
	],
	"name": "verify",
	"outputs": [
	    {
		"internalType": "address",
		"name": "",
		"type": "address"
	    },
	    {
		"internalType": "bool",
		"name": "",
		"type": "bool"
	    }
	],
	"stateMutability": "view",
	"type": "function"
    }
]

const VerificationManagerInfo = {
    address: ADDRESS,
    abi: ABI
}

export const VerificationContract = (signer) => {
    return new Contract(
		VerificationManagerInfo.address,
		VerificationManagerInfo.abi,
		signer
    );
}

