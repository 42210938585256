import React, {useEffect, useState} from 'react';

import Logo_Black from "../../../assets/logo/new/logo_left_slogan_right_black.svg"
import Logo_White from "../../../assets/logo/new/logo_left_slogan_right_white.svg"
import {AppButton, LinksContainer, LogoContainer, MenuContainer, NavBar, NavBarWrapper} from "./Navbar.styles";


import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import {Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {useLanguageService} from "../../../../language/LanguageProvider";
import {useNavigate} from "react-router-dom";
import {device} from "../../../../theme/DeviceStyling";

function Navbar(){

    const [logo, setLogo] = useState((window.innerWidth <= device.size.tablet) ? Logo_White : Logo_Black);

    const navigate = useNavigate();
    const {strings} = useLanguageService();

    const [openMenu, setOpenMenu] = useState(false);

    const menuOptions = [
	{
	    text: "Home",
	    icon: <HomeIcon />
	},
	{
	    text: "Info",
	    icon: <InfoIcon />
	}
    ]

    useEffect(()=>{

		const handleWidth = () => {
			(window.innerWidth <= device.size.tablet) ? setLogo(Logo_White) : setLogo(Logo_Black)
		}
		window.addEventListener("resize", handleWidth);
		return () => {window.removeEventListener("resize", handleWidth)}
    }, [])

    return(
	<NavBarWrapper>
	    <NavBar>
		<LogoContainer onClick={() => {navigate("/")} }>
		    <img src={logo} alt="logo"/>
		</LogoContainer>
		<LinksContainer>

		    {
			(menuOptions.map((item) => (
			    <a href={""} key={item.text}>{item.text}</a>
			)))
		    }

		    {/*<a href={""}>Home</a>*/}
		    {/*<a href={""}>About</a>*/}

		    <AppButton onClick={() => {navigate("/app")}}>
			Open App
		    </AppButton>
		</LinksContainer>
		<MenuContainer>
		    <MenuIcon onClick={() => setOpenMenu(true)}/>
		</MenuContainer>
		<Drawer open={openMenu} onClose={() => {setOpenMenu(false)}} anchor={"right"}>
		    <Box
			sx={{width: 200}}
			role={"presentation"}
			onClick={() => {setOpenMenu(true)} }
			onKeyDown={() => {setOpenMenu(false)} }
		    >
			<List>
			    {menuOptions.map((item) => (
				<ListItem key={item.text} disablePadding>
				    <ListItemButton>
					<ListItemIcon>{item.icon}</ListItemIcon>
					<ListItemText primary={item.text} />
				    </ListItemButton>
				</ListItem>
			    ))}
			</List>
		    </Box>
		</Drawer>
	    </NavBar>
	</NavBarWrapper>
    )
}

export default Navbar;