/**
 * This File is used if a Web-Connector is used.
 */
import {InjectedConnector} from "@web3-react/injected-connector";


const supportedChainIds = [
    1,
    4,
    5,
    11155111,
    137,	//
    56,		//Binance
    // 250,
    43114,	//Avalanche-C-Chain
    1422,	//zkEVM-Polygon-Mango
    1442,	//zkEVM-Polygon

    4854	//LOCALHOST
];
const Web3Connector = new InjectedConnector({ supportedChainIds });

const NetworkID = {
    ETH: 		        { id: "0x1", 	name: "Ethereum", 	chainId: 1	},		// 1
    POLYGON: 		    { id: "0x89", 	name: "Polygon",  	chainId: 137	},		// 137
    BSC: 		        { id: "0x38", 	name: "Binance", 	chainId: 56	},		// 56
    FTM: 		        { id: "0xfa", 	name: "Fantom", 	chainId: 250	},		// 250
    AVAX: 		        { id: "0xa86a", name: "Avalanche",	chainId: 43114	},		// 43114
    GOERLI: 		        { id: "0x5", 	name: "Goerli", 	chainId: 5	},		// 5
    SEPOLIA: 		        { id: "0xaa36a7", name: "Sepolia",  	chainId: 11155111},
    // POLYGON_ZKEVM_OLD: 	{ id: "0x58e", name: "zkEVM_Polygon",  	chainId: 1422},
    // POLYGON_ZKEVM: 	{ id: "0x5a2", name: "zkEVM_Polygon",  	chainId: 1442},

    LOCALHOST: 	                { id: "0x12f6", name: "localhost", 	chainId: 4854	},
    // CRYPTAGO_PRE: 	        { id: "0x12f6", name: "Cryptago_Pre", 	chainId: 4854	},
    // CRYPTAGO_ALPHA: 	        { id: "0x12f5", name: "Cryptago_Alpha",	chainId: 4853	},
    // CRYPTAGO_BETA: 	        { id: "0x12f4", name: "Cryptago_Beta",	chainId: 4852	},
    // CRYPTAGO_RC: 	        { id: "0x12f3", name: "Cryptago_RC",	chainId: 4851	},
    CRYPTAGO: 	                { id: "0x12f2", name: "Cryptago",	chainId: 4850	},
}

const TestNetworkID = {
    POLYGON_ZKEVM:	        { id: "0x5a2",  name: "zkEVM_Polygon",	chainId: 1442           },
    GOERLI:		            { id: "0x5",    name: "Goerli",		chainId: 5 		},
    SEPOLIA:		        { id: "0xaa36a7", name: "Sepolia",	chainId: 11155111 	},
    // LOCALHOST: 	        { id: "0x12f6", name: "localhost", 	chainId: 4854	        },
    CRYPTAGO_PRE: 	        { id: "0x12f6", name: "Cryptago-Pre", 	chainId: 4854	},
    CRYPTAGO_ALPHA: 	        { id: "0x12f5", name: "Cryptago-Alpha",	chainId: 4853	},
    CRYPTAGO_BETA: 	        { id: "0x12f4", name: "Cryptago-Beta",	chainId: 4852	},
    CRYPTAGO_RC: 	        { id: "0x12f3", name: "Cryptago-RC",	chainId: 4851	},
}

const getNetworkConfig = (network) => {
    const configOfNetwork = (network) => {
        switch(network){
            case NetworkID.AVAX: 			return ["https://api.avax.network/ext/bc/C/rpc/", 	"", "Avalanche", "AVAX"];
            case NetworkID.ETH: 			return ["https://mainnet.infura.io/v3/", 		"", "Ethereum", "ETH"];
            case NetworkID.BSC: 			return ["https://bsc-dataseed.binance.org/", 		"", "Binance", "BSC"];
            case NetworkID.POLYGON: 			return ["https://polygon-rpc.com/", 			"", "Polygon", "MATIC"];
            case NetworkID.FTM: 			return ["https://rpcapi.fantom.network/", 		"", "Fantom", "FTM"];

            case NetworkID.LOCALHOST: 	                return ["http://localhost:8545/", 		        null, "Cryptago", "CTO"];

            case NetworkID.CRYPTAGO:                    return ["https://web3.cryptago.eu/", 		        null, "Cryptago", "CTO"];

            case TestNetworkID.GOERLI: 		        return ["https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",	 		"", "Ethereum", "goerliETH"];
            case TestNetworkID.SEPOLIA: 		return ["https://sepolia.infura.io/v3/", 		"", "Ethereum", "sepoliaETH"];
            case TestNetworkID.POLYGON_ZKEVM: 	        return ["https://rpc.public.zkevm-test.net/", 		"https://explorer.public.zkevm-test.net/", "Polygon", "MATIC"];

            // case TestNetworkID.LOCALHOST: 	        return ["http://localhost:8545", 		        null, "Cryptago", "CTO"];
            case TestNetworkID.CRYPTAGO_PRE: 	        return ["https://web3.cryptago.eu/pre/", 		null, "Cryptago-PreAlpha", "CTO"];
            case TestNetworkID.CRYPTAGO_ALPHA: 	        return ["https://web3.cryptago.eu/alpha/", 		null, "Cryptago-Alpha", "CTO"];
            case TestNetworkID.CRYPTAGO_BETA: 	        return ["https://web3.cryptago.eu/beta/", 		null, "Cryptago-Beta", "CTO"];
            case TestNetworkID.CRYPTAGO_RC: 	        return ["https://web3.cryptago.eu/rc/", 		null, "Cryptago-RC", "CTO"];
            default:
        }
    }

    let networkConfig = configOfNetwork(network);
    return {rpc: networkConfig[0], explorer: networkConfig[1], token: {name: networkConfig[2], symbol: networkConfig[3]}}
}

const getNetworkForAdd = (network) => {
    let networkConfig = getNetworkConfig(network);
    let params = {
        chainId: network.id,
        chainName: network.name,
        nativeCurrency: {
            name: networkConfig.token.name,
            symbol: networkConfig.token.symbol,
            decimals: 18
        },
        rpcUrls : [networkConfig.rpc],
        // blockExplorerUrls: [networkConfig.explorer],
        // iconUrls: ""
    }
    if(networkConfig.explorer !== null)
        params.blockExplorerUrls = [networkConfig.explorer]

    return params;
}

const Chain = {

    getNetwork : (chainId) => {
        switch (chainId) {
            case NetworkID.ETH.chainId                  :   return NetworkID.ETH;
            case NetworkID.GOERLI.chainId               :   return NetworkID.GOERLI;
            case NetworkID.SEPOLIA.chainId              :   return NetworkID.SEPOLIA;
            case NetworkID.POLYGON.chainId              :   return NetworkID.POLYGON;
            case NetworkID.BSC.chainId                  :   return NetworkID.BSC;
            case NetworkID.FTM.chainId                  :   return NetworkID.FTM;
            case NetworkID.AVAX.chainId                 :   return NetworkID.AVAX;
            case TestNetworkID.POLYGON_ZKEVM.chainId    :   return TestNetworkID.POLYGON_ZKEVM;

            case TestNetworkID.CRYPTAGO_PRE.chainId     :   return TestNetworkID.CRYPTAGO_PRE;
            case TestNetworkID.CRYPTAGO_ALPHA.chainId   :   return TestNetworkID.CRYPTAGO_ALPHA;
            case TestNetworkID.CRYPTAGO_BETA.chainId    :   return TestNetworkID.CRYPTAGO_BETA;
            case TestNetworkID.CRYPTAGO_RC.chainId      :   return TestNetworkID.CRYPTAGO_RC;
            case NetworkID.CRYPTAGO.chainId             :   return NetworkID.CRYPTAGO;


            // case NetworkID.LOCALHOST.chainId        :   return NetworkID.LOCALHOST;
            default:
                console.log(chainId, TestNetworkID.CRYPTAGO_PRE)
        }
    },

    getNetworkFromChainIdHex : (chainId) => {

        // console.log(chainId)

        switch (chainId) {
            case NetworkID.ETH.id                  :   return NetworkID.ETH;
            case NetworkID.GOERLI.id               :   return NetworkID.GOERLI;
            case NetworkID.SEPOLIA.id              :   return NetworkID.SEPOLIA;
            case NetworkID.POLYGON.id              :   return NetworkID.POLYGON;
            case NetworkID.BSC.id                  :   return NetworkID.BSC;
            case NetworkID.FTM.id                  :   return NetworkID.FTM;
            case NetworkID.AVAX.id                 :   return NetworkID.AVAX;
            case TestNetworkID.POLYGON_ZKEVM.id    :   return TestNetworkID.POLYGON_ZKEVM;

            case TestNetworkID.CRYPTAGO_PRE.id     :   return TestNetworkID.CRYPTAGO_PRE;
            case TestNetworkID.CRYPTAGO_ALPHA.id   :   return TestNetworkID.CRYPTAGO_ALPHA;
            case TestNetworkID.CRYPTAGO_BETA.id    :   return TestNetworkID.CRYPTAGO_BETA;
            case TestNetworkID.CRYPTAGO_RC.id      :   return TestNetworkID.CRYPTAGO_RC;
            case NetworkID.CRYPTAGO.id             :   return NetworkID.CRYPTAGO;


            // case NetworkID.LOCALHOST.chainId        :   return NetworkID.LOCALHOST;
            default: console.warn(`No Chain found in configs ChainId[${chainId}]`)
        }
    },

    getChainId : (network) => {
        switch (network) {
            case NetworkID.ETH                  :   return NetworkID.ETH.chainId;
            case NetworkID.GOERLI               :   return NetworkID.GOERLI.chainId;
            case NetworkID.SEPOLIA              :   return NetworkID.SEPOLIA.chainId;
            case NetworkID.POLYGON              :   return NetworkID.POLYGON.chainId;
            case NetworkID.BSC                  :   return NetworkID.BSC.chainId;
            case NetworkID.FTM                  :   return NetworkID.FTM.chainId;
            case NetworkID.AVAX                 :   return NetworkID.AVAX.chainId;
            case NetworkID.POLYGON_ZKEVM        :   return NetworkID.POLYGON_ZKEVM.chainId;

            case TestNetworkID.CRYPTAGO_PRE     :   return TestNetworkID.CRYPTAGO_PRE.chainId;
            case TestNetworkID.CRYPTAGO_ALPHA   :   return TestNetworkID.CRYPTAGO_ALPHA.chainId;
            case TestNetworkID.CRYPTAGO_BETA    :   return TestNetworkID.CRYPTAGO_BETA.chainId;
            case TestNetworkID.CRYPTAGO_RC      :   return TestNetworkID.CRYPTAGO_RC.chainId;
            case NetworkID.CRYPTAGO             :   return NetworkID.CRYPTAGO.chainId;

            case NetworkID.LOCALHOST                :   return NetworkID.LOCALHOST.chainId;
            default:
        }
    }

}

const NetworkUtils = {
    Web3Connector,
    NetworkID,
    TestNetworkID,
    getNetworkConfig,
    getNetworkForAdd,
    getNetwork: Chain.getNetwork,
    getNetworkFromChainIdHex: Chain.getNetworkFromChainIdHex,
    getChainId: Chain.getChainId
}

export default NetworkUtils;