import React, {createContext, useContext, useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";

const DocumentViewContext = createContext(undefined);

export function DocumentViewProvider({children}) {

    const [currentPage, setCurrentPage] = useState({page_id: null, page_ref: null});
    const [pages, setPages] = useState([]);

    const [viewLoaded, setViewLoaded] = useState(false);

    // const prevPages = usePrevious(pages);

    const Font = ReactQuill.Quill.import('formats/font');
    Font.whitelist = [
        "Arial",
        "Consolas",
        "Sans Serif",
        "Serif",
        "Monospace",
        "Roboto"
        // "arial",
        // "comic-sans",
        // "courier-new",
        // "georgia",
        // "helvetica",
        // "lucida"
    ].sort();


    ReactQuill.Quill.register(Font, true);

    //Modules for the Quill-Editor
    const modules = {
        toolbar: [
            [
                { header: [1, 2, 3, 4, 5, 6, false] },
            ],
            [
                { font: Font.whitelist },
                { size: [] },
                { indent: '-1' },
                { indent: '+1' }
            ],
            [ 'bold', 'italic', 'underline', { background: [] }, { color: [] }, { list: 'bullet'}, { list: 'ordered'} ],

            // [{ align: [] }, 'direction' ],

            // [ 'bold', 'italic', 'underline', 'strike' ],
            // [{ color: [] }, { background: [] }],
            // [{ script: 'super' }, { script: 'sub' }],
            // ['blockquote', 'code-block' ],
            // [{ list: 'ordered' }, { list: 'bullet'}, { indent: '-1' }, { indent: '+1' }],
            // [ 'link', 'image', 'video' ],
            // [ 'clean' ]
        ],
        clipboard: {
            matchVisual: false
        },
    }

    useMemo(() => {
        console.log("Init - DocumentViewProvider")
        // console.clear();
    }, []);

    useEffect(() => {
        if(pages.length === 0){
            utils.pages.newPage();
            setViewLoaded(false);
        }else{
            console.log(pages[0].page_ref)
            setViewLoaded(true);
        }
    }, [pages]);

    const utils = {

        pages: {
            setRef: (quillRef) => {
                setPages(
                    (prev) => prev.map((page) => {
                        if(page.page_id === quillRef.current.props.id)
                            return {...page, page_ref:quillRef.current}
                        else
                            return page
                    })
                )
                // setPages((prevMap) => {
                //     const updatedMap = new Map(prevMap);
                //     if(updatedMap.has(quillRef.current.props.id)){
                //         const pageInfo = updatedMap.get(quillRef.current.props.id);
                //         updatedMap.set(quillRef.current.props.id, {...pageInfo, page_ref: quillRef.current})
                //     }
                //     return updatedMap;
                // })
            },

            newPage: (content=null, focus=false) => {
                setPages(oldValues => [...oldValues, {page_id: crypto.randomUUID(), page_ref: null, content: content, focus: focus}])

                // setPages((oldMap) => {
                //     const newPageId = crypto.randomUUID();
                //     const newMap = new Map(oldMap);
                //     const newPageData = {
                //         page_id: newPageId,
                //         page_ref: null,
                //         content: content,
                //         focus: content
                //     }
                //     newMap.set(newPageId, newPageData );
                //     console.log("new Page", newPageData)
                //     return newMap;
                // });
            },

            deletePage: (page_id_to_delete) => {
                let newPageList = pages.filter(key => key.page_id !== page_id_to_delete);
                setPages(newPageList)
            },

            setCurrentPage: (page_data) => {
                if(currentPage.page_id !== page_data.page_id){
                    // console.log("Curr_page", page_data.page_id)
                    console.assert(page_data.page_ref !== undefined, "Page-Reference is undefined")
                    setCurrentPage(page_data)
                }
            },
            getPageIndex: (quillRef) => {
                let page_index = pages.map((page_data) => {return page_data.page_id}).indexOf(quillRef.current.props.id)
                return page_index;
            },
            hasNextPage: (quillRef) => {
                console.log("Pages", pages)
                let page_index = pages.map((page_data) => {return page_data.page_id}).indexOf(quillRef.current.props.id)
                return pages.at(page_index+1) !== undefined

                // let page_index = Array.from(pages.keys()).indexOf(quillRef.current.props.id);
                // return Array.from(pages.keys()).at(page_index+1) !== undefined
            },
            hasPreviousPage: (quillRef) => {
                let page_index = pages.map((page_data) => {return page_data.page_id}).indexOf(quillRef.current.props.id)
                if(page_index === 0){
                    return false
                }else{
                    return pages.at(page_index-1) !== undefined
                }
            },
            getNextPage: (quillRef) => {
                if(utils.pages.hasNextPage(quillRef)){
                    let page_index = pages.map((page_data) => {return page_data.page_id}).indexOf(quillRef.current.props.id)
                    return pages.at(page_index+1)
                }else{
                    return null;
                }
            },
            getPreviousPage: (quillRef) => {
                if(utils.pages.hasPreviousPage(quillRef)){
                    let page_index = pages.map((page_data) => {return page_data.page_id}).indexOf(quillRef.current.props.id)
                    return pages.at(page_index-1)
                }else{
                    return null;
                }
            },

        },

        // content: {
        //     isOverflowing: (quillRef) => {
        //         const quillEditor = quillRef.current.getEditor().root;
        //         const isOverflowing = quillEditor.scrollHeight > quillEditor.clientHeight;
        //
        //         // console.log(quillEditor)
        //         // console.log(quillEditor.scrollHeight);
        //         // console.log(quillEditor.clientHeight)
        //
        //         return isOverflowing;
        //     },
        //     getOverflowingNodes: (quillRef) => {
        //         const quillEditor = quillRef.current.getEditor().root;
        //         const overflowAmount = quillEditor.scrollHeight - quillEditor.clientHeight;
        //         const childNodes = Array.from(quillEditor.childNodes).reverse();
        //
        //         let overflowingElements = [];
        //         let overflowingNodeHeight = 0;
        //
        //         for(let childNode of childNodes){
        //             if(overflowingElements.length === 0 || overflowAmount > overflowingNodeHeight){
        //                 overflowingElements.push(childNode)
        //                 overflowingNodeHeight += childNode.getBoundingClientRect().height;
        //             }else{
        //                 break;
        //             }
        //
        //         }
        //         return overflowingElements;
        //     },
        // },
    }

    // console.log("ViewProvider", pages)

    return(
        <DocumentViewContext.Provider
            value={{
                documentViewLoaded: viewLoaded,
                currentPage: currentPage,
                pages: pages,
                editorModules: modules,

                getDocumentContent: () => {
                    return pages.map((page) => {return page.page_ref.value}).join("")
                },

                setDocumentContent: (content) => {
                    console.warn("Setting Content of Document Content: ", content)
                    if(pages.length > 0 && pages[0]?.page_ref){
                        console.log("Setting Content", pages.length, pages[0]?.page_ref)

                        let editor = pages[0].page_ref
                        let content_delta = editor.getEditor().clipboard.convert(content)
                        editor.getEditor().setContents(content_delta, "blockchain")

                    }else{
                        console.warn("Pages-Ref not set", pages)
                        // console.log(pages, currentPage)
                        // console.log(pages.length, pages[0]?.page_ref)
                        // console.error("Error setting Content")
                    }
                },

                utils: utils,
                resetDocumentView: () => {
                    setPages([])
                }
            }}
        >
            {children}
        </DocumentViewContext.Provider>
    )

}
DocumentViewProvider.propTypes = {
    children: PropTypes.node
}



export function useDocumentView() {

    const context = useContext(DocumentViewContext);
    if (!context) throw Error("useDocumentView can only be used with DocumentViewProvider component")
    return context;

}