import React, {useEffect} from 'react';
import {useLanguageService} from "../../language/LanguageProvider";
import RestrictedPage from "../../components/common/restrictedPage/RestrictedPage";
import {useLocation, useNavigate} from "react-router-dom";

function NotFoundPage(){

    const navigate = useNavigate();
    const location = useLocation();

    const {strings} = useLanguageService();

    useEffect(() => {
	// setTimeout(() => {
	//     navigate(-1)
	// }, 5000)
    }, [])

    return (
        <RestrictedPage info={"404 "+strings.NOT_FOUND}>
            <p>{strings.NOT_FOUND_DESCRIPTION}</p>
        </RestrictedPage>
    )
}


export default NotFoundPage;