import React, {createContext, useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useWorkspace} from "./WorkspaceProvider";
import UnlockModalComponent from "../diary/components/modals/UnlockDocumentModal";

const WorkspaceServiceContext = createContext(undefined)


export function WorkspaceService({children}){

    const {workspace, unlocked} = useWorkspace();

    // UnlockModal
    const [showModal, setShowModal] = useState(false);
    const [loadingWorkspace, setLoadingWorkspace] = useState(false);

    useEffect(() => {
        console.log(workspace)
    }, [workspace])

    useEffect(() => {
        if(workspace.isLocked()){
            console.log("IS LOCKED")
            // setShowModal(true); //TODO: Uncomment when finished.
        }
    }, [unlocked]);

    return(
        <WorkspaceServiceContext.Provider
            value={{
                showUnlockModal: () => {setShowModal(true)}
            }}
        >
            {/**/}
            {children}
            <UnlockModalComponent
                showModal={showModal}
                setShowModal={setShowModal}
            />
        </WorkspaceServiceContext.Provider>
    )

}

WorkspaceService.propTypes = {
    children: PropTypes.node
}

export function useWorkspaceService(){
    const context = useContext(WorkspaceServiceContext);
    if(!context) throw Error("useWorkspaceService can only be used within WorkspaceService component")
    return context;
}