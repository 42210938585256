import React, {useEffect, useRef, useState} from 'react';

import "./faq.css";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from "prop-types";
import {useLanguageService} from "../../../../language/LanguageProvider";
import {Web3App} from "../../../common/AppItem";

function FaqSection(){

    const {landingFAQ} = useLanguageService();

    const accordionData = [
	{
	    title: landingFAQ.FAQ_TITLE_PUBLISH,
	    content: landingFAQ.FAQ_CONTENT_PUBLISH,
	    additional_content:
			<div className={"additional-content"}>
				<h3>Pre-Alpha</h3>
			</div>
	},
	{
	    title: landingFAQ.FAQ_TITLE_PRIVACY,
	    content: landingFAQ.FAQ_CONTENT_PRIVACY,
		additional_content:
			<div className={"additional-content"}></div>

	},
	// {
	//     title: landingFAQ.FAQ_TITLE_3,
	//     content: landingFAQ.FAQ_CONTENT_3
	// },
	{
	    title: landingFAQ.FAQ_TITLE_WEB3_PROVIDER_SUPPORT,
	    content: landingFAQ.FAQ_CONTENT_WEB3_PROVIDER_SUPPORT,
	    additional_content:
			<div className={"additional-content"}>
				<h3>{landingFAQ.FAQ_ADDITIONAL_CONTENT_WEB3_PROVIDER_SUPPORT.pre_alpha} / {landingFAQ.FAQ_ADDITIONAL_CONTENT_WEB3_PROVIDER_SUPPORT.alpha}</h3>
				<div className={"provider"}>
					<Web3App provider={"metamask"} background/>
				</div>

				<h3>{landingFAQ.FAQ_ADDITIONAL_CONTENT_WEB3_PROVIDER_SUPPORT.beta}</h3>
				<div className={"provider"}>
					<Web3App provider={"binance"} disabled background/>
					<Web3App provider={"trezor"} disabled background/>
					<Web3App provider={"walletconnect"} disabled background/>
					<Web3App provider={"mew"} disabled background/>
					{/*<AppItem imgSrc={bscSVG} name={"Binance Chain"} disabled/>*/}
					{/*<AppItem imgSrc={trezorSVG} name={"Trezor"} disabled/>*/}
					{/*<AppItem imgSrc={walletConnectSVG} name={"WalletConnect"} disabled/>*/}
					{/*<AppItem imgSrc={mewSVG} name={"MEW"} disabled/>*/}
				</div>
			</div>

	},
	{
	    title: landingFAQ.FAQ_TITLE_MONETIZATION,
	    content: landingFAQ.FAQ_CONTENT_MONETIZATION,
		additional_content:
			<div className={"additional-content"}></div>
	}
    ];

    return(
	<div className={"faq-section-container"}>
	    <p className={"primary-subheading"}>
			FAQ
	    </p>
	    <h1 className={"primary-heading"}>
			{landingFAQ.FAQ_HEADING}
	    </h1>

	    <div className={"faq-collapse-container"}>

		<div className="accordion">
		    {accordionData.map(({ title, content, additional_content}, key) => (
			<Accordion key={key} title={title} content={content}>
			    {additional_content}
			</Accordion>
		    ))}
		</div>

	    </div>
	</div>
    )
}

const Accordion = ({ title, content, children} ) => {
    const [isActive, setIsActive] = useState(false);

	const contentRef = useRef(null);
	const [height, setHeight] = useState(0);

	useEffect(() => {
		if (isActive) {
			const contentHeight = contentRef.current.scrollHeight;
			console.log('Höhe des Inhalts:', contentHeight);
			setHeight(contentHeight)
		}else{
			setHeight(0)
		}
	}, [isActive]);

	console.log(isActive, height)

    return (
		<div className="accordion-item">
			<div className="accordion-title" onClick={() => setIsActive(!isActive)}>
			<div>{title}</div>
			<div className="accordion-expand-icon" style={isActive ? {rotate: "90deg"} : {rotate: "0deg"}}>
				<ExpandMoreIcon/>
			</div>
			</div>
			{
				<div className="accordion-content" style={{height: height, paddingBlockEnd: (isActive) ? "1em" : "0em"}}  ref={contentRef}>
					<p className={"primary-text"}>{content}</p>
					{children}
				</div>
			}
		</div>
    );
};
Accordion.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.any.isRequired,
    children: PropTypes.node
}





export default FaqSection;