import React from 'react';
import {useLanguageService} from "../../../../language/LanguageProvider";
import EncyclopediaImage from "../../../assets/svg/encyclopedia.svg";

import PrivacyImage from "../../../assets/svg/privateDocument.svg";
import DecentralizedImage from "../../../assets/svg/decentralizedWorld.svg";

import PillowImage from "../../../assets/images/Pillow.png";

import "./work.css";

function Work(){

    const {landing, landingPR} = useLanguageService();

    const workInfoData = [
	{
	    image: PrivacyImage,
	    title: landing.WORK_PRIVACY_TITLE,
	    desc: landing.WORK_PRIVACY_DESC
	},
	{
	    image: DecentralizedImage,
	    title: landing.WORK_DECENTRALIZED_TITLE,
	    desc: landing.WORK_DECENTRALIZED_DESC
	},
	{
	    image: EncyclopediaImage,
	    title: landing.WORK_ENCYCLOPEDIA_TITLE,
	    desc: landing.WORK_ENCYCLOPEDIA_DESC
	},
    ];
    console.log(workInfoData)

    return(
	<div className={"work-section-container"}>
	    <div className={"work-section-top"}>
		<p className={"primary-subheading"}>Work</p>
		<h1 className={"primary-heading"}>
		    {landing.WORK_HOW_IT_WORKS_HEADER}
		</h1>
		<p className={"primary-text"} style={{whiteSpace: "pre-line"}}>
		    {landing.WORK_HOW_IT_WORKS_DESC}
		</p>
	    </div>

	    <div className={"work-section-bottom"}>
		{
		    workInfoData.map((data, key) => (
			<div key={key} className={"work-section-pillow-info"}>
			    <div className={"work-section-info"}>
				<div className={"info-boxes-img-container"}>
				    <img src={data.image} alt={""}/>
				    <div className={"vr"}/>
				    <h2>{data.title}</h2>
				</div>
				<div className={"info-boxes-text-container"}>
				    <p className={"primary-text"}>{data.desc}</p>
				</div>
			    </div>
			    <div className={"info-pillow-img-container"}>
				<img src={PillowImage} alt={""}/>
			    </div>
			</div>

		    ))
		}
	    </div>

	</div>
    )
}

export default Work;