import React, {useEffect, useState} from "react";

import LibraryV2 from "../../pages/library/Library";

import PropTypes from "prop-types";
import {SelectionDiv, SelectionTile, ViewSelectionDiv} from "./ViewSelection.styles";
import {useDAO} from "../dao/DaoProvider";

import project from "../../../package.json";
import Footer from "../../branding/Footer";
import {useLanguageService} from "../../language/LanguageProvider";
import {BlockViewer, NetworkViewer} from "../common/uiUtils/Utils";
import {Workspace} from "../../pages/workspace/Workspace";
import {useWeb3React} from "@web3-react/core";
import {GasStation} from "../../pages/gasstation/GasStation";

import {useDeviceSelectors, useMobileOrientation} from 'react-device-detect';

import {ReactComponent as DocumentIcon} from "../../pages/assets/svg/document.svg";
import {ReactComponent as GasStationIcon} from "../../pages/assets/svg/gas_station.svg";
import {ReactComponent as LibraryIcon} from "../../pages/assets/svg/library.svg";

function ViewSelection({mainNetwork}) {

    const {strings} = useLanguageService();

    const {account} = useWeb3React();
    const {readOnly, library, gasStationContract} = useDAO();

    const { isLandscape, orientation } = useMobileOrientation()
    const [selectors, data] = useDeviceSelectors(window.navigator.userAgent)


    // useEffect(() => {
    //     console.log("ORIENTATION", orientation)
    //     console.log("IS-LANDSCAPE", isLandscape)
    //     console.log(selectors, data)
    //
    //     console.table(selectors)
    //     console.log(selectors.deviceType)
    //
    // }, [isLandscape, orientation, selectors]);


    // useEffect(() => {
    //     if (library) {
    //         console.log("Subscribe to Event - NewBlock")
    //         library.on('block', (block) => {
    //             console.log("New Block", block)
    //         })
    //     }
    // }, [library])

    const VIEW = {
        NONE: "NONE",
        LIBRARY: "LIBRARY",
        MYDIARY: "MYDIARY",
        WORKSPACE: "WORKSPACE",
        GASSTATION: "GASSTATION"
    }
    const [view, setView] = useState((readOnly) ? VIEW.LIBRARY : VIEW.NONE);
    const [previousView, setPreviousView] = useState(view);

    const [isGasStationAdmin, setIsGasStationAdmin] = useState(false);
    const [isGasRequester, setIsGasRequester] = useState(false);

    useEffect(() => {
        (async() => {

            if(!readOnly){
                console.log(account)
                console.log(gasStationContract)

                let admin_role = await gasStationContract.ADMIN_ROLE();
                let gas_requester_role = await gasStationContract.GAS_REQUESTER_ROLE();

                const [isAdmin, isGasRequester] = await Promise.all([
                    gasStationContract.hasRole(admin_role, account),
                    gasStationContract.hasRole(gas_requester_role, account)
                ]);
                setIsGasStationAdmin(isAdmin)
                setIsGasRequester(isGasRequester)
            }

        })();

    }, [account]);



    const setNewView = (new_view) => {
        console.log("SetPreviousView", previousView, view, new_view)
        setPreviousView(view);
        setView(new_view)
    }

    // const renderMyDiary = () => {
    //     return <MyDiary back={() => setView(VIEW.NONE)} preview={false}/>
    // }

    const renderLibrary = () => {
        return <LibraryV2 back={() => setNewView(VIEW.NONE)}/>
    }

    const renderWorkspace = () => {
        // return <WorkspaceDesktop back={() => setView(VIEW.NONE)} />
        return <Workspace
            back={() => setNewView(VIEW.NONE)}
            visitLibrary={() => setNewView(VIEW.LIBRARY)} //Function to return View back to library
        />
    }

    const renderGasStation = () => {
        return <GasStation
            back={() => setNewView(VIEW.NONE)}
        />
    }

    /**
     * Selection of Views
     * TODO: Think about Routing
     * @return {*|JSX.Element}
     */
    const renderSelectionView = () => {
        switch (view) {
            case VIEW.NONE:
                return renderSelection();
            // case VIEW.MYDIARY:
            //     return renderMyDiary();
            case VIEW.WORKSPACE:
                return renderWorkspace();
            case VIEW.LIBRARY:
                return renderLibrary();
            case VIEW.GASSTATION:
                return renderGasStation();
        }
    }

    /**
     * Main View of ViewSelection
     * @return {JSX.Element}
     */
    const renderSelection = () => {

        console.log(selectors.deviceType)
        console.log(selectors)
        console.log(selectors.deviceType === selectors.isMobile)

        return (
            <div style={{height: "100vh"}}>
                <SelectionDiv>
                    <h1>{strings.VIEW_CHOICE}</h1>
                    <p>v{project.version}</p>
                    <p>{selectors.deviceType}</p>
                    <ViewSelectionDiv>

                        {
                            (!readOnly)
                                ?
                                <>
                                    <SelectionTile
                                        onClick={() => { setView(VIEW.WORKSPACE) }}
                                        disabled={selectors.isMobile}
                                        // disabled
                                    >
                                        <DocumentIcon/>
                                        <h2>{strings.VIEW_MY_WORKSPACE}</h2>

                                        <div>
                                            <div>
                                                <hr/>
                                                <label>Currently only Browser-Version</label>
                                                <hr/>
                                            </div>
                                        </div>

                                        {/*<div className={"info-bubble"}>New</div>*/}

                                    </SelectionTile>
                                    {/*<SelectionTile onClick={() => { setView(VIEW.MYDIARY) }}>*/}
                                    {/*    <Logo src={"/images/diary.svg"} style={{*/}
                                    {/*        backgroundColor: "transparent",*/}
                                    {/*        paddingBottom: "10px",*/}
                                    {/*        width: "64px"*/}
                                    {/*    }}/>*/}
                                    {/*    <h2>{strings.VIEW_MY_DOCUMENTS}</h2>*/}
                                    {/*</SelectionTile>*/}
                                </>
                                :
                                <>
                                </>
                        }

                        <SelectionTile onClick={() => { setView(VIEW.LIBRARY) }}>
                            <LibraryIcon/>
                            <h2>{strings.VIEW_LIBRARY}</h2>
                        </SelectionTile>

                        {
                            (isGasStationAdmin || isGasRequester)
                                ?
                                <SelectionTile onClick={() => {
                                    setView(VIEW.GASSTATION)
                                }}>
                                    <GasStationIcon/>
                                    <h2>{strings.VIEW_GAS_STATION}</h2>
                                    {/*<div className={"info-bubble"} style={{backgroundColor: "blue"}}>Admin</div>*/}
                                </SelectionTile>
                                :
                                <></>
                        }

                    </ViewSelectionDiv>

                    <NetworkViewer/>


                </SelectionDiv>
                <BlockViewer style={{position: "absolute"}}/>
                <Footer/>
            </div>

        )
    }

    return renderSelectionView();
}

ViewSelection.propTypes = {
    mainNetwork: PropTypes.any
}

export default ViewSelection;