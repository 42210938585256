import styled from "styled-components";

export const ExitDiv = styled.div`
  
    position : ${(props => props.position ? props.position : "sticky")};
    //position: sticky;
    display: flex;
    justify-content: flex-end;
    bottom: 0;
    padding-bottom: 4px;
    //right: 10px;
    //height: 40px;
`;
export const Exit = styled.div`
    
    position: relative;
    content: url("/images/door_closed_bold.svg");
    //height: 100%;
    //height: 60px;
    width: 50px;
    padding-right: 6px;
    padding-bottom: 6px;
    
    //bottom: 10px;
    //right: 10px;
    cursor: pointer;
    //filter: invert(49%) sepia(53%) saturate(2305%) hue-rotate(166deg) brightness(107%) contrast(102%);
  
    &:hover{
	content: url("/images/door_open_bold.svg");
      
      //top: calc(100% - 52px);
	transform: scaleY(1.07) translateY(2px);
    }
`;

export const LibraryHeader = styled.header`
  
    display: inline-grid;
    grid-template-rows: auto auto;
    gap: 12px;
    //padding: 16px;
    padding-top: 16px;
    //padding-bottom: 16px;
    border-bottom: 1px solid grey;
    box-shadow: 0px -27px 31px 9px #3498db;
  
  
    background-color: lightgray;
    text-align: center;

    font-size: 1em;
    
    z-index: 1;
    fieldset{
      //min-height: 100px;
      border: 1px solid gray;
      border-radius: 6px;
      text-align: left;
      margin: 0;
    }
  
    @media screen and (max-width: 600px) {
    	//h1{
	  font-size: 0.8em;
	//}
    }
`;

export const SearchIconWrapper = styled.div`
    //padding: 0 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #3498db;
    color: white;
    
    width: 42px;
    
    //:hover{
    //	background-color: #3498dbaa;  
    //}
  
`;
export const LibrarySearchDiv = styled.div`
    position: relative;
  
    display: inline-flex;
    justify-content: space-between;
    align-items: center;	
  
    border-radius: 6px;
    //padding: 6px;
    background-color: white;
    border: 1px solid lightgray;
    //min-width: 80%;
    width: 100%;
    height: 42px;
    overflow: auto;
`;

// export const LibraryDiv = styled.div`
//     display: grid;
//     grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
//     //grid-template-columns: repeat(auto-fill, minmax(min(10rem, 100%), 1fr));
//     gap: 3rem;
//     margin: 50px 20%;
//
// `;
export const LibraryDocumentContent = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    //grid-template-columns: repeat(auto-fill, minmax(min(10rem, 100%), 1fr));
    gap: 3rem;
    padding: 50px 5%;
    //background-color: #D3D3D340;

    overflow: auto;
  
    @media screen and (max-width: 600px) {
      gap: 1rem;
      padding: 1rem;
    }
  
`;


export const DocumentDiv = styled.div`
  position: relative;
  //display: flex;
  //flex-direction: column;
  //justify-content: space-evenly;
  //align-items: center;

  border: 2px solid #f7f7f7;
  width: 100%;
  //height: 160px;
  border-radius: 4px;

  //overflow: auto;
  background-color: white;

  @media screen and (max-width: 600px) {
    //height: 300;
  }

`;

export const ReadOverlay = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    cursor: pointer;
`;

export const DiaryInfoDiv = styled.div`

    background-color: #f7f7f7;
    
    display: grid;
    grid-template-areas: 
	    "image"
	    "metadata"
	    "content"
	    "pricing"
    ;
    
    //grid-template-columns: 200px auto;
    grid-template-rows: 280px 40px 280px auto;
  
    > div{
      padding: 6px 12px 6px 12px;
    }

    p{
      color: #3498db;
    }
  
    @media screen and (max-width: 600px) {

      grid-template-areas: 
	"image metadata"
	"image content"
      	"image content"
	"image pricing"
      ;

      grid-template-columns: 120px auto;
      grid-template-rows: auto 116px;
      
      font-size: 0.8em;
    }
`;

export const DocumentPricingDiv = styled.div`
  
    grid-area: pricing;
    padding: 6px;
`;
export const DocumentMetaDataDiv = styled.div`
    grid-area: metadata;
    //background-color: #f7f7f7;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px !important;
`;
export const DocumentImageDiv = styled.div`
    grid-area: image;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    height: inherit;
    width: inherit;
    //background-color: #f7f7f7;
    //grid-row: 1;
    margin: 12px;
    
    position: relative;
  
    img{
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      max-width: 100%;
      max-height: 100%;
      //object-fit: cover;
      box-shadow: 1px 1px 6px 0;
    }
  
    p{
      color: #3498db;
    }
  
    @media screen and (max-width: 600px) {
    	//padding: 6px;
    }
`;

export const DocumentInfoDiv = styled.div`
    grid-area: content;
    //grid-row: 1;
    
    font-size: 1.2em;
    background-color: white;
    header{
      color: #3498db;
      label{
	font-size: 1.3em;
      }
      
      p[class="author"]{
	font-weight: normal;
	color: lightgrey;
      }
    }
    
    section{
      padding: 6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 6px;
    }
`;