import NetworkUtils from "../../../../../utils/NetworkUtils";
import {useLanguageService} from "../../../../../language/LanguageProvider";
import {useWeb3React} from "@web3-react/core";
import React, {useEffect, useState} from "react";
import {EarlyAccessDiv} from "./EarlyAccess.styles";
import {
    ConnectWalletButton,
    CreateSignUpCodeButton,
    FollowButton,
    SwitchNetworkButton,
    Web3App
} from "../../../../common/AppItem";

import MetaMaskOnboarding from '@metamask/onboarding';
import {NetworkInfo} from "../../../../common/tiles/Network";
import {WalletInfo} from "../../../../common/tiles/Account";

import {VerificationContract} from "./VerificationManager";
import Relayer from "../../../../../components/relay_api/RelayAPI";

import CheckIcon from '@mui/icons-material/Check';
import PropTypes from "prop-types";
import {toast} from "react-toastify";

const EarlyAccess = () => {

    const __main_network = NetworkUtils.TestNetworkID.CRYPTAGO_PRE;
    // const __main_network = NetworkUtils.TestNetworkID.POLYGON_ZKEVM;

    const {landingEarlyAccess} = useLanguageService();

    const {activate, deactivate, active, chainId, connector, account, library} = useWeb3React();

    const [verificationManagerContract, setVerificationManagerContract] = useState(null);
    const [verificationCode, setVerificationCode] = useState(null);
    const [loadingVerificationCode, setLoadingVerificationCode] = useState(false);

    const [onboarded, setOnboarded] = useState(false);

    const [countRegistrations, setCountRegistrations] = useState("0");

    useEffect(() => {

        console.log("ChainId changed: ", chainId)
        if (chainId !== __main_network.chainId && active) {
            switchNetwork()
        } else {
            if (active && chainId === __main_network.chainId) {
                console.log("%c ----- ReferalManager Initialization -----", "font-size: medium")
                let contract = VerificationContract(library.getSigner());
                setVerificationManagerContract(contract)
                console.log(contract, "font-size: medium")
                console.log("%c ----- ReferalManager Initialization ----- Finsihed", "font-size: medium")
            }
        }

    }, [chainId])

    useEffect(() => {

        (async () => {
            if (verificationManagerContract !== null) {
                console.log("%c ----- Initial-Step Verification-Code check -----", "font-size: medium")
                let code = await verificationManagerContract.verificationCode(account);
                if (code.toString() !== "0") {
                    setVerificationCode(code.toString())
                    console.log("%c Code: " + code.toString(), "font-size: medium")

                    // let onboarded = await verificationManagerContract.signings(code.toString());
                    // console.log("Onboarded: ", onboarded)
                    // setOnboarded(onboarded.verified)

                } else {
                    console.log("No code exist")
                }
                console.log("%c ----- Initial-Step Verification-Code finished -----", "font-size: medium");

                let countRegisteredAccount = await verificationManagerContract.countRegisteredAccounts();
                setCountRegistrations(countRegisteredAccount.toString());

                verificationManagerContract.on('Verified', async (newSignIn) => {

                    let verified = await verificationManagerContract.isVerified(account);
                    setOnboarded(verified)

                    await refreshRegisteredAccounts();

                })

            }
        })();
    }, [verificationManagerContract])

    useEffect(() => {

        (async () => {
            if (account && verificationManagerContract) {
                console.log("Account switched", account, verificationManagerContract)
                let verified = await verificationManagerContract.isVerified(account);
                setOnboarded(verified)
                let code = await verificationManagerContract.verificationCode(account);
                setVerificationCode((code.toString() !== "0") ? code.toString() : null)
                setLoadingVerificationCode(false)
            }
        })();


    }, [account, verificationManagerContract])

    const refreshRegisteredAccounts = async () => {
        let countRegisteredAccount = await verificationManagerContract.countRegisteredAccounts();
        setCountRegistrations(countRegisteredAccount.toString());
    }

    const connect = async (network) => {
        try {
            activate(NetworkUtils.Web3Connector, console.error, true).then((result) => {
                console.log(result)
            }).catch(async (error) => {
                console.table(error)
                if (error.name === "UnsupportedChainIdError") {
                    await switchNetwork()
                    await connect()
                }
            })
        } catch (e) {
            console.error(e)
        }
    }
    const disconnect = async () => {
        deactivate()
    }

    const switchNetwork = async () => {
        if (window.ethereum && window.ethereum.isMetaMask) {
            console.log(window.ethereum.chainId, __main_network.chainId)

            console.log(NetworkUtils.getNetworkForAdd(__main_network))

            if (window.ethereum.chainId !== __main_network.id) {
                let availableChainIds = await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [NetworkUtils.getNetworkForAdd(__main_network)]
                })
            }
        }
    }

    const follow = async (account) => {
        console.log("Verifying Account", account)
        let tweetObj = {
            url: "https://twitter.com/intent/follow?",
            // domain: "CryptagoEU",
            // original_referer: "original_referer="+window.location.origin,
            screen_name: "screen_name=CryptagoEU",
            // text: "Code: 321Code123\nType: verify",
            // obj: {
            // domain: "cryptao.eu",
            // type: "verify",
            // code: "321Code123"
            // }

        }

        const newWindow = window.open(
            tweetObj.url + (tweetObj.screen_name),
            // tweetObj.url + encodeURIComponent(tweetObj.text),
            "_blank",
            "width=550,height=420"
        );
    }

    const signTypedData = async () => {

        const domain = {
            name: "Cryptago",
            version: "1",
            chainId: chainId,
            verifyingContract: verificationManagerContract.address,
        }

        const EIP712Domain = [
            {name: 'name', type: 'string'},
            {name: 'version', type: 'string'},
            {name: 'chainId', type: 'uint256'},
            {name: 'verifyingContract', type: 'address'},
        ]

        const Verify = [
            {name: 'code', type: 'uint256'}
        ]

        const message = {
            code: verificationCode
        }

        const msgParams = {
            domain: domain,
            message: message,	    // Defining the message signing data content.
            primaryType: 'Verify',
            types: {
                // TODO: Clarify if EIP712Domain refers to the domain the contract is hosted on
                EIP712Domain: EIP712Domain,
                // Permit: Permit,
                Verify: Verify

            },
        };

        const signature = await library.provider.request({
            method: 'eth_signTypedData_v4',	//MetaMask Function
            params: [account, JSON.stringify(msgParams)]
        })

        setLoadingVerificationCode(true)
        let response = await Relayer.confirm({sender: account, signature: signature, code: verificationCode})
        let result = await response.json();

        if (response.status === 200) {
            // toast.info(landingEarlyAccess.CREATE_REGISTER_CODE_FINISHED)
        } else if (response.status === 400) {
            toast.error(result.error.reason)
        } else {
            // console.log(response, result)
            let events = result.receipt.events;
            for (let event of events) {
                if (event.event === "Verified" && event.args[0][0] === account) {
                    let verified = event.args[0][2]
                    setOnboarded(verified)
                    await refreshRegisteredAccounts();
                    if (verified)
                        toast.success(landingEarlyAccess.FINISH_VERIFY_CODE)
                }
            }
        }
        //Listener will trigger Verification-DONE
    }

    /**
     * 1.) Connect via Relayer to receive Code for verification
     * @returns {Promise<void>}
     */
    const signConnect = async () => {
        setLoadingVerificationCode(true)
        let result = await Relayer.connect({
            sender: account,
            chainId: chainId,
            domain: window.location.host,
            uri: window.location.origin
        })
        let message = await result.json()
        console.log(message)
        setVerificationCode(message.code)
        setLoadingVerificationCode(false)
        // setReferalMsg(message.message)
    }

    /**
     * 2.) Send signature back to Server to finish Subscription
     * @returns {Promise<void>}
     */
        // const confirmConnect = async() => {
        // const msg = `0x${Buffer.from(referalMsg, 'utf8').toString('hex')}`;
        // const signature = await window.ethereum.request({
        //     method: 'personal_sign',
        //     params: [msg, account],
        // });
        // console.log(signature)
        //
        // //Finished Onboarding
        // setOnboarded(true);
        // }

    const splitSig = (sig, readable) => {
            const pureSig = sig.replace('0x', '');

            const _r = Buffer.from(pureSig.substring(0, 64), 'hex');
            const _s = Buffer.from(pureSig.substring(64, 128), 'hex');
            const _v = Buffer.from(
                parseInt(pureSig.substring(128, 130), 16).toString(),
            );
            if (readable)
                return {
                    r: "0x" + _r.toString("hex"),
                    s: "0x" + _s.toString("hex"),
                    v: _v.toString()
                };
            else
                return {_r, _s, _v};
        };

    if (window.ethereum) {
        return (
            <EarlyAccessDiv>
                <div className={"connect-wallet-container"}>
                    <p className={"primary-text"}>{landingEarlyAccess.DESCRIPTION}</p>
                    {/*<MetaMaskApp onClick={() => {console.log("Clicked")} }/>*/}
                    <div className={"wallet-provider-container"}>
                        <WalletInfoComponent/>
                        {
                            !active
                                ?
                                <ConnectWalletButton onClick={() => {
                                    connect(__main_network)
                                }}/>
                                :
                                (chainId === __main_network.chainId)
                                    ?
                                    (onboarded)
                                        ?
                                        <>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                whiteSpace: "pre-wrap",
                                                textAlign: "left"
                                            }}>
                                                <CheckIcon style={{color: "green", fontSize: "3em", width: "100px"}}/>
                                                <div>
                                                    <p className={"primary-text"}>{landingEarlyAccess.FINISHED_ONBOARDING_THANK_YOU}</p>
                                                    <p className={"primary-text"}
                                                       style={{fontSize: "0.9em"}}>{landingEarlyAccess.FINISHED_ONBOARDING_TEXT}</p>
                                                </div>

                                            </div>
                                            <FollowButton onClick={follow}/>
                                        </>

                                        :
                                        (verificationCode)
                                            ?
                                            <CreateSignUpCodeButton
                                                back
                                                onClick={signTypedData}
                                                                    referalCode={verificationCode}
                                                                    loading={loadingVerificationCode}/>
                                            :
                                            <CreateSignUpCodeButton onClick={signConnect}
                                                                    loading={loadingVerificationCode}/>

                                    // <CreateSignUpCodeButton onClick={generateCode} />
                                    // <CreateSignUpCodeButton onClick={signTypedData} />
                                    // <CreateSignUpCodeButton onClick={signConnect} />
                                    :
                                    <SwitchNetworkButton onClick={switchNetwork}
                                                         from={NetworkUtils.getNetwork(chainId)}
                                                         to={NetworkUtils.getNetwork(__main_network.chainId)}
                                    />
                            // <VerifyWalletButton onClick={ () => {verify(account)} } />
                        }
                    </div>
                </div>
                {
                    (verificationManagerContract)
                        ?
                        <UserActivity registeredAccounts={countRegistrations}/>
                        :
                        <></>
                }

            </EarlyAccessDiv>
        )
    } else {
        return (
            <EarlyAccessDiv style={{flexDirection: "column"}}>
                <p className={"primary-text"}>{landingEarlyAccess.DOWNLOAD_DESCRIPTION_HEADER}</p>
                <div>
                    <OnboardingMetaMask/>

                    {/*<div className={"provider"} style={{margin: "2rem 6rem", gap: "12px"}}>*/}
                    {/*<p className={"primary-text"}>{landingEarlyAccess.DOWNLOAD_DESCRIPTION_2}</p>*/}

                    {/*<Web3App onClick={() => {console.log("Clicked")}} provider={"metamask"}/>*/}
                    {/*<Web3App onClick={() => {console.log("Clicked")}} provider={"trezor"} disabled/>*/}
                    {/*<Web3App onClick={() => {console.log("Clicked")}} provider={"mew"} disabled/>*/}
                    {/*<Web3App onClick={() => {console.log("Clicked")}} provider={"binance"} disabled/>*/}
                    {/*<Web3App onClick={() => {console.log("Clicked")}} provider={"walletconnect"} disabled/>*/}

                </div>
                {/*<ConnectWallet onClick={() => {connect(__main_network)} }/>*/}
            </EarlyAccessDiv>
        )
    }
}

const UserActivity = ({registeredAccounts = "0"}) => {

    const {landingEarlyAccess} = useLanguageService();

    return (
        <div>
            <p className={"primary-text"}>
                {registeredAccounts + " " + landingEarlyAccess.USERS_REGISTERED}
            </p>
        </div>
    )
}
UserActivity.propTypes = {
    registeredAccounts: PropTypes.string
}

export function OnboardingMetaMask() {

    const ONBOARD_TEXT = 'Click here to install MetaMask!';
    const CONNECT_TEXT = 'Connect';
    const CONNECTED_TEXT = 'Connected';

    const [buttonText, setButtonText] = React.useState(ONBOARD_TEXT);
    const [isDisabled, setDisabled] = React.useState(false);
    const [accounts, setAccounts] = React.useState([]);
    const onboarding = React.useRef();

    React.useEffect(() => {
        if (!onboarding.current) {
            onboarding.current = new MetaMaskOnboarding();
        }
    }, []);

    React.useEffect(() => {
        if (MetaMaskOnboarding.isMetaMaskInstalled()) {
            if (accounts.length > 0) {
                setButtonText(CONNECTED_TEXT);
                setDisabled(true);
                onboarding.current.stopOnboarding();
            } else {
                setButtonText(CONNECT_TEXT);
                setDisabled(false);
            }
        }
    }, [accounts]);

    React.useEffect(() => {
        function handleNewAccounts(newAccounts) {
            setAccounts(newAccounts);
        }

        if (MetaMaskOnboarding.isMetaMaskInstalled()) {
            window.ethereum.request({method: 'eth_requestAccounts'}).then(handleNewAccounts);
            window.ethereum.on('accountsChanged', handleNewAccounts);
            return () => {
                window.ethereum.removeListener('accountsChanged', handleNewAccounts);
            };
        }
    }, []);

    const onClick = () => {
        if (MetaMaskOnboarding.isMetaMaskInstalled()) {
            window.ethereum.request({method: 'eth_requestAccounts'})
                .then((newAccounts) => setAccounts(newAccounts));
        } else {
            console.log(onboarding.current)
            onboarding.current.startOnboarding();
        }
    };
    return (
        <>
            <Web3App disabled={isDisabled} onClick={onClick} provider={"metamask"} background/>
            {/*<p>{buttonText}</p>*/}
        </>
        // <button disabled={isDisabled} onClick={onClick}>
        //     {buttonText}
        // </button>
    );
}

const WalletInfoComponent = () => {

    const {activate, deactivate, active, chainId, connector, account, library} = useWeb3React();
    if (active) {
        return (
            <div className={"provider-infos"}>
                <NetworkInfo/>
                <WalletInfo/>
            </div>
        )
    } else {
        return (
            <>
            </>
        )
    }

}

export default EarlyAccess;