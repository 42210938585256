import styled from "styled-components";

export const LogoDiv = styled.div`
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        //width: 100%;
        //padding: 6px;
        
        color: ${(prop) => prop.theme.global.base};
        
        cursor: pointer;
    
        label{
            font-weight: 500;
            font-size: 1.8rem;
            cursor: inherit;
            font-family: "Akashi Regular";
        }
        
        @keyframes fadeOutLogo {
            0% {
                //opacity:1;
                transform: translateX(0);
                visibility: visible;
            }
            100% {
                transform: translateX(-100%);
                visibility: hidden;
            }
        }

        @keyframes fadeInLogo {
            0% {
                //opacity:1;
                transform: translateX(-100%);
                visibility: hidden;
            }
            100% {
                transform: translateX(0);
                visibility: visible;
            }
        }
        
        //@-moz-keyframes fadeOut {
        //    0% {
        //        opacity:1;
        //    }
        //    100% {
        //        opacity:0;
        //    }
        //}
        //
        //@-webkit-keyframes fadeOut {
        //    0% {
        //        opacity:1;
        //    }
        //    100% {
        //        opacity:0;
        //    }
        //}
        //
        //@-o-keyframes fadeOut {
        //    0% {
        //        opacity:1;
        //    }
        //    100% {
        //        opacity:0;
        //    }
        //}
        //
        //@-ms-keyframes fadeOut {
        //    0% {
        //        opacity: 1;
        //    }
        //    100% {
        //        opacity: 0;
        //    }
        //}
        
        .fade-out {
            animation: fadeOutLogo ease 0.5s forwards;
            //-webkit-animation: fadeOut ease 1s;
            //-moz-animation: fadeOut ease 1s;
            //-o-animation: fadeOut ease 1s;
            //-ms-animation: fadeOut ease 1s;
        }
        .fade-in{
            animation: fadeInLogo ease 0.5s forwards;
        }

        //@media(max-width: 768px){
        //
        //    img{
        //        width: 50px;
        //        //height: 30px;
        //    }
        //}
        
    `;

export const LogoStyledImg = styled.img`
	width: 50px;
        height: 100%;
        z-index: 10;
        //background-color: ${(prop) => prop.theme.palette.navPanel};
        transition: 0.5s;

        @media(max-width: 768px){

            img{
                width: 40px;
                
            }
        }
    `;