import styled from "styled-components";

export const EditorStyling = styled.div`
  
     display: grid;
    //flex-direction: row;
    height: fit-content;
    grid-template-columns: 82% auto;
  
    //p, h3{
    //  padding: unset;
    //}
  
  
    .ck-editor{
      //width: 100%;
      height: 100%;
      //width: calc(90vh / 1.4);
    }
  
    .ck-editor__editable {
      	//height: 100%;
    	height: calc( var(--editor-height) - 41px);

	//@media (max-width: 768px) {
	//    min-height: 100px;
	//}
  
      	//width: calc(100% - 100px);
    }

  .ck-editor__editable.ck-focused {
    //border: unset !important;
  }
  
    .sendBtn {
      //width: 20%;
      width: 100%;
      border: 1px solid grey;
      background-color: ${(props) => props.theme.global.base};
      color: white;
      flex-direction: column;
    }
    .sendBtn:hover{
      //background-color: lightgrey;
      background-color: ${(props) => props.theme.global.base+"CD"};
      //color: ${(props) => props.theme.global.base};
    }
`;

export const OptionPanel = styled.div`
    position: relative;
    width: 400px;
    height: 100%;
`;

export const ReleaseDiv = styled.div`
    position: absolute;
    width: 100%;
    bottom: 0;
`;

export const DiaryEntrySection = styled.section`
    
    //border-radius: 5px;
    //padding: 10px;
    //padding-inline-start: 60px;
    //margin-left: 60px;
    //height: 100%;
    overflow: auto;
  
    //> div > *{
    //  //padding-left: 10px;
    //}

    > div > hr {
      opacity: 0.1;
    }
    span[class='insecure']:before{
      content: 'INSECURE Msg - ';
      color: red;
    }
    span[class='insecure']{
      color: lightgrey;
    }

    span[class='empty']:before{
    	content: '-'
    }
  
    span[class='empty']{
      color: lightgrey;
      font-style: italic;
    }
    
`;

export const DocumentEntry = styled.div`
  //background-color: lightgrey;
  //color: lightgrey;
  :hover{
    background: ${(props) => props.hoverStyle}
  }
  
  > p{
    padding: 0px 0px 10px 10px;
    text-align: justify;
    font-size: 1em;
    font-weight: normal;
    line-height: 1.8em;
    transition: all 0.05s;
  }
  
  div[id="edit"]{
    display: none;
    justify-content: center;
    position: absolute;
    left: -0px;
    padding-right: 20px;
  }
      
  
`;

export const UnlockModal = styled.div`

    &.documentOverlay {
        position: relative;
    }

    position: absolute;
    height: 100%;
    width: 100%;

    align-items: center;
    justify-items: center;

    //color: white;

    backdrop-filter: blur(10px);
    //border: 1px solid green;

    z-index: 2;
    top: 0;

    div[id="content"] {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        padding: 20px;
        width: 80%;
        max-width: 400px;
        height: 50%;
        background-color: lightgrey;
        border-radius: 10px;

        header {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            width: 100%;
            height: 60px;
            top: 0;

            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
        }

    }

    div[id="closeBtn"] {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0px;
        right: 0;
        transition: 0.2s;
        height: 40px;
        width: 40px;
        border-top-right-radius: 10px;
        color: black;
        cursor: pointer;
    }

    div[id="closeBtn"]:hover {
        background-color: lightcoral;
        color: white;
    }


    section[id="document_description"] {
        //height: 100px;

        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 20px;
        //margin: 20px;

        .warning {
            color: indianred;
        }
    }

    //.MuiInputBase-root{
    //  > input {
    //color: white;
    //  }
    //  > fieldset {
    //border-color: white;
    //  }
    //}

`;

export const WorkspaceSettingsPanel = styled.div`
  
  position: absolute;
  
  display: flex;
  flex-direction: column;

  gap: 10px;
  
  top: 10px;
  right: 10px;
  
  //padding: 0.4em;

`;

export const SettingsPanelIconTile = styled.div`
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  white-space: nowrap;
  
  gap: 20px;
  height: 30px;
  width: 30px;

  border-radius: 50%;
  background-color: #fff;

  padding: 0.4em;
  
  cursor: pointer;

  border: 2px solid grey;
  
  svg{
    width: 100%;
    height: 100%;
  }
  
  &:hover{
    background-color: lightgrey;
  }
  
  &.notgasless:after{
    content: "";
  }
  
  &.gasless{
    border: 2px solid black;
  }
  
  &.gasless:after{
    content: "";
    position: absolute;
    border-top: 3px solid black;
    width: 100%;
    left: 7px;
    top: 5px;
    transform: rotate(45deg);
    transform-origin: 1% 0%;
  }
  
`;

export const SettingsSidePanel = styled.aside`
  
  position: absolute;
  right: 0;
  height: 100%;

  //visibility: hidden;
  
  overflow-x: hidden;
  
  //display: flex;
  //flex-direction: column;
  //justify-content: center;
  //align-items: center;
  
  background-color: #fff;
  //border-left: 1px solid grey;
  box-shadow: -1px 0px 10px 0px;
  
  transition: 0.2s width;
  z-index: 1;
  
  > div{
    width: 100%;
    text-align: center;

  }
  div[class="close-button"]{
    //position: inherit;
    //top: 0;
    //height: 60px;
    //text-align: right;
    
    > button{
      width: 100%;
      height: 40px;
    }
    border-bottom: 1px solid grey;
  }
  div[class="seal-button"]{
    position: inherit;
    width: 100%;
    bottom: 0;
  }
  
  div[class="content"]{
    padding-top: 10px;
    
    section{
      padding: 12px;
      
      fieldset > legend{
	text-align: left;
      }
      
    }
    
  }
  
  
`;