import React, {createContext, useContext, useState} from "react";
import PropTypes from "prop-types";
import Web3 from "web3";
import {Wallet} from "ethers";
import {HDNode} from "ethers/lib/utils";
import EthCrypto from "eth-crypto";


const WorkspaceContext = createContext(undefined)

export function WorkspaceProvider({children}){


    const web3 = new Web3();

    const [key] = useState("diary"); //TODO: Change to workspace after all finished
    const [storage] = useState(localStorage);

    const [currentDocument, setCurrentDocument] = useState({})

    const [rootDocumentId, setRootDocumentId] = useState({}) //ID of current document, {address, pubKey, privKey}
    const [unlocked, setUnlocked] = useState(false);
    const [hashedPassword, setHashedPassword] = useState("");

    const reset = () => {
        setRootDocumentId({});
        setUnlocked(false);
        setCurrentDocument({});
    }

    const workspace = {
        // current_document: rootDocumentId.document,

        isUnlocked: () => {
            return unlocked
        },
        isLocked: () => {
            return !unlocked
        },
        hasIdentity: () => {
            return !(!storage.getItem(key) || JSON.parse(storage.getItem(key)).user === undefined)
        },
        create: (password) => {

            /**
             * 1.) Create Mnemonic (random-words)
             * 2.) Create Wallet with Mnemonic and Passphrase
             * 3.) Never store private-key, always hold passphrase temporary because Mnemonic will be stored in localStorage
             */
            const wallet = Wallet.createRandom();
            let id = {
                user: {
                    mnemonic: wallet.mnemonic.phrase,
                    locale: wallet.mnemonic.locale,
                    path: wallet.mnemonic.path
                }
            }

            storage.setItem(key, JSON.stringify(id))
            return id;
        },

        __restoreWallet: (identity, password) => {

            const node_save = HDNode.fromMnemonic(identity.user.mnemonic, password, identity.user.locale).derivePath(identity.user.path)
            let document_id = {
                document: {
                    address: node_save.address,
                    publicKey: node_save.address, //What if using publicKey from node_save?
                    privateKey: node_save.privateKey
                }
            }

            // console.log(node_save, identity, password)

            setHashedPassword(password);

            setRootDocumentId(document_id);

            setUnlocked(true)
            return document_id;
        },

        lockWorkspace: () => {
            reset()
        },
        /**
         * Main-Logic
         * Unlocks the Diary with a necessary Password
         * @param password
         */
        unlockWorkspace: async (password = "") => {

            if (password === null || password === "") {
                throw Error("Password is empty!")
                // }else if(password.length < 5){
                // throw Error("Password is to short! Min. 5 characters")
            } else {

                password = web3.utils.keccak256(password)

                if (workspace.hasIdentity()) {
                    //
                    // console.log("Has Identity: ", password)
                    let identity = JSON.parse(storage.getItem(key));
                    return workspace.__restoreWallet(identity, password);

                } else {
                    //1.) Create Mnemonic and save
                    // console.log("Create Mnemonic first", password)
                    const identity = workspace.create();
                    const node_original = HDNode.fromMnemonic(identity.user.mnemonic, null, identity.user.locale).derivePath(identity.user.path)
                    // console.log("Unsave: ", node_original.address)
                    return workspace.__restoreWallet(identity, password);
                }
            }
        },


        /**
         * Encrypts a specific String with the EC-publicKey
         * Verschluesselt einen bestimmten String mit dem EC-publicKey
         * TODO: it only encrypts with the (root)Workspace-Key
         * @param messageToEncode
         * @returns {Promise<string>}
         */
        encryptMessage: async (messageToEncode) => {
            console.log("Encrypting with current_document", currentDocument)
            const publicKey = EthCrypto.publicKeyByPrivateKey(currentDocument.privateKey)
            let msg = await EthCrypto.encryptWithPublicKey(publicKey, messageToEncode);
            return EthCrypto.cipher.stringify(msg)
        },

        decryptMessage: async (messageToDecrypt) => {

            if (web3.utils.isHex(messageToDecrypt)) {
                try {
                    return EthCrypto.decryptWithPrivateKey(
                        currentDocument.privateKey,
                        EthCrypto.cipher.parse(messageToDecrypt)
                    ).then((decryptedMessage) => {

                        if (decryptedMessage === '')
                            return "<span class='empty'/>"
                        else
                            return decryptedMessage;
                    }).catch((error) => {
                        console.error(error)
                        // throw Error("Cannot decode, regarding missing correct privateKey")
                        return "<span class='insecure'>" + messageToDecrypt + "</span>";
                    })
                } catch (e) {
                    console.error(e)
                    // throw Error("Cannot decode, regarding missing correct privateKey")
                    //return messageToDecrypt;
                    return "<span class='insecure'>" + messageToDecrypt + "</span>";
                }
            } else {
                console.warn("Unsecured Msg detected: ", messageToDecrypt)
                return "<span class='insecure'>" + messageToDecrypt + "</span>";
            }
        },

        /**
         * Restoring, equals reading own document.
         * TODO: Need to do something about the Index, need a better solution
         * @param identity
         * @param index
         * @return {HDNode}
         * @private
         */
        __restoreDocument: (identity, index) => {

            // let password = web3.uiUtils.keccak256(hashedPassword);

            const PATH = "m/44'/60'/0'/0/" + index;
            const node_derived = HDNode.fromMnemonic(identity.user.mnemonic, hashedPassword, identity.user.locale).derivePath(PATH)
            const derived_obj = {
                depth: node_derived.depth,
                index: node_derived.index,
                address: node_derived.address,
                privateKey: node_derived.privateKey
            }
            // return derived_obj;

            // console.log(node_derived, identity, hashedPassword)

            // console.log("__restoreDocument", index, node_derived)

            // let test = HDNode.fromExtendedKey(node_derived.extendedKey)
            // console.log(test)

            return node_derived;
        },

        getWorkspaceDocument: (number = 0) => {
            if (workspace.hasIdentity()) {
                let identity = JSON.parse(storage.getItem(key));
                return workspace.__restoreDocument(identity, number)
            } else {
                throw Error("No identity found")
            }
        },
        getWorkspaceRootDocument: () => {
            if (workspace.hasIdentity()) {
                let identity = JSON.parse(storage.getItem(key));
                return workspace.__restoreDocument(identity, 0)
            } else {
                throw Error("No identity found")
            }
        }
    }

    const utils = {
        setCurrentDocument: (number = 0) => {
            let document = workspace.getWorkspaceDocument(number);
            console.log("Utils[setCurrentDocument]: ", document)
            let document_id = {
                document: {
                    address: document.address,
                    publicKey: document.address, //What if using publicKey from node_save?
                    privateKey: document.privateKey
                }
            }
            console.log(document_id)
            // workspace.current_document = document_id.document;
            setCurrentDocument(document_id.document)
        },

        setDocumentToPreview:(privateKey) => {
            if (privateKey.length > 0) {
                const publicKey = EthCrypto.publicKeyByPrivateKey(privateKey)
                const address = EthCrypto.publicKey.toAddress(publicKey);

                let document_id = {
                    document: {
                        address: address,
                        publicKey: address, //What if using publicKey from node_save?
                        privateKey: privateKey
                    }
                }
                // setId(id);
                // workspace.current_document = document_id.document;
                setCurrentDocument(document_id.document);
                setUnlocked(true);
                console.log("Setting document to Preview")
            }
        }

    }

    return(
        <WorkspaceContext.Provider
            value={{
                workspace,
                unlocked,
                resetWorkspace: reset,
                lock: reset,
                utils,
                currentDocument,
                preview: {
                    document: currentDocument,
                    setDocumentToPreview: utils.setDocumentToPreview
                }
            }}
        >
            {children}
        </WorkspaceContext.Provider>
    )

}

WorkspaceProvider.propTypes = {
    children: PropTypes.node
}

export function useWorkspace(){
    const context = useContext(WorkspaceContext);
    if(!context) throw Error("useWorkspace can only be used within WorkspaceProvider component")
    return context;
}