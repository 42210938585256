import React, {useEffect, useState} from "react";
import {useWeb3React} from "@web3-react/core";
import Identicon from "identicon.js";
import {AvatarImage} from "../desktop/menu/Menu.styles";
import PropTypes from "prop-types";

export function Avatar({style}){

    const [icon, setIcon] = useState("")

    const {account} = useWeb3React()

    useEffect(() => {
        // console.log(address)
        setIcon(new Identicon(account, 420).toString())
    })

    return(
        <AvatarImage >
            <img src={"data:image/png;base64," + icon} />
        </AvatarImage>
    )

}
Avatar.propTypes = {
    style: PropTypes.object
}